import { combineReducers, createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension';
import { all, fork } from 'redux-saga/effects';
import createSagaMiddleware from 'redux-saga';
import { StateType } from 'typesafe-actions';
import { reducer as formReducer } from 'redux-form';
import { loadUser } from "redux-oidc";
import { reducer as oidcReducer } from 'redux-oidc';
import thunk from 'redux-thunk';

import { userManager } from "../services/userManager";

import {
  // State as SelectCategoryState,
  reducer as reducerSelectCategory,
  saga as sagaSelectCategory
} from './selectCategory';
import * as questions from './questions';
import * as language from './language';
import * as user from './user';
import * as addEditQuestion from './addEditQuestion';
import * as customQuestion from './customQuestions';
import * as editCategory from './editCategory';
import * as tip from './tip';

function* rootSaga() {
  yield all([
    fork(user.saga),
    fork(language.saga),
    fork(sagaSelectCategory),
    fork(questions.saga),
    fork(customQuestion.saga),
    fork(editCategory.saga),
    fork(tip.saga),
  ]);
}
const reducers = {
  user: user.reducer,
  addEditQuestion: addEditQuestion.reducer,
  category: reducerSelectCategory,
  questions: questions.reducer,
  language: language.reducer,
  customQuestion: customQuestion.reducer,
  editCategory: editCategory.reducer,
  tip: tip.reducer,
  oidc: oidcReducer,
  form: formReducer,
};

export type RootState = StateType<typeof reducers>;
const reducer = combineReducers(reducers);
const initialStore = reducer(undefined, {type: 'init'});

function stores() {
  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    reducer,
    initialStore,
    composeWithDevTools(
      applyMiddleware(sagaMiddleware),
      applyMiddleware(thunk),
    ),
  );

  loadUser(store, userManager);

  sagaMiddleware.run(rootSaga);

  return store;
}

const store = stores();

export default store;
