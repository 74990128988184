import * as React from 'react';
import { connect } from 'react-redux';
import {withStyles, createStyles, Theme} from '@material-ui/core/styles';
import LinkNav from '../LinkNav';
import { JnJButton, JnJContainer, LogoIcon } from '@jaj/ui-components';
import { signOut } from '../../services/userManager';
import DrawerIcon from './DrawerIcon';
import { onHistoryLinkClicked } from '../../services/historyLinksService';
import cs from 'classnames';
import { Tooltip, Collapse } from '@material-ui/core/';
import { switchCurrentUserRoleTo } from '../../store/user/action';
// import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

interface JnJHeaderState {
  isOpen: boolean;
  loading:boolean;
}

const BRAKE_SIZE = 'md';
const HEADER_HEIGHT = 76;

const styles = (theme: Theme) => createStyles({
  root: {
    position: 'fixed',
    width: '100%',
    top: 0,
    zIndex: theme.zIndex.appBar
  },
  headerSpace: {
    height: HEADER_HEIGHT
  },
  rootHeader: {
    height: HEADER_HEIGHT,
    background: 'white',
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  whiteBg: {
    background: 'white',
  },
  rootMenu: {
    width: '100vw',
    height: 'auto',
    display: 'flex',
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    }
  },
  logoBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  logo: {
    marginTop: 6,
    marginRight: 12
  },
  label: {
    color: '#9C9C9C',
    fontSize: 12,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 500,
    lineHeight: 1
  },
  tabs: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& $tab': {
      marginLeft: 20,
      marginRight: 20
    },
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      display: 'none',
    }
  },
  tab: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: HEADER_HEIGHT,
    ...theme.typography.body1,
    '&:hover': {

      color: theme.palette.secondary.main,
      [theme.breakpoints.up(BRAKE_SIZE)]: {
        paddingTop: 2,
        borderBottom: `2px solid ${theme.palette.secondary.main}`
      }
    },
  },
  customTab: {
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      fontSize: 18
    }
  },
  activeTab: {
    color: theme.palette.secondary.main,
    [theme.breakpoints.up(BRAKE_SIZE)]: {
      paddingTop: 2,
      borderBottom: `2px solid ${theme.palette.secondary.main}`
    }
  },
  drawer: {
    display: 'none',
    [theme.breakpoints.down(BRAKE_SIZE)]: {
      display: 'block',
      height: '32px',
    }
  },
  divider: {
    marginLeft: 10,
    marginRight: 10,
    width: 2,
    height: 20,
    background: theme.palette.divider
  },
  button: {
      marginLeft: 25,
      backgroundColor: '#000099;',
      height: 25,
      width: 72,
      borderRadius: 12.5,
      paddingTop: 1,
      color:'white',
      [theme.breakpoints.up('sm')]: {
        flex: '0 0 72px',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: 10,
        marginLeft: 0,
      }
    }
});

class Header extends React.Component<{}, JnJHeaderState> {
  state = {
    isOpen: false,
    loading:false
  }

  toggle = () => {
    this.setState({isOpen: !this.state.isOpen});
  }

  switchRole = () => {
    const user = this.props.user ? this.props.user.data : undefined;
    const loading = (user && user.loading) || this.state.loading;
    const nextRole = this.getNextRole();
    if (user && user.currentUser && !loading) {
      if (user.userHasRoles) {
        this.setState({loading:true});
        this.props.switch(nextRole).then(_ => {
          this.setState({loading:false});
        });
      }
      else {
        this.props.history.push('/');
      }
    }
  }

  getCurrentRole = () => {
    return this.props.user
      && this.props.user.data
      && this.props.user.data.userIsInRoleWriter
      ? 'Admin' : 'User'
  }

  getNextRole = () => {
    return this.getCurrentRole() === 'Admin' ? 'User' : 'Admin'
  }

  render() {
    const {
      classes, user
    } = this.props;

    const label = "GLOBAL TALENT MANAGEMENT";
    const homeLink = '/';
    const customMobileStyles = false;
    const {isOpen} = this.state;
    const userIsInRoleWriter = user && user.data ? user.data.userIsInRoleWriter : false;
    const userHasRoles = user && user.data ? user.data.userHasRoles : false;

    const links = [
      {
        label: 'My Interview Guides',
        url: '/interview',
      },
      {
        label: 'Resources',
        url: '/downloads',
      }
    ];

    const linkComponents = links.map(
      ({label, url}) => (<LinkNav
        className={cs(classes.tab, customMobileStyles ? classes.customTab : '')}
        key={url}
        to={url}
        activeClass={classes.activeTab}
        >{label}</LinkNav>)
    );

    return (
      <React.Fragment>
        <div className={classes.root}>
          <JnJContainer static className={classes.rootHeader}>
            <div className={classes.logoBlock}>
              <LinkNav key={homeLink} to={homeLink}>
                <LogoIcon/>
              </LinkNav>
              {label && <div className={classes.label}>{label}</div>}
            </div>
            <div className={classes.tabs}>
              {linkComponents}
              {/*this.props.pageUri=='/interview/questions-list-page'  ?
              <>
              <div className={cs(classes.divider)}/>
              <nav className={classes.tab}>
                <LanguageSelector disableUnderline={true} />
              </nav>
              </>
              : "" */}
              <div className={cs(classes.divider)}/>
              <nav className={classes.tab} onClick={signOut}>Log Out</nav>
              {userHasRoles && (
              <div>
                <Tooltip title={`Switch to ${this.getNextRole()}`}>
                  <JnJButton color="secondary"
                    className={classes.button}
                    variant={'outlined'}
                    onClick={() => onHistoryLinkClicked({ category: 'admin', action: 'changeRole', label: userIsInRoleWriter ? 'toUser' : 'toAdmin', onClick: this.switchRole })}>
                    {this.getCurrentRole()}
                  </JnJButton>
                </Tooltip>
              </div>)}
            </div>
            <DrawerIcon className={classes.drawer} isOpen={isOpen} onClick={this.toggle}/>
          </JnJContainer>

          <Collapse classes={{wrapperInner: classes.rootMenu}} in={isOpen}>
            <JnJContainer static className={classes.whiteBg}>
              {linkComponents}
              <nav  className={cs(classes.tab, customMobileStyles ? classes.customTab : '')}  onClick={signOut}>Log Out</nav>
              {userHasRoles && (
              <div>
                <Tooltip title={`Switch to ${this.getNextRole()}`}>
                  <JnJButton color="secondary"
                    className={classes.button}
                    variant={'outlined'}
                    onClick={() => onHistoryLinkClicked({ category: 'admin', action: 'changeRole', label: userIsInRoleWriter ? 'toUser' : 'toAdmin', onClick: this.switchRole })}>
                    {this.getCurrentRole()}
                  </JnJButton>
                </Tooltip>
              </div>)}
            </JnJContainer>
          </Collapse>
        </div>
        <div className={classes.headerSpace}/>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  switch: switchCurrentUserRoleTo,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));
