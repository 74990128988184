import { RootState } from "..";
import { createSelector } from 'reselect';


//@TODO find better place for this item.
export const selectQuestionList = (state: RootState) => state.category.questions;
export const selectQuestionStore = (state: RootState) => state.category.questionStore;

export const questionSelector = createSelector(
  selectQuestionList,
  selectQuestionStore,
  (list, store) => list.map(id => store[id])
);
