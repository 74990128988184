import React, { Component } from 'react';
import ArrowTooltips from '../Tooltip/ArowTooltip';
import { guideViewStatus } from "../../models/guideModel";

class GuideInfo extends Component {

  render() {
    const {
      guide,
      cardText,
      viewStatus,
      cardContentText,
      shouldPositionShow,
      longTextView,
      /*positionText,*/
    } = this.props;

    const dateFormat = { year: 'numeric', month: 'short', day: 'numeric' };
    const text = `Each position level within a function has a unique set 
    of questions so that interviewers ask questions appropriate to the skill and experience level of the candidate. 
    
    To view questions associated to a different level position, you will need to create a new interview guide. `

    return (
      <div className={cardText}>
        <div>

          <p>{shouldPositionShow && guide.guideTypeCategoryName ? 'Position' : ''} </p>
          {guide.functionCategoryName ? <p>Function<span></span></p> : null }
          {guide.questionnaireLength ? <p>Length <span></span></p> : null}
          {!shouldPositionShow && guide.createdDate ? <p>Created<span></span></p> : null}
        </div>

        <div className={cardContentText} >
          {shouldPositionShow && guide.guideTypeCategoryName ?
            <div style={{ display: 'flex' }}>
              <p className={longTextView}> {guide.guideTypeCategoryName} </p>
              {viewStatus === guideViewStatus.copy ? <ArrowTooltips content={text} /> : null}
            </div> :
            ''}
          <p className={longTextView}>{guide.functionCategoryName ? guide.functionCategoryName : ''}</p>
          <p>{guide.questionnaireLength ? `${guide.questionnaireLength} minutes` : ''} </p>
          {!shouldPositionShow && !guide.isDraft && guide.updatedDate ?
            <p>{new Date(guide.updatedDate).toLocaleDateString("en-US", dateFormat)} </p>
          :
            <p>{!shouldPositionShow && guide.createdDate ? new Date(guide.createdDate).toLocaleDateString("en-US", dateFormat) : ''} </p>
          }        
          </div>
      </div>
    );
  }
}


export default GuideInfo;
