import React from 'react';
import { JnJButton, ModalCloseIcon } from '@jaj/ui-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme) => createStyles({
  dialogContent: {
    minHeight: 400,
    backgroundColor: '#f4f4f4',
    padding: '40px 40px 61px 70px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  closeIcon: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '22px',
    minWidth: '22px',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  header: {
    minHeight: '28px',
    color: '#000099',
    fontSize: ' 24px',
    fontWeight: ' 900',
    lineHeight: ' 28px',
  },
  dialogContentText: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 0',
    justifyContent: 'end',
  },
  dialogMessage: {
    minHeight: '30px',
    fontSize: '20px',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    padding: '20px 0',
  },
  selectedItem: {
    minHeight: '26px',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '26px',
    padding: '20px 0',
    [theme.breakpoints.down("md")]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  dialogTitle: {
    padding: '4px 0',
  },
  buttons: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minHeight: '160px',
      alignItems: 'unset',
      justifyContent: 'space-around',
      flexDirection: 'column-reverse',
    }
  },
  deleteButton: {
    minWidth: '180px'
  },
  cancelButton: {
    minWidth: '130px',
    [theme.breakpoints.down('md')]: {
      minWidth: '180px',
    }
  },
  selectedItemInfo: {
    fontWeight: 'bold',
    marginLeft: '15px',
    [theme.breakpoints.down("md")]: {
      marginLeft: '0px',
    },
  }
});

const DeleteModalDialog = (props) => {
  const { open, modalClose, dialogMessage, deletedElement, classes, question, selectedThing } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => modalClose(false)}
        maxWidth="md"
        fullWidth
      >
        <div className={classes.dialogContent}>
          <JnJButton onClick={() => modalClose(false)} className={classes.closeIcon}>
            <ModalCloseIcon />
          </JnJButton>
          <DialogTitle className={classes.dialogTitle}>
            <div className={classes.header}>
              {question}
            </div>
          </DialogTitle>

          <DialogContent className={classes.dialogContentText}>
            <div>
              {dialogMessage}
            </div>
            <div className={classes.dialogMessage}>
              This action cannot be undone.
              </div>
            <div className={classes.selectedItem}>
              Selected {selectedThing}: <span className={classes.selectedItemInfo}>{deletedElement}</span>
            </div>
          </DialogContent>
          <DialogActions className={classes.buttons}>
            <JnJButton
              onClick={() => modalClose(false)}
              color="primary"
              variant="outlined"
              className={classes.cancelButton}
            >
              Cancel
            </JnJButton>
            <JnJButton
              onClick={() => modalClose(true)}
              color="primary"
              variant="contained"
              className={classes.deleteButton}
            >
              Yes, delete it
            </JnJButton>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(DeleteModalDialog);
