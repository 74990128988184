import * as React from 'react';
import cs from 'classnames';
import { withStyles, WithStyles, createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  root: {
    cursor: 'pointer',
    width: 30,
    height: 30,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  line: {
    width: 30,
    height: 2,
    borderRadius: 1,
    backgroundColor: 'black',
    marginTop: 6,
    marginBottom: 6,
    transition: 'all 0.2s linear',
  },
  open: {
    '& $line': {
      '&:nth-child(2)': {
        opacity: 0
      },
      '&:first-child': {
        transform: 'rotate(45deg) translateX(6px) translateY(6px)'
      },
      '&:last-child': {
        transform: 'rotate(-45deg) translateX(5px) translateY(-6px)'
      }
    }
  }
});


interface Props extends WithStyles<typeof styles> {
  className?: string,
  onClick?: () => void,
  isOpen: boolean
}

function DrawerIcon(props: Props) {
  const {
    className,
    classes,
    isOpen,
    onClick
  } = props;

  return (
    <div className={cs(className, classes.root, {[classes.open]: isOpen})} onClick={onClick}>
      <div className={classes.line}/>
      <div className={classes.line}/>
      <div className={classes.line}/>
    </div>
  )
}


export default withStyles(styles)(DrawerIcon)
