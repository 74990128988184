import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import Container from '../../components/Container/Container';
import { ResourcesListItem } from "../../components/Resource";
import List from '@material-ui/core/List';
import { ListComponent } from "../../components/ListComponent";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Theme } from '@material-ui/core';
import { Typography, JnJLink } from '@jaj/ui-components';
import { downloadResource } from '../../services/reportService';
import { Link, Redirect } from "react-router-dom";

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: '#fff',
    padding: '25px',
    [theme.breakpoints.up('lg')]: {
      padding: '72px 144px 105px'
    },
  },
  typographyTitle:{
    fontSize: 44,
    marginBottom: 45,
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('md')]: {
      marginBottom: 0,
      fontSize: 30
    }
  },
  descriptionList: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    paddingLeft: 30,
    fontWeight: 300,
    '& > li' : {
      listStyleType: "decimal",
      display: 'list-item',
      paddingTop: 0,
      color: "#000",
    },
    '& li span': {
      fontWeight: 300
    }
  },
  descriptionText: {
    marginTop: 13,
    paddingRight: 210,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '26px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
});

interface ResourcesPageProps extends WithStyles{
}
interface ResourcesPageState {
  downloadingStatus:string
}
interface Resource {
  title: string,
  path: string,
  description?: string | React.ReactChild,
  fileFormat: string,
  downloadable?: boolean,
  redirectable?: boolean
}

const linkDesc = (classes) => {
  return (
    <div>
      <Typography variant="subtitle1" className={classes.descriptionText}>
        Who we hire is one of the most IMPORTANT business decisions we make every day. 
        For us to achieve our purpose of changing human health, we need to become the best at hiring the best. 
        With that, we want to make sure you have the tools you need to be a great, effective interviewer.<br/>
        This training has been designed to:
      </Typography>
      <List component="ol" className={classes.descriptionList}>
        <ListItem>
          <ListItemText primary="Improve the effectiveness of interviews as an assessment method hence improving the quality of hire"/>
        </ListItem>
        <ListItem>
          <ListItemText primary="Provide a framework to ensure a consistent candidate experience"/>
        </ListItem>
          <ListItem>
          <ListItemText primary="Enable all interviewers to be a great storyteller and articulate the overarching promise J&J makes to all candidates in order to persuade them to join us"/>
        </ListItem>
          <ListItem>
          <ListItemText primary="Improve diversity and inclusion through ensuring interviewers minimize unconscious bias and assess candidate based on the skills needed for successful performance on the job"/>
        </ListItem>
          <ListItem>
          <ListItemText primary="Strengthen your confidence as the interviewer"/>
        </ListItem>
      </List>
    </div>
  )
}

class ResourcesPage extends React.PureComponent<ResourcesPageProps, ResourcesPageState> {
  resources: Resource[] = [
    {
      title: 'Functional Competency Models',
      path: 'Funtional_Competency_Models.pptx',
      description: 'This is a power point presentation that explains our enterprise-wide guidance on creating and validating a functional competency model, as well as how these models can be used to inform talent management practices.',
      fileFormat: 'PPT',
      downloadable: true,
    },
    {
      title: 'Business Case Guidelines',
      path: 'Business_Case_Guideline.pptx',
      description: 'This is a power point presentation that explains our enterprise-wide best practices on how to create, use, and score business cases (sometimes called work samples, or role play simulations) to use as part of the hiring process.',
      fileFormat: 'PPT',
      downloadable: true,
    },
    {
      title: 'Trust Based Hiring Toolkit',
      path: 'Trust_Based_Hiring_Toolkit.pptx',
      description: 'Explore the Trust-Based Hiring Toolkit, an optional resource designed to assist with high-volume hiring builds and events. This comprehensive guide provides helpful assets and strategies for implementing Trust-based hiring into your interview process. For more information, reach out to your TA Partner.',
      fileFormat: 'PPT',
      downloadable: true,
    },
    {
      title: 'Interview Debrief Worksheet',
      path: 'Interview_Debrief_Worksheet_FINAL.xlsx',
      description: 'This is an excel file to help you compile ratings from multiple interviewers to help you make your hiring decisions.',
      fileFormat: 'XLS',
      downloadable: true,
    },
    {
      title: 'Interview with Purpose Training and Certification Program',
      path: 'https://jnj.csod.com/LMS/LoDetails/DetailsLo.aspx?loid=86dc767b-f364-48f3-b0c6-8419ff182d42',
      fileFormat: 'LINK',
      description: linkDesc(this.props.classes),
      redirectable: true
    }
  ];
  constructor(pars) {
    super(pars);
    this.state = {
      downloadingStatus:''
    }
  }
  componentDidMount() {
    let path = this.props.match.params.name;
    if (path) {
      this.setState({
        downloadingStatus:'Downloading...'
      });
      downloadResource(path).then(_ => {
        this.setState({
          downloadingStatus:'Downloaded'
        });
      });
    }
  }

  onDownload = (e:any, resource: Resource) => {
    e.preventDefault();
    if (resource.downloadable)
      downloadResource(resource.path);
    if(resource.redirectable) {
      window.open(resource.path, "_blank");
    }
    return true;
  }
  
  renderResources(){
    return this.resources.map(resource => (
      <ResourcesListItem
        {...resource}
        key={resource.path}
        onDownload={(e)=>this.onDownload(e, resource)}
      />
    ))
  }

  render() {
    if (!this.props.user || this.props.user.loading)
      return (<div>Loading..</div>);

    if (this.state.downloadingStatus)
      return (<div>{this.state.downloadingStatus}</div>);

    const {classes} = this.props;
    return (
      <Container
        static
        className={classes.root}
      >
        <Typography
          variant="h2"
          color="secondary"
          className={classes.typographyTitle}
        >
          Resources
        </Typography>
        {this.renderResources()}
        {/* <div style={{ paddingTop: 48 }}>
          <Link to="https://jnj.csod.com/LMS/LoDetails/DetailsLo.aspx?loid=86dc767b-f364-48f3-b0c6-8419ff182d42">
            <JnJLink>Interview with Purpose Training and Certification Program</JnJLink>
          </Link>
        </div> */}
      </Container>
    );
  }
}


const mapStateToProps = (state) => ({
  user: state.user
});

export default withStyles(styles)(connect(mapStateToProps)(ResourcesPage));
