import React, { Component } from 'react';
import Loading from "../Loading/Loading";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {withStyles } from '@material-ui/core';
import {actions} from "../../store/selectCategory";

const styles = (theme) => ({
});

class SharedGuide extends Component {

  constructor(props) {
    super(props);
    this.props.downloadGuide(this.props.match.params.key);
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.isGuideDownloaded) {
      this.props.history.push({pathname: '/interview', state: {value: 0}})
    }
    return null
  }

  render() {
    const { initializing } = this.props;

    return ( initializing ? <Loading /> : null);
  }
}

const mapStateToProps = (state) => ({
  initializing: state.category.initializing,
  isGuideDownloaded: state.category.isGuideDownloaded
});

const mapDispatchToProps = {
  downloadGuide: actions.downloadSharedGuideAsync.request
};

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(SharedGuide)));

