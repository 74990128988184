import {Reducer} from 'redux';
import {getType} from 'typesafe-actions';
import {Actions, actions} from './action';

type State = number[];

const initialState: State = [];

export const reducer: Reducer<State> = (state: State = initialState, action: Actions): State => {
  switch(action.type) {
    case getType(actions.resetAll):
      return [];
    case getType(actions.pushQuestions):
      return [...action.payload.questions];
    case getType(actions.toggle):
      let index = state.indexOf(action.payload.questionId);
      if (index !== -1) {
        return [...state.slice(0, index), ...state.slice(index+1)];
      } else {
        return [...state, action.payload.questionId];
      }
    default:
      return state;
  }
}
