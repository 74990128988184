export const FUNCTIONAL_MODEL_FORMAT_TRUE = 'A rating scale with function';
export const FUNCTIONAL_MODEL_FORMAT_FALSE = 'A rating scale with each question';
export const PROBING_QUESTION_FORMAT_TRUE = 'Probing questions with function';
export const PROBING_QUESTION_FORMAT_FALSE = 'Probing questions with each question';

export const QUESTION_ACTIONS = {
  add: "add",
  edit: "edit"
};

export const COMPETENCY_ACTIONS = {
  add: "add",
  edit: "edit"
};