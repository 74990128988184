import React from 'react';
import buildTimestamp from '../../build-timestamp.json';

class RefreshCache extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            refreshRequired: true,
            refreshCacheAndReload: () => {
                console.log('Clearing cache and hard reloading...')
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    //console.log("Service worker cache gets deleted.");
                    caches.keys().then(function(names) {
                        for (let name of names) caches.delete(name);
                    });
                }
                // delete browser cache and hard reload
                //window.location.reload(true); 
            }
        };
    }

    componentDidMount() {
        fetch('/build-timestamp.txt?timestamp=' + new Date().getTime())
        .then((response) => response.json())
        .then((jsonData) => {
            const latestBuildTimestamp = jsonData.timestamp;
            const currentBuildTimestamp = buildTimestamp.timestamp;
            //console.log("Timestamp from /public/: "+ latestBuildTimestamp);
            //console.log("Timestamp src/: "+ currentBuildTimestamp);
            if (latestBuildTimestamp != currentBuildTimestamp) {
                //console.log('This is not the latest version. Force refresh is needed!');
                this.setState({ loading: false, refreshRequired: true });
            } else {
                //console.log('This is the latest version. No cache refresh needed.');
                this.setState({ loading: false, refreshRequired: false });
            }
        });
    }

    render() {
        const {loading, refreshRequired, refreshCacheAndReload} = this.state;
        return this.props.children({loading, refreshRequired, refreshCacheAndReload});
    }
}

export default RefreshCache;
