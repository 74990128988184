import { Reducer } from 'redux';
import { getType } from 'typesafe-actions';
import { Actions, actions, SELECT_FUNCTIONAL_COMPETENCY } from './action';
import { CategoryDTO, CategoryID, Duration, Category, CategoryTab, ReportDocRequestDTO } from './type';
import { IQuestion } from '../../models/questionModels';
import { EDraftState, guideViewStatus } from '../../models/guideModel';

type State = {
  readonly categoryStore: CategoryStore;
  readonly questionStore: QuestionStore;
  readonly initializing: boolean;
  readonly initializingQuestions: boolean;
  readonly initializingFunctionalCompetency: boolean;
  readonly page: string;

  readonly competency: CategoryID;
  readonly duration?: Duration;
  readonly durationLabel?: String;
  readonly tab?: CategoryTab;
  readonly categoryId?: number;
  readonly categories: CategoryID[];
  readonly groups: CategoryID[];
  readonly functionalCompetency: CategoryID[];
  readonly questionCategory: CategoryID[];
  readonly questions: number[];
  readonly previewModal: {
    open: boolean,
    initializing: boolean,
    content: ReportDocRequestDTO,
    previouslySentQuestions: string,
    viewStatus: string
  },
  readonly guide: ReportDocRequestDTO,
  readonly guideViewStatus: string,
  readonly name: string,
  readonly functionCategoryName: string,
  readonly draftStatus: number,
  readonly isGuidePublished: boolean
  readonly isGuideDownloaded: boolean
  readonly drafts: ReportDocRequestDTO[],
  readonly published: ReportDocRequestDTO[],
  readonly deletedGuide: number,
};

type MapStore<T> = {
  [key: number]: T
}

type CategoryStore = MapStore<Category>;
type QuestionStore = MapStore<IQuestion>;

const initialState: State = {
  questionStore: {},
  categoryStore: {},
  initializing: true,
  initializingQuestions: true,
  initializingFunctionalCompetency: true,
  categories: [],
  groups: [],
  functionalCompetency: [],
  questionCategory: [],
  questions: [],
  page: '/interview/select-guide-type',
  previewModal: {
    open: false,
    initializing: false,
    content: {} as ReportDocRequestDTO,
    previouslySentQuestions: '',
    viewStatus: ''
  },
  guide: {
    selectedQuestions: [],
    selectedCustomQuestions: [],
    guideTypeCategoryId: 0,
    questionnaireLength: 0,
    guideTypeCategoryName: '',
    typeOfReport: 'Word',
    functionCategoryId: 0,
    functionCategoryName: '',
    name: '',
    isDraft: false,
    languageCode: 'EN'
  },
  guideViewStatus: '',
  competency: 0,
  name: '',
  functionCategoryName: '',
  draftStatus: EDraftState.none,
  isGuidePublished: false,
  isGuideDownloaded: false,
  drafts: [],
  published: [],
  deletedGuide: 0
};

function parseCategory(newCategories: CategoryDTO[], store: CategoryStore) {
  return newCategories.reduce((agr, category) => {
    const extendedCategory: Category = {
      ...category,
      parents: []
    };

    if (category.parentId && agr[category.parentId]) {
      extendedCategory.parents = [
        ...agr[category.parentId].parents,
        category.parentId
      ];
    }

    agr[extendedCategory.id] = extendedCategory;

    return agr;
  }, store);
}

function prepareQuestionStore(newQuestions: IQuestion[], store: QuestionStore): QuestionStore {
  return newQuestions.reduce((agr, question) => {
    agr[question.id] = question;
    return agr;
  }, store);
}

export const reducer: Reducer<State> = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case getType(actions.resetAll):
      return {
        ...initialState
      };
    case getType(actions.goToPage):
      return {
        ...state,
        page: action.payload.page
      };
    case getType(actions.setPagePath):
      return {
        ...state,
        page: action.payload.page
      };
    case getType(actions.selectGuideType):
      return {
        ...state,
        guide: {
          ...state.guide,
          guideTypeCategoryId: action.payload.position,
          guideTypeCategoryName: action.payload.guideTypeCategoryName
        },
      };
    case getType(actions.publishGuide):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          initializing: true
        },
        guide: {
          ...state.guide,
          isDraft: false
        }
      };
    case getType(actions.publishGuideAsync.request):
      return {
        ...state,
      };
    case getType(actions.setLanguage):
      return {
        ...state,
        guide: {
          ...state.guide,
          languageCode: action.payload
        }
      };
    case getType(actions.publishGuideAsync.success):
      return {
        ...state,
        isGuidePublished: true,
        guide: initialState.guide
      };
    case getType(actions.fetchGuideAsync.request):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          initializing: true,
        },
      };
    case getType(actions.downloadGuideAsync.request):
      return {
        ...state,
        guide: action.payload,
        previewModal: {
          ...state.previewModal,
          initializing: true,
        },
      };
    case getType(actions.fetchGuideAsync.success):
    case getType(actions.downloadGuideAsync.success):
      return {
        ...state,
        isGuideDownloaded: true,
        previewModal: {
          ...state.previewModal,
          initializing: false,
          open: false
        },
      };
    case getType(actions.saveDraft):
      return {
        ...state,
        guide: {
          ...state.guide,
          name: action.payload.name,
          isDraft: true
        },
      };
    case getType(actions.editGuide):
      return {
        ...state,
        guide: action.payload.guide,
        guideViewStatus: guideViewStatus.edit,
        initializing: false,
      };
    case getType(actions.copyGuide):
      return {
        ...state,
        guide: {
          ...action.payload.guide,
          id: 0,
          isDraft: true,
          name: action.payload.guide.name + ' Copy'
        },
        guideViewStatus: guideViewStatus.copy
      };
    case getType(actions.downloadSharedGuideAsync.request):
      return {
        ...state,
        initializing: true
      };
    case getType(actions.downloadSharedGuideAsync.success):
      return {
        ...state,
        initializing: false,
        isGuideDownloaded: true
      };
    case getType(actions.clearGuide):
      return {
        ...state,
        guide: initialState.guide
      };
    case getType(actions.saveGuideAsDraft.request):
      return {
        ...state,
        initializing: true,
      };
    case getType(actions.saveGuideAsDraft.success):
      return {
        ...state,
        initializing: false,
        draftStatus: state.draftStatus === EDraftState.draft ? EDraftState.copy : EDraftState.draft,
        guide: action.payload
      };
    case getType(actions.changeDraftStatus):
      return {
        ...state,
        draftStatus: action.payload.status
      };
    case getType(actions.changeGuideName):
      return {
        ...state,
        guide: {
          ...state.guide,
          name: action.payload.name
        }
      };
    case getType(actions.changeDownloadStatus):
      return {
        ...state,
        isGuideDownloaded: action.payload.status
      };
    case getType(actions.fetchDraftsList.request):
      return {
        ...state,
        initializing: true,
      };
    case getType(actions.fetchDraftsList.success):
      return {
        ...state,
        initializing: false,
        drafts: action.payload
      };
    case getType(actions.fetchPublishedList.request):
      return {
        ...state,
        initializing: true,
      };
    case getType(actions.fetchPublishedList.success):
      return {
        ...state,
        initializing: false,
        published: action.payload
      };
    case getType(actions.fetchGuideList.request):
      return {
        ...state,
        initializing: true,
      };
    case getType(actions.fetchGuideList.success):
      return {
        ...state,
        initializing: false,
        published: action.payload.published,
        drafts: action.payload.drafts
      };
    case SELECT_FUNCTIONAL_COMPETENCY:
      return {
        ...state,
        guide: {
          ...state.guide,
          functionCategoryId: action.payload.competency,
          functionCategoryName: action.payload.functionCategoryName
        },
      };

    case getType(actions.selectDuration):
      return {
        ...state,
        durationLabel: action.payload.text,
        // duration: action.payload.duration,
        page: '/interview/select-duration',
        guide: {
          ...state.guide,
          questionnaireLength: action.payload.duration
        }
      };

    case getType(actions.selectTab):
      return {
        ...state,
        tab: action.payload.tab,
        categoryId: action.payload.categoryId,
      };

    case getType(actions.fetchGuideTypeAction.request):
      return {
        ...state,
        initializing: true
      };
    case getType(actions.fetchGuideTypeAction.success):
      return {
        ...state,
        initializing: false,
        categories: action.payload.map(({ id }) => id),
        categoryStore: parseCategory(action.payload, state.categoryStore)
      };
    case getType(actions.fetchGuideTypeAction.failure):
      return {
        ...state,
        initializing: false
      };

    case getType(actions.fetchCategoriesAction.request):
      return {
        ...state,
        initializingFunctionalCompetency: true
      };
    case getType(actions.fetchCategoriesAction.success):
      return {
        ...state,
        initializingFunctionalCompetency: false,
        ...action.payload.reduce<{
          functionalCompetency: CategoryID[],
          questionCategory: CategoryID[],
          groups: CategoryID[],
        }>(
          (agr, { id, type }) => {
            switch (type) {
              case "Category":
                agr.questionCategory.push(id);
                break;
              case "Group":
                agr.groups.push(id);
                break;
              case "FunctionalCompetency":
                agr.functionalCompetency.push(id);
                break;
            }
            return agr;
          },
          {
            functionalCompetency: [],
            questionCategory: [],
            groups: [],
          }
        ),
        categoryStore: parseCategory(action.payload, state.categoryStore)
      };
    case getType(actions.fetchCategoriesAction.failure):
      return {
        ...state,
        initializingFunctionalCompetency: false
      };

    case getType(actions.fetchQuestionsAction.request):
      return {
        ...state,
        initializingQuestions: true
      };
    case getType(actions.fetchQuestionsAction.success):
      return {
        ...state,
        initializingQuestions: false,
        questions: action.payload.map(({ id }) => id),
        questionStore: prepareQuestionStore(action.payload, state.questionStore)
      };
    case getType(actions.fetchQuestionsAction.failure):
      return {
        ...state,
        initializingQuestions: false
      };
    case getType(actions.openPreviewModal):
      return {
        ...state,
        guide: action.payload.guide,
        previewModal: {
          ...state.previewModal,
          open: true,
          initializing: true,
          viewStatus: action.payload.viewStatus
        }
      };
    case getType(actions.changeGuideModalStatus):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          viewStatus: action.payload.viewStatus
        }
      };
    case getType(actions.previewGuideModal):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          open: true,
          initializing: true,
          viewStatus: action.payload.viewStatus
        },
        guide: action.payload.guide
      };
    case getType(actions.fetchPreviewDocAction.request):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          previouslySentQuestions: action.payload,
          initializing: true,
        },
      };
    case getType(actions.fetchPreviewDocAction.success):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          content: action.payload,
          initializing: false,
        },
      };
    case getType(actions.fetchPreviewDocAction.failure):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          initializing: false,
        },
      };
    case getType(actions.closePreviewModal):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          open: false,
          initializing: false,
        },
      };
    case getType(actions.stopInitializingPreviewModal):
      return {
        ...state,
        previewModal: {
          ...state.previewModal,
          initializing: false,
        },
      };
    case getType(actions.removeQuestionFromStore): {
      let changedStore = state.questionStore;
      delete changedStore[action.payload.questionId];
      return {
        ...state,
        questions: state.questions.filter((q) => q !== action.payload.questionId),
        questionStore: changedStore
      };
    }
    case getType(actions.removeCategoryFromStorage): {
      let changedStore = state.categoryStore;
      delete changedStore[action.payload.categoryId];
      return {
        ...state,
        categories: state.categories.filter((q) => q !== action.payload.categoryId),
        functionalCompetency: state.functionalCompetency.filter((q) => q !== action.payload.categoryId),
        categoryStore: changedStore
      };
    }
    case getType(actions.deleteGuide):
      return {
        ...state,
        guide: action.payload.guide,
        isGuideDownloaded: false
      };
    case getType(actions.deleteGuideAsync.request):
      return {
        ...state,
        initializing: true,
        isGuideDownloaded: false
      };
    case getType(actions.deleteGuideAsync.success):
      return {
        ...state,
        deletedGuide: action.payload,
        guide: initialState.guide,
        isGuideDownloaded: false
      };
    default:
      return state;
  }
}
