import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import * as languageActions from '../../store/language/action';

export class LanguageSelector extends Component {
  static propTypes = {
    languages: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired).isRequired,
    language: PropTypes.string.isRequired,
    changeLanguage: PropTypes.func.isRequired,
  };

  state = {
    selectedLanguageCode: ''
  };

  componentDidMount() {
    // TO make current language code available accross the pages
    localStorage.setItem('currentLanguageCode', this.props.language);

    var editGuideLanguageCode = localStorage.getItem('editGuideLanguageCode');
    if (editGuideLanguageCode !== null && editGuideLanguageCode !== '') {
      localStorage.setItem('editGuideLanguageCode', '');
      this.setState({
        selectedLanguageCode: editGuideLanguageCode
      });
    }
  };
  
  handleChange = ({ target }) => {
    this.setState({
      selectedLanguageCode: target.value
    });
    if (this.props.modalVIewStatus) {
      this.props.languageSelect(target.value);
      this.props.changeLanguage(target.value);
      return this.props.changeModalLanguage(target.value);
    }
    return this.props.changeLanguage(target.value);
  };

  render() {
    const {
      languages,
      inputlabel,
      specialClassName,
      language,
      modalLanguage,
      disableUnderline,
    } = this.props;
    
    return (
      <FormControl className={specialClassName ? specialClassName : null}>
        {(inputlabel != null) ?
          <InputLabel style={{ lineHeight: '16px' }} htmlFor="name-disabled">{inputlabel}</InputLabel>
        : ""}
        <Select
          disableUnderline = {disableUnderline ? true : false}
          onChange={this.handleChange}
          value={(this.state.selectedLanguageCode !== '')? this.state.selectedLanguageCode :
            (this.props.modalVIewStatus ? modalLanguage : language)
            }
          input={<Input name="name" id="name-disabled" />}
        >
          {languages.map(({ code, name }) => (
            <MenuItem key={code} value={code}>{name}</MenuItem>
          ))}
        </Select>
      </FormControl>
    )
  }
}

const mapStateToProps = (state) => ({
  language: state.language.code,
  modalLanguage: state.language.modalCode,
  languages: state.language.languages,
  isModalOpened: state.category.previewModal.open,
  modalViewStatus: state.category.previewModal.viewStatus,
  guide: state.category.guide
});

const mapDispatchToProps = {
  changeLanguage: languageActions.select,
  changeModalLanguage: languageActions.changeModalLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSelector)
