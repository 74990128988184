import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { actions } from '../../store/selectCategory/action';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  JnJButton,
  ModalCloseIcon
} from '@jaj/ui-components';
import { getLanguageCode } from '../../store/language/selector';
import { withRouter } from 'react-router-dom';
import { renderModal } from './ModalFeaturesRender'

export const featureStatus = {
  featureOne: 'featureOne',
  featureTwo: 'featureTwo',
  featureThree: 'featureThree'
};

const styles = theme => ({
  mainContent: {
    margin: 24,
    padding: 36,
  },
  modalContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 55px 48px 43px',
    justifyContent: 'space-between',
    minHeight: '530px',
    [theme.breakpoints.down('md')]: {
      padding: 20
    },
  },
  dialogContentWithButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '390px',
  },
  styledHeader: {
    padding: '0px',
    '& h2': {
      color: theme.palette.primary.main,
      lineHeight: '28px',
      fontWeight: '900',
      fontSize: '24px',
    },
    [theme.breakpoints.down('md')]: {
      flex: 'auto',
    }
  },
  signIcon: {
    width: '50px',
    height: '50px'
  },
  closeIconAlt: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  pureIconButton: {
    padding: '0',
    minWidth: 0,
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  nextButton: {
    minWidth: 300,
    alignSelf: 'stretch',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  dotsWrapper: {
    flexShrink: 0,
    flexGrow: 0,
    minWidth: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  dot: {
    marginLeft: '15px',
    height: '15px',
    width: '15px',
    borderRadius: '50%',
    display: 'inline-block',
    backgroundColor: '#FFF',
    border: '3px solid #D8D8D8',
  },
  selectedDot: {
    backgroundColor: '#CA001B',
    border: '1px #CA001B',
  },
  dialogContent: {
    padding: '0 !important',
    maxHeight: '260px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    lineHeight: '24px',
    overflowY: 'none'
  },
  guidesTitle: {
    height: ' 30px',
    width: ' 259px',
    color: '#212121',
    fontSize: ' 30px',
    fontWeight: ' 900',
    lineHeight: ' 30px',
  },
  dialogActions: {
    margin: '0px',
    justifyContent: 'space-around',
  },
  modalHeader: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  buttonWithDots: {
    display: 'flex',
    minHeight: '80px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
});

class ModalFeatures extends Component {
  constructor(props) {
    super(props);
    this.renderModal = renderModal.bind(this);
    this.state = {
      modalVIewStatus: this.props.modalVIewStatus || featureStatus.featureOne,
      open: true
    };
  }

  changeStatus = (featureStatus) => {
    this.setState({
      modalVIewStatus: featureStatus,
    });
  };

  closeModal = () => {
    this.setState({ open: false });
  }

  render() {
    const { classes } = this.props;
    const modalView = this.renderModal(this.state.modalVIewStatus);

    return (
      // <div className={classes.mainContent}>
      <Dialog
        open={this.state.open}
        onClose={this.closeModal}
        aria-labelledby="modal-preview"
        className={classes.mdSizePadding}
        PaperProps={{
          style: { backgroundColor: '#f4f4f4', overflowY: 'unset', maxWidth: '390px', minHeight: '440px' },
        }}
        maxWidth="sm"
        fullWidth
      >
        <div className={classes.modalContainer}>

          <div className={classes.modalHeader}>
            <div className={classes.closeIconAlt}>
              <JnJButton onClick={this.closeModal} className={classes.pureIconButton}>
                <ModalCloseIcon />
              </JnJButton>
            </div>

            <DialogTitle className={classes.styledHeader}>
              {modalView.title}
            </DialogTitle>
          </div>

          <div className={classes.dialogContentWithButton}>
            <DialogContent className={classes.dialogContent}>
              <div>

                <img src={modalView.icon} alt='sign' className={classes.sign} />

              </div>
              <div className={classes.guidesTitle}>
                {modalView.contentTitle}
              </div>
              <div>
                {modalView.content}
              </div>
            </DialogContent>

            <div className={classes.buttonWithDots}>
              <div type={'submit'} variant={'outlined'} color={'primary'} className={`${classes.button} ${classes.dotsWrapper}`}>
                <JnJButton onClick={modalView.dots.dot1.onClick} className={classes.pureIconButton}>
                  <span className={modalView.dots.dot1.class}></span>
                </JnJButton>
                <JnJButton onClick={modalView.dots.dot2.onClick} className={classes.pureIconButton}>
                  <span className={modalView.dots.dot2.class}></span>
                </JnJButton>
                <JnJButton onClick={modalView.dots.dot3.onClick} className={classes.pureIconButton}>
                  <span className={modalView.dots.dot3.class}></span>
                </JnJButton>
              </div>

              {/* ${classes.unSelectedDot} */}

              <DialogActions className={classes.dialogActions}>
                <JnJButton
                  className={modalView.button.class}
                  variant={modalView.button.submit.variant}
                  color={modalView.button.submit.color}
                  onClick={modalView.button.submit.onClick}
                >
                  {modalView.button.submit.label}
                </JnJButton>
              </DialogActions>

            </div>
          </div>
        </div>
      </Dialog>
      //  </div>
    );
  }

}

const mapStateToProps = (state) => ({
  content: state.category.previewModal.content,
  open: state.category.previewModal.open,
  initializing: state.category.previewModal.initializing,
  languageCode: getLanguageCode(state),
});

const mapDispatchToProps = {
  closePreviewModal: actions.closePreviewModal,
  openPreviewModal: actions.openPreviewModal,

};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ModalFeatures)));



