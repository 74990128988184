declare global {
	interface Window {
		appConfig: Config;
	}
}

interface Config {
	apiUrl: string;
	authServer:string;
	gaTrackingId: string;
	appInsight: string;
}

export const config = window.appConfig;
