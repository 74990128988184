
import { config } from './config';
import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';
import { RootState } from "../store";
import { UserState } from "./type";

export const userManagerConfig = {
  authority: config.authServer, 
  client_id: "urn:jnj_interview_client",
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login/callback`, //client UI url
  response_type: "id_token token",
  scope:"openid offline_access jnjinterviewapi.full_access",
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`, //client UI url,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  check_session_iframe: `${config.authServer}/connect/checksession`,
  automaticSilentRenew: true,
  includeIdTokenInSilentRenew: true,
  monitorSession: false,
  loadUserInfo: false,
  silentRequestTimeout:2000,
  checkSessionInterval:2000,
  stateStore: new WebStorageStateStore({ prefix:'jnj.', store: localStorage })
}

export const userManager = createUserManager(userManagerConfig);


function getParameterFromLocation(paramName: string) {
    var searchString = window.location.search.substring(1),
        i, val, params = searchString.split("&");
  
    for (i=0;i<params.length;i++) {
      val = params[i].split("=");
      if (val[0] == paramName) {
        return val[1];
      }
    }
    return null;
}

export function signIn(): void {
  var redirectUrl = '';
  if (window.location.search) {
      const redirectUrlFromPar = getParameterFromLocation('returnUrl');
      if (redirectUrlFromPar)
          redirectUrl = redirectUrlFromPar;
  }
  if (!redirectUrl && window.location.pathname)
    redirectUrl = window.location.pathname;
    
  localStorage.setItem('auth', 'loading');
  userManager.signinRedirect({ data: { redirectUrl } });
}

export function signOut(): void {
  localStorage.clear();
  userManager.clearStaleState();
  userManager.signoutRedirect();
}

export function getValidatedUserState(state: RootState): UserState {
  const storeUser = state.user;
  if (storeUser && storeUser.data && !storeUser.loading) {
    if (storeUser.data.currentUser || storeUser.data.isAuthenticated){
      return storeUser;
    }
  }
  
  if (storeUser && (storeUser.loading || (!storeUser.loading && storeUser.error)))  {
    return storeUser;
  }
    
  signIn();
  return storeUser;
};


export function getTokenBy(state: RootState) {
  return state && state.oidc && state.oidc.user ? state.oidc.user.access_token : '';
};