import * as React from "react";
import { withStyles, StyleRulesCallback } from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core";
import cs from "classnames";

export interface Props extends WithStyles<typeof styles> {
  className?: string,
  children?: React.ReactNode,
  onClick?: (() => void),
}

const styles: StyleRulesCallback = theme => ({
  root: {
    display: "flex",
    border: "1px solid #D8D8D8",
    alignItems: "center",
    "&:hover": {
      borderColor: theme.palette.secondary.main,
      cursor: "pointer"
    }
  },
});

const Card = (props:Props) => {
  const {classes, className, children, onClick} = props;

  return (
    <div className={cs(classes.root, className)} onClick={onClick}>
      {children}
    </div>
  );
};


export default withStyles(styles)(Card);
