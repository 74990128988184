import {Reducer} from 'redux';
import {Actions, actions} from './action';
import { Category, CompetencyFullInformation } from './type';
import { getType } from 'typesafe-actions';
import { getActionStartType, getActionEndType, getActionFailureType } from '../../services/actionService';

type State =  {
  category: Category[],
  fullInformation?: CompetencyFullInformation,
  loading: boolean,
  error?: string,
  savingResult?: boolean
}

const initialState: State = {
  category: [],
  loading: true,
};

export const reducer: Reducer<State, Actions> = (state: State = initialState, action: any): State => {
  switch (action.type) {
    case getType(actions.fetchFunctionalTranslation.request):
    case getActionStartType(actions.LOAD_CATEGORY_TRANSLATIONS):
    case getActionStartType(actions.SAVE_CATEGORY):
    case getActionStartType(actions.DELETE_CATEGORY):
    case getActionStartType(actions.SAVE_CATEGORY_WITH_TRANSLATIONS):
    case getActionStartType(actions.LOAD_CATEGORY_FULL):
      return {
        ...state,
        error:'',
        fullInformation: undefined,
        savingResult: undefined,
        loading: true
      };
    case getActionStartType(actions.SAVE_CATEGORY_FULL):
      return {
        ...state,
        error:'',
        savingResult: undefined,
      };
    case getType(actions.fetchFunctionalTranslation.failure):
    case getActionFailureType(actions.LOAD_CATEGORY_TRANSLATIONS):
    case getActionFailureType(actions.SAVE_CATEGORY):
    case getActionFailureType(actions.DELETE_CATEGORY):
    case getActionFailureType(actions.SAVE_CATEGORY_WITH_TRANSLATIONS):
    case getActionFailureType(actions.LOAD_CATEGORY_FULL):
    case getActionFailureType(actions.SAVE_CATEGORY_FULL):
      return {
        ...state,
        error: action ? (action.error ? action.error : (action.payload ? action.payload : '')) : '',
        loading: false,
        savingResult: false
      };
    case getType(actions.fetchFunctionalTranslation.success):
    case getActionEndType(actions.LOAD_CATEGORY_TRANSLATIONS):
      return {
        ...state,
        category: action.payload,
        loading: false
      };
    case getActionEndType(actions.SAVE_CATEGORY):
      return {
        ...state,
        category: [...state.category, action.payload],
        loading: false
      };      
    case getActionEndType(actions.DELETE_CATEGORY):
      return {
        ...state,
        category: state.category.filter(cat => cat!= action.payload),
        loading: false
      };
    case getActionEndType(actions.SAVE_CATEGORY_WITH_TRANSLATIONS):
      return {
        ...state,
        loading: false
      };   
    case getActionEndType(actions.LOAD_CATEGORY_FULL):
      return {
        ...state,
        fullInformation: action.payload,
        loading: false
      };
    case getActionEndType(actions.SAVE_CATEGORY_FULL):
      return {
        ...state,        
        savingResult: action.payload,
      };
    default:
      return state
  }
}

export default reducer;
