import * as React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { JnJGridRow, JnJGridColumn, NextIcon } from '@jaj/ui-components';
import cs from 'classnames';
import Image from './assets/Image.png';
import { Link } from 'react-router-dom';
import Container from '../Container/Container';

const styles = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    paddingTop: '50px',
    paddingBottom: '50px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '100px',
      paddingBottom: '100px'
    }
  },
  heading: {
    marginBottom: '20px',
    color: '#fff',
    fontSize: '30px',
    lineHeight: '30px',
    [theme.breakpoints.up('md')]: {
      fontSize: '42px',
      lineHeight: '45px',
      marginBottom: '30px'
    }
  },
  text: {
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 300
  },
  whiteText: {
    color: '#fff'
  },
  image: {
    width: '100%',
    height: 'auto'
  },
  rowStyles: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  readOn: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30
  },
});

const SectionWithImage = (props) => {
  const {classes, title, text} = props;
  return(
    <Container static className={classes.root}>
      <JnJGridRow className={classes.rowStyles}>
        <JnJGridColumn xs={12} md={1} />
        <JnJGridColumn xs={8} md={4}>
          <img
            src={Image}
            alt='How to get the most out of your interview'
            className={classes.image} />
        </JnJGridColumn>
        <JnJGridColumn  xs={12} md={1} />
        <JnJGridColumn xs={8} md={4}>
          <p className={classes.heading}>{title}</p>
          <p className={cs(classes.text, classes.whiteText)}>{text}</p>
          <Link to='/how-to-get-most-of-your-interview'>
            <span className={classes.readOn}>
              <Typography
                variant="body2"
                className={cs([classes.whiteText], 'mright-20')}
              >
                Read On
              </Typography>
              <NextIcon />
            </span>
          </Link>
        </JnJGridColumn>
        <JnJGridColumn xs={12} md={1} />
      </JnJGridRow>
    </Container>
  )
}

export default withStyles(styles)(SectionWithImage);
