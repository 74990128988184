import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import {
  JnJGridColumn,
  JnJGridRow,
  JnJButton,
  DumbIcon,
  ModalCloseIcon
} from '@jaj/ui-components';
import ModalPreviewPage from './ModalPreviewPage';
import { actions } from '../../store/selectCategory/action';
import { downloadReport } from '../../services/reportService';
import { getLanguageCode } from '../../store/language/selector';
import { onHistoryLinkClicked } from '../../services/historyLinksService';
import { selectReportDocRequest } from '../../store/selectCategory/selectors';
import { withRouter } from 'react-router-dom';
import { guideViewStatus } from "../../models/guideModel";
import * as languageActions from '../../store/language/action';

const styles = theme => ({
  selectType: {
    order: 2,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      order: 1,
    }
  },
  iconWithLabel: {
    '& span': {
      display: 'flex',
      justifyContent: 'space-between',
      minWidth: '110px',
    }
  },
  clearButton: {
    order: 1,
    flexBasis: 'auto',
    [theme.breakpoints.down('sm')]: {
      order: 3,
    }
  },
  clearbutton:{
    maxHeight: '44px',
    '&:hover':{
      color: '#d8d8d8',
      border: '2px solid #d8d8d8',
    }
  },
  deleteButton: {
    order: 2,
  },
  discardButton: {
    order: 3,
  },
  publishButton: {
    order: 4,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    }

  },
  previewButton: {
    order: 3,
    [theme.breakpoints.down('sm')]: {
      order: 4,
    }
  },
  inlineRadio: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'space-around'
    }
  },
  formControl: {
    flex: 1
  },
  loading: {
    paddingLeft: 5,
  },
  startOver: {
    fontSize: '16px',
    lineHeight: '26px',
    paddingLeft: '27px',
  },
  startOverSpan: {
    position: 'relative',
  },
  startOverImg: {
    position: 'absolute',
    top: '0',
    left: '-25px',
  },
  startOverButton: {
    display: 'flex'
  }
});

export class QuestionsListToolbar extends Component {
  state = {
    value: 'pdf',
    publishing: false,
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  onPublish = () => {
    this.setState({
      publishing: true,
    });
    downloadReport(this.props.reportDocRequest, 'docx', this.props.languageCode).then(() => {
      this.setState({
        publishing: false,
      });
    });
  };

  resetLanguage = () => {
    this.props.changeLanguage(this.props.guide.languageCode);
    this.props.changeModalLanguage(this.props.guide.languageCode);
  }

  render() {
    const {
      classes,
      resetAll,
      resetAllQuestion,
      questions,
      customQuestions
    } = this.props;
    const hasCustomQuestion = customQuestions.some(question => question.text);
    const controlsDisabled = (!questions.length && !hasCustomQuestion) || this.state.publishing;
    return (
      <>
        <JnJGridRow>
          {this.props.viewStatus !== guideViewStatus.edit && this.props.viewStatus !== guideViewStatus.copy &&
          <JnJGridColumn xs={6} md={2} className={classes.startOverButton}>
            <ButtonBase
              onClick={(e) => {
                this.props.history.push('/interview/select-guide-type');
                return resetAll(e);
              }}>
              <span className={classes.startOver}>
                <span className={classes.startOverSpan}><img className={classes.startOverImg} src='/images/arrow.png'></img></span>Start Over
              </span>
            </ButtonBase>
          </JnJGridColumn>
          }
          <JnJGridColumn xs={6} md={8} className={classes.clearButton}>
            <JnJButton
              color="primary"
              className={classes.clearbutton}
              variant="outlined"
              disabled={controlsDisabled}
              onClick={() => onHistoryLinkClicked({
                category: 'questions',
                action: 'resetAll',
                label: 'resetAll',
                onClick: resetAllQuestion
              })}
            >Clear All Questions</JnJButton>
          </JnJGridColumn>
          {(this.props.viewStatus === guideViewStatus.edit || this.props.viewStatus === guideViewStatus.copy) &&
             <JnJGridColumn className={classes.deleteButton}>
               <JnJButton
                 className={classes.iconWithLabel}
                 onClick={() => onHistoryLinkClicked({
                   category: 'questions',
                   action: 'resetAll',
                   label: 'resetAll',
                   onClick: () => this.props.onDelete()
                 })}
               >
                 <DumbIcon />
                 Delete Guide
               </JnJButton>
             </JnJGridColumn>}
          {this.props.viewStatus === guideViewStatus.edit &&
          <JnJGridColumn className={classes.discardButton}>
               <JnJButton
                 className={classes.iconWithLabel}
                 onClick={() => {
                   this.resetLanguage();
                   this.props.history.push({pathname: '/interview', state: {value: 1}}); 
                  }}
               >
                 <ModalCloseIcon />
                 Discard Changes
               </JnJButton>
             </JnJGridColumn>}
        </JnJGridRow>
        {this.props.modalVIewStatus &&
        <ModalPreviewPage
          scroll="paper"
          selectedFormat={this.state.value}
        />}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  questions: state.questions,
  reportDocRequest: selectReportDocRequest(state),
  languageCode: getLanguageCode(state),
  customQuestions: state.customQuestion,
  modalVIewStatus: state.category.previewModal.viewStatus,
  viewStatus: state.category.guideViewStatus,
  guide: state.category.guide
});

const mapDispatchToProps = {
  resetAll: actions.resetAll,
  resetAllQuestion: actions.resetAllQuestion,
  openPreviewModal: actions.openPreviewModal,
  changeLanguage:languageActions.select,
  changeModalLanguage: languageActions.changeModalLanguage
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(QuestionsListToolbar)));