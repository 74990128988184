import * as React from 'react';
import { NavLink } from 'react-router-dom';

const LinkNav = ({className, to, children, activeClass}) => {
  if (to.indexOf('http://') === 0 || to.indexOf('https://') === 0) {
    return <a href={to} target="_blank" className={className} rel="noopener noreferrer">{children}</a>;
  } else {
    return <NavLink to={to} className={className} activeClassName={activeClass}>{children}</NavLink>;
  }
};

export default LinkNav;
