import * as React from "react";
import { CircularProgress, withStyles, Theme, createStyles, WithStyles } from "@material-ui/core";

const styles = (theme: Theme) => createStyles({
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0',
  },
  loadingText: {
    size: '20px',
    color: '#FF0000',
    paddingRight: '3px',
  },  
});

interface LoadingProps extends WithStyles<typeof styles> {
  loadingMsg:any
}

class Loading extends React.PureComponent<LoadingProps, {}> {
  render() {
    const { classes, loadingMsg } = this.props;
    return (
      <div className={classes.loadingContainer}> 
        <div className={classes.loadingText}>{loadingMsg}</div>
        <CircularProgress />
      </div>
    );
  }
}

export default withStyles(styles)(Loading);
