
import React from "react"
import { JnJButton, PlusIcon } from "@jaj/ui-components";
import { withStyles } from "@material-ui/core";

const useStyles = theme => ({
  button: {
    padding: '8px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  dotsButton: {
    marginLeft: '10px',
    flexShrink: 0,
    flexGrow: 0,
    minWidth: 0,
    '&:hover ': {
      backgroundColor: '#CA001B',
      '& span': {
        '& span': {
          backgroundColor: '#ffffff',
        }
      }
    },
  },
  dot: {
    marginLeft: '2px',
    height: '5px',
    width: '5px',
    backgroundColor: '#CA001B',
    borderRadius: '50%',
    display: 'inline-block',
  },
  xButton: {
    marginLeft: '10px',
    flexShrink: 0,
    flexGrow: 0,
    minWidth: 0,
  },
  whitePlus: {
    transform: 'rotate(45deg)',
    filter: ' brightness(0) invert(100%) sepia(100%) saturate(99%) hue-rotate(341deg) brightness(111%) contrast(102%)'
    // ' saturate(100%) invert(94%) sepia(1%) saturate(70%) hue-rotate(360deg) brightness(93%) contrast(93%)
  }
});

const DotsButton = ({ classes, onClick, dots }) => (
  dots ? <JnJButton
      type={'submit'}
      variant={'outlined'}
      color={'primary'}
      onClick={onClick}
      className={`${classes.button} ${classes.dotsButton}`}>
        <span className={classes.dot}/>
        <span className={classes.dot}/>
        <span className={classes.dot}/>
    </JnJButton> : <JnJButton
      type={'submit'}
      variant={'contained'}
      color={'primary'}
      onClick={onClick}
      className={`${classes.button} ${classes.xButton}`}>
      <PlusIcon className={classes.whitePlus} />
    </JnJButton>
)


export default withStyles(useStyles)(DotsButton)