import { RootState } from "..";
import { StateType } from "typesafe-actions";
import { reducer } from "./reducer";

const rootSelector = (state: RootState): StateType<typeof reducer> => state.user;

export const selectIsWriter = (state: RootState): boolean => {
  const store = rootSelector(state);
  if (store.data) {
    return store.data.userIsInRoleWriter;
  }
  return false;
};
