import { createAction, createAsyncAction } from "typesafe-actions";
import { Language, LanguageCode } from "./type";


export const select = createAction('@@language/CHANGE_LANGUAGE',  resolve => {
  return (code: LanguageCode) => resolve(code);
});

export const changeModalLanguage = createAction('@@language/CHANGE_MODAL_LANGUAGE',  resolve => {
  return (code: LanguageCode) => resolve(code);
});

export const syncGuideLanguage = createAction('@@language/SYNC_GUIDE_LANGUAGE',  resolve => {
  return (code: LanguageCode) => resolve(code);
});

export const fetch = createAsyncAction(
  '@@language/FETCH_LANGUAGES_REQUEST',
  '@@language/FETCH_LANGUAGES_SUCCESS',
  '@@language/FETCH_LANGUAGES_FAIL',
)<void, Language[], string>();


