import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import { LightbulbInfo, JnJGridRow, JnJGridColumn, } from '@jaj/ui-components';
import Container from "../../components/Container/Container";
import { Link } from 'react-router-dom';
import cs from 'classnames';
import { UnderlinedEditIcon, NextIcon } from "@jaj/ui-components";

const styles = theme => ({
  textSection: {
    padding: '35px 0',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    }
  },
  title: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  tipContent: {
    maxWidth: 333,
    marginTop: 25,
    [theme.breakpoints.down('md')]: {
      marginTop: 0
    }
  },
  blueSection: {
    backgroundColor: '#000099',
    [theme.breakpoints.down('md')]: {
      margin: '60px -20px 0 -20px'
    }
  },
  blueSectionHeader: {
    marginBottom: '35px',
    fontSize: '30px',
    lineHeight: '30px',
    letterSpacing: -0.75,
    fontWeight: 'bold',
    maxWidth: 479,
    [theme.breakpoints.up('md')]: {
      fontSize: '42px',
      lineHeight: '45px',
      marginBottom: '20px',
    }
  },
  blueSectionText: {
    fontSize: '18px',
    lineHeight: '25px',
    fontWeight: 300,
    color: '#fff'
  },
  whiteText: {
    color: '#fff',
  },
  boldText: {
    fontWeight: 500
  },
  readOn: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 30,
    [theme.breakpoints.down('md')]: {
      // paddingRight: 40,
    }
  },
  blueSectionContent: {
    padding: '100px 0 110px 0',
    [theme.breakpoints.down('md')]: {
      padding: '45px 50px 80px 45px',
    }
  },
  iconClass: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginRight: 15,
    marginTop: 5,
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginBottom: 9
    }
  },
});

interface ChoosingYourTeamProps extends WithStyles {
}

const ScoringTheInterview = ({classes}: ChoosingYourTeamProps ) => (
  <div className={classes.root}>
    <JnJGridRow className="justify-center">
      <JnJGridColumn xs={12} md={10}>
        <div className={classes.textSection}>
          <Typography
            variant="h3"
            color="secondary"
            className={"margin-bottom-20 " + classes.title}
          >
            Scoring your interviews
          </Typography>
          <JnJGridRow>
            <JnJGridColumn xs={12} lg={8}>
              <Typography
                variant="body1"
                className="margin-bottom-20"
              >
                The grading rubric follows a 1–4 rating system. Use these tips to help your team evaluate candidates consistently and fairly.
              </Typography>
              <ul className="list">
                <li>
                  Review your chosen interview questions before the interview starts.
                </li>
                <li>
                  Review your notes to ensure you’ve captured appropriate details about specific examples from a candidate’s responses. The details you capture should be the ones that influence your decision-making.
                </li>
                <li>
                  Use your best judgment to assign a score for each competency dimension against which you interviewed. Your notes should capture why you assigned the score you chose.
                </li>
                <li>
                  Use the Interview Summary section at the end of each guide to transcribe all of the scores you assigned to one page.
                </li>
              </ul>
            </JnJGridColumn>
            <JnJGridColumn xs={12} lg={4}>
              <div className={classes.tipContent}>
                <LightbulbInfo direction="vertical">
                  Each interviewer on your team should track their notes and evaluations in their own copy of the guide and should use it to ensure that their questions are behaviorally based
                  and will leverage our Leadership Imperatives.
                </LightbulbInfo>
              </div>
            </JnJGridColumn>
          </JnJGridRow>
          <Typography
            variant="h4"
            color="secondary"
            className="margin-top-50 margin-bottom-20"
          >
            What happens when all interviews are completed?
          </Typography>
          <JnJGridRow>
            <JnJGridColumn md={9}>
              <Typography
                variant="body1"
              >
                It is the hiring manager’s responsibility to debrief with all of the interviewers and compile their ratings.
                The hiring manager should schedule a time to assess the candidate together—from their industry knowledge, skills, and accomplishments to their personality and professionalism.
                The goal is to find the right candidate for the position. This can be done through a variety of methods, including reaching consensus across interviewers or averaging across interviewers.
                You don't always have to reach consensus across all interviewers, but it is important that all candidates for a role are evaluated consistently
                (i.e., using the same method to total interview scores).
              </Typography>
            </JnJGridColumn>
          </JnJGridRow>
          <JnJGridRow>
            <JnJGridColumn xs={12} lg={8}>
              <Typography
                variant="body1"
                className="margin-bottom-15"
              >
                Here are some tips to keep in mind as you conduct the debrief meeting:
              </Typography>
              <ul className="list">
                <li>
                  Avoid collecting feedback individually. Debriefing in a group gives everyone a chance to hear each other's perspectives.
                </li>
                <li>
                  Try to hold this meeting in person or by phone—not through email. This makes it easier to have an open discussion where you can ask each other questions.
                </li>
                <li>
                  Try to schedule the debrief within a few days of the final interview so the interviews are still fresh in everyone's minds.
                </li>
                <li>
                  Base your discussion around the rubric in the Interview Guide, and make sure you understand why each team member scored as they did, especially when there are discrepancies.
                </li>
              </ul>
            </JnJGridColumn>
            <JnJGridColumn xs={12} lg={4}>
              <div className={classes.tipContent}>
                <LightbulbInfo direction="vertical">
                  Are you a hiring manager? Use the Interview Debrief Form at the end of the interview guide to to debrief with all interviewers and compile their ratings.
                </LightbulbInfo>
              </div>
            </JnJGridColumn>
          </JnJGridRow>
        </div>
      </JnJGridColumn>
    </JnJGridRow>
    <Container className={classes.blueSection}>
      <JnJGridRow className={cs("justify-center", classes.blueSectionContent)}>
        <JnJGridColumn xs={12} md={1}>
          <div className={classes.iconClass}>
            <UnderlinedEditIcon/>
          </div>
        </JnJGridColumn>
        <JnJGridColumn xs={12} md={6}>
          <p className={cs(classes.blueSectionHeader, classes.whiteText)}>
            Ready to create your own Interview Guide?
          </p>
          <p className={cs(classes.blueSectionText, classes.whiteText)}>
            Now that you’ve had a refresher in interviewing,
            it’s time to create your Interview Guide and meet with your candidates! If you have questions at any point in the process,
            refer back to this site, check out our video training modules, or contact your recruiter.
          </p>
          <Link to='/interview'>
              <span className={classes.readOn}>
                <Typography
                  variant="body2"
                  className={cs([classes.whiteText], classes.boldText, 'mright-10')}
                >
                  Create your own interview guide
                </Typography>
                <NextIcon />
              </span>
          </Link>
        </JnJGridColumn>
      </JnJGridRow>
    </Container>
  </div>
);

export default withStyles(styles)(ScoringTheInterview);
