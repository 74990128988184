import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import { JnJLink, JnJGridRow, JnJGridColumn } from '@jaj/ui-components';
import {ARTICLE_TAB_STYLES, ARTICLE_TAB_CONTENT} from './articleConstants';

const styles = theme => ({
  textSection: {
    padding: '35px 0 0 0',
    borderBottom: '1px solid #D8D8D8',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      borderBottom: 0
    }
  },
  learnMore: {
    marginTop: 20,
    marginBottom: 45,
    float: 'right',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      marginBottom: 15
    }
  },
  title: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  }
});

interface ChoosingYourTeamProps extends WithStyles {
 onChangeTab: (arg1: string) => void
}

const ChoosingYourTeam = ({onChangeTab, classes}: ChoosingYourTeamProps ) => (
  <div className={classes.root}>
    <JnJGridRow className="justify-center">
      <JnJGridColumn xs={12} md={10}>
        <div className={classes.textSection}>
          <JnJGridRow>
            <JnJGridColumn md={9}>
              <Typography
                variant="h3"
                color="secondary"
                className={"margin-bottom-20 " + classes.title}
              >
                Choose your lineup
              </Typography>
              <Typography
                variant="body1"
                className="margin-bottom-20"
              >
                Successful hiring decisions start with the right team of interviewers. Choose a team that can help you achieve your interview goals:
              </Typography>
              <ol className="list">
                <li>
                  To gather enough information on a candidate to make an informed hiring decision
                </li>
                <li>
                  To ensure that each candidate has a chance to demonstrate his or her skills, as well as learn more about the position and the company
                </li>
              </ol>
              <Typography
                variant="body1"
                className="margin-top-30 margin-bottom-20"
              >
                The interviewers could be managers, peers or direct reports to the position you’re hiring for.
                They may work in your location or in another part of the world. They may be on your team or work in a totally different sector. The level,
                location and function of an interviewer matters much less than the insight they can provide to help you make your hiring decision.
              </Typography>
              <Typography
                variant="body1"
                className="margin-bottom-20"
              >
                Think about areas where you could use some additional expertise, such as technical or leadership areas.
                Is there someone on your team who would be better able to assess a candidate’s experience than you would? If so, consider having that person interview and focus on that aspect of the role.
              </Typography>
              <Typography
                variant="body1"
                className="margin-bottom-20"
              >
                Also, think about who this candidate would interact with on the job. For example, if the role you are hiring for will spend
                a lot of time working with another team, consider adding someone from that team as an interviewer.
              </Typography>
              <Typography
                variant="body1"
                className="margin-bottom-20"
              >
                Finally, make sure that all of the interviewers:
              </Typography>
              <ul className="list">
                <li>
                  Are knowledgeable about the job
                </li>
                <li>
                  Have a clear and mutually agreed upon picture of who will be successful in the job
                </li>
                <li>
                  Have enough technical knowledge or expertise to evaluate the candidate’s technical proficiency
                </li>
              </ul>
              <Typography
                variant="h4"
                color="secondary"
                className="margin-top-50 margin-bottom-10"
              >
                How big should the team be?
              </Typography>
              <Typography
                variant="body1"
                className="margin-bottom-50"
              >
                The number of interviewers will likely depend on the role you’re hiring for. As a general rule, we recommend 2 to 4 additional interviewers.
                To maintain a reasonable number of interview experiences for the candidate (we recommend 3 interviews for a Professional Wage role,
                or no fewer than 2 and no more than 4), you may want to consider <a href="#" onClick={() => onChangeTab(ARTICLE_TAB_STYLES, true)}>panel interviews</a> instead of sequential individual interviews.
              </Typography>
            </JnJGridColumn>
          </JnJGridRow>
        </div>
        <div className={classes.learnMore} onClick={() => onChangeTab(ARTICLE_TAB_CONTENT, true)}>
          <JnJLink>What to ask in an interview</JnJLink>
        </div>
      </JnJGridColumn>
    </JnJGridRow>
  </div>
);

export default withStyles(styles)(ChoosingYourTeam);
