import { createAsyncAction } from "typesafe-actions";
import { TipStore } from "./tipReducer";

const fetch = createAsyncAction(
  '@@tip/FETCH_TIPS_REQUEST',
  '@@tip/FETCH_TIPS_SUCCESS',
  '@@tip/FETCH_TIPS_FAIL',
)<void, TipStore, string>();

export const actions = {
  fetch,
};
