import { call, put, select, all, fork, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import { loadCategoryTips } from '../../services/tipService';
import { actions } from './tipAction';
import { actions as categoryActions } from '../selectCategory/action';
import { selectDuration, selectPositionId } from './tipSelector';
import * as languageActions from '../language/action';
import { Tip } from './tipType';
import { TipStore } from './tipReducer';

function transformTips(tips: Tip[]): TipStore {
  const tipStore: TipStore = {};
  tips.forEach((tip) => {
    const tipCategoryId = tip.categoryId.toString();
    if (!tipStore[tipCategoryId]) {
      tipStore[tipCategoryId] = {};
    }
    tipStore[tipCategoryId][tip.length] = tip;
  });
  return tipStore
}

function* fetchTips() {
  try {
    const categoryId = yield select(selectPositionId);
    const duration = (yield select(selectDuration)) || 0;
    if (!categoryId) {
      return;
    }
    yield put(actions.fetch.request());
    const res = yield call(loadCategoryTips, categoryId, duration.toString());
    yield put(actions.fetch.success(transformTips(res)));
  }
  catch(error) {
    yield put(actions.fetch.failure('Error Load'));
  }
}

function* watchChangeTab() {
  yield takeLatest([
    getType(categoryActions.init),
    getType(languageActions.select)
  ],
  function* () {
    yield all([
      fork(fetchTips),
    ]);
  });
}

export function* saga() {
  yield all([
    fork(watchChangeTab),
  ]);
}
