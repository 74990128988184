export const guideViewStatus = {
  edit: 'edit',
  copy: 'copy'
};

export enum EDraftState {
  none = 0,
  draft = 1,
  copy = 2
}

export const guideStatus = {
  preview: 'preview',
  pending: 'pending',
  published: 'published'
};
