import { featureStatus } from './ModalFeatures'

export function renderModal(modalVIewStatus) {

  const title = `Check out what's new!`

  const featureOneText = `
  Start building your guide and come back at a later  time to finish up. Simply give it a name and click the “Save as draft” button. On your next visit, go to your interview guides and continue editing.
  `;
  const featureTwoText = `Save time and stay organized!
  We now store your finished guides online so you
  can easily find and use them in the future.`;

  const featureThreeText = `
  Need to make a small edit for a new interview? Now you can create a copy of an existing guide and make your tweaks without starting from scratch!
  `;

  switch (modalVIewStatus) {
    case featureStatus.featureOne:
      return {
        title: title,
        icon: require('../../styles/assets/sign.svg'),
        contentTitle: 'Save as draft',
        content: featureOneText,
        dots: {
          dot1: {
            class: `${this.props.classes.dot} ${this.props.classes.selectedDot}`,
            onClick: () => this.changeStatus(featureStatus.featureOne),
          },
          dot2: {
            class: this.props.classes.dot,
            onClick: () => this.changeStatus(featureStatus.featureTwo),
          },
          dot3: {
            class: this.props.classes.dot,
            onClick: () => this.changeStatus(featureStatus.featureThree),
          }
        },
        classes: {
          contentParent: this.props.classes.dialogContent,
          contentChild: this.props.classes.content
        },
        button: {
          class: this.props.classes.nextButton,
          submit: {
            color: 'primary',
            variant: 'contained',
            className: this.props.classes.publishButton,
            onClick: () => this.changeStatus(featureStatus.featureTwo),
            label: 'Next'
          }
        }
      };
    case featureStatus.featureTwo:
      return {
        title: title,
        icon: require('../../styles/assets/iconFeedbackStar.svg'),
        contentTitle: 'Reuse your guides',
        content: featureTwoText,
        dots: {
          dot1: {
            class: this.props.classes.dot,
            onClick: () => this.changeStatus(featureStatus.featureOne),
          },
          dot2: {
            class: `${this.props.classes.dot} ${this.props.classes.selectedDot}`,
            onClick: () => this.changeStatus(featureStatus.featureTwo),
          },
          dot3: {
            class: this.props.classes.dot,
            onClick: () => this.changeStatus(featureStatus.featureThree),
          }
        },
        classes: {
          contentParent: this.props.classes.dialogContent,
          contentChild: this.props.classes.content
        },
        button: {
          class: this.props.classes.nextButton,
          submit: {
            color: 'primary',
            variant: 'contained',
            className: this.props.classes.publishButton,
            onClick: () => this.changeStatus(featureStatus.featureThree),
            label: 'Next'
          }
        }
      };
    case featureStatus.featureThree:
      return {
        title: title,
        icon: require('../../styles/assets/Docs.svg'),
        contentTitle: 'Create a copy',
        content: featureThreeText,
        dots: {
          dot1: {
            class: this.props.classes.dot,
            onClick: () => this.changeStatus(featureStatus.featureOne),
          },
          dot2: {
            class: this.props.classes.dot,
            onClick: () => this.changeStatus(featureStatus.featureTwo),
          },
          dot3: {
            class: `${this.props.classes.dot} ${this.props.classes.selectedDot}`,
            onClick: () => this.changeStatus(featureStatus.featureThree),
          }
        },
        classes: {
          contentParent: this.props.classes.dialogContent,
          contentChild: this.props.classes.content
        },
        button: {
          class: this.props.classes.nextButton,
          submit: {
            color: 'primary',
            variant: 'contained',
            className: this.props.classes.publishButton,
            onClick: () => this.closeModal(),
            label: 'Got it, thanks!'
          }
        }
      };
    default:
      return console.error("Something went wrong");
  }
};


