import * as ratingAPI from "../../services/ratings";
import { IEntityTranslation } from '../../models/questionModels';
import { IRating, IRatingWithTranslations } from '../../models/ratingModels';

export const enrichRatingFromExisted = (categoryId: number, questionId:number, newRatings: IRatingWithTranslations[], oldRatings?: IRatingWithTranslations[]): IRatingWithTranslations[] => {
  if (newRatings && newRatings.length > 0) {
    newRatings.forEach(rating=> {
        rating.questionId = questionId;
        rating.categoryId = categoryId;
        const engTranslation = rating.translations ? rating.translations.find(val => val.languageCode === 'EN') : undefined;
        if (engTranslation) {
          rating.text = engTranslation.text;
          rating.description = engTranslation.description;
          rating.languageCode = engTranslation.languageCode;
        }
        if (oldRatings && oldRatings.length > 0) {
          const existRating = oldRatings ? oldRatings.find(_ => _.value == rating.value) : undefined;        
          if (existRating) {
            rating.id = existRating.id;
          }        
        }
      });
  }
  return newRatings;
}

export const updateRatingsPromise = (ratings: IRatingWithTranslations[]): Promise<boolean[]> => {
  if (!Array.isArray(ratings) || ratings.length === 0) {
    return new Promise<boolean[]>((resolve) => {
      resolve([]);
    });
  }

  const rtPromises = ratings.map(rating => {
    if (rating.id && rating.text && rating.text.length > 0) {
      return ratingAPI.saveRatingTranslationsRequest(rating.id, rating.translations).then(_ => {
        return _ && _.length > 0 ? true : false;
      });
    }
    else if (rating.id && (!rating.text || rating.text.length == 0)) {
      return ratingAPI.deleteRatingRequest(rating.id).then(_ => {
        return true;
      });
    }
    else if (!rating.id && rating.text && rating.text.length > 0) {
      return createRatingPromise(rating.categoryId, rating.questionId, rating.value, rating.translations).then(_ => {
        return _ && _.length > 0 ? true : false;
      });
    }
    else { 
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    }
  });

  return Promise.all(rtPromises);
}

export const updateRatingPromise = (id: number, translations: IEntityTranslation[]) => {
  return ratingAPI.saveRatingTranslationsRequest(id, translations);
}

export const createRatingsPromise = (ratings: IRatingWithTranslations[]): Promise<boolean[]> => {
  if (!Array.isArray(ratings) || ratings.length === 0) {
    return new Promise<boolean[]>((resolve) => {
      resolve([]);
    });
  }

  const rtPromises = ratings.map(rating => {
    if (!rating.id && rating.text && rating.text.length > 0) {
      return createRatingPromise(rating.categoryId, rating.questionId, rating.value, rating.translations).then(_ => {
        return _ && _.length > 0 ? true : false;
      });
    }
    else { 
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    }
  });

  return Promise.all(rtPromises);
}

export const createRatingPromise = (categoryId: number, questionId:number| undefined, value: number, translations: IEntityTranslation[]): Promise<IEntityTranslation[]> => {
  if (!Array.isArray(translations) || translations.length === 0) {
    return new Promise<IEntityTranslation[]>((resolve) => {
      resolve([]);
    });
  }
  
  const rating = {
    ...translations[0],
    categoryId: categoryId,
    questionId: questionId ? questionId : null,
    value: value
  } as IRating;

  const response = ratingAPI.createRatingRequest(rating).then((data) => {
    return ratingAPI.saveRatingTranslationsRequest(data.id, translations)
  });
  return response;
};

export const loadRatingsPromise = (categoryId: number, questionId?: number): Promise<IRatingWithTranslations[]> => {  
  const response = ratingAPI.loadRatingsByCategory('en', categoryId).then((data) => {
    if (data && data.length > 0) {

      const filteredItems = questionId 
        ? data.filter(q => q.questionId == questionId)
        : data;

      const promises = filteredItems.map(rating => {
        return ratingAPI.loadRatingWithTranslationsRequest(rating.id).then((translations) => {
          return {
            ...rating,
            translations:translations
          } as IRatingWithTranslations;
        })
      });

      return Promise.all(promises);
    }    
    return new Promise<IRatingWithTranslations[]>((resolve) => {
      resolve([]);
    });
  });

  return response;
};

export const deleteRatingsPromise = (ratings: IRatingWithTranslations[]): Promise<boolean[]> => {
  if (!Array.isArray(ratings) || ratings.length === 0) {
    return new Promise<boolean[]>((resolve) => {
      resolve([]);
    });
  }

  const rtPromises = ratings.map(rating => {
    if (rating.id) {
      return ratingAPI.deleteRatingRequest(rating.id).then(_ => {
        return true;
      });
    }
    else { 
      return new Promise<boolean>((resolve) => {
        resolve(false);
      });
    }
  });

  return Promise.all(rtPromises);
}
