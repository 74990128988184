import React, { Component } from 'react';
import { Form, Field, reduxForm, InjectedFormProps } from 'redux-form';
import { withStyles, createStyles } from "@material-ui/core";
import RenderLanguagesComponents from './RenderLanguagesComponents';
import { TextFullField } from '../ReduxFormFields';
import Typography from "@material-ui/core/Typography";
import { RadioGroup } from "../RadioGroup";

const translateFormStyles = createStyles({
  root: {
    margin: "75px 0",
  },
  marginTop: {
    marginTop: 55
  }
});


function EntityTranslateForm(props) {

  const getRatingOptions = () =>{
    return [
      {
        title: "Use Default",
        value: "false",
      },
      {
        title: "Enter custom scale (4 highest, 1 lowest)",
        value: "true",
      },
    ];
  }

  const getRatingValueComponent = (val:string) => {
    return (<div className="flex justify-space-between margin-bottom-20">
      <Typography variant="h5" className="margin-right-10" style={{justifyContent: 'center'}}>{val}</Typography>
      <Field
        name={"custom_rating"+val}
        component={TextFullField}
        label=""
        fullWidth
        multiline
        placeholder="Enter Rating Description"
        rowsMax={4}
      />
    </div>);
  }

  return (
    <div className={props.classes.root}>
      <Field
        name="text"
        component={TextFullField}
        type="textField"
        label={props.entityText}
        fullWidth
        multiline
        placeholder="Input your translation"
        rowsMax="4"
      />
      {
        !!props.showDescription &&
          <div className={props.classes.marginTop}>
            <Field
              name="description"
              component={TextFullField}
              label="Description"
              fullWidth
              multiline
              placeholder="Input description"
              rowsMax="4"
            />
          </div>
      }
      {
        !!props.showProbingQuestions &&
          <div className={props.classes.marginTop}>
            <Field
              name="probingQuestions"
              component={TextFullField}
              label="Probing questions"
              fullWidth
              multiline
              placeholder="Input probing questions"
              rowsMax={4}
            />
          </div>
      }
      {
        !!props.showRatings &&
        <div>
          <div className="margin-top-40 margin-bottom-20">
            <Typography variant="h5" className="margin-bottom-15">Rating Criteria</Typography>
            <RadioGroup options={getRatingOptions()} value={props.isCustomRatings !== undefined ? props.isCustomRatings.toString() : 'false'} onChange={props.onChangeRatingType}  />
          </div>

          { !!props.isCustomRatings &&          
            <div style={{width: '70%'}}>
              {getRatingValueComponent('4')}
              {getRatingValueComponent('3')}
              {getRatingValueComponent('2')}
              {getRatingValueComponent('1')}
            </div>
          }
        </div>          
      }
    </div>
  );
}

const EntityTranslateFormWithStyles = withStyles(translateFormStyles)(EntityTranslateForm);

type EntityTranslateFullAddEditFormProps = {
  showDescription?:boolean,
  showProbingQuestions?: boolean;
  showRatings?: boolean;
  entityText: string;
}

class EntityTranslateFullAddEditForm extends Component<
  InjectedFormProps<{}, EntityTranslateFullAddEditFormProps> & EntityTranslateFullAddEditFormProps
> {  
  render() {
    const {
      showProbingQuestions,
      showDescription,
      showRatings,
      entityText,
      initialValues
    } = this.props;
    return (
      <Form onSubmit={() => {}}>
        <RenderLanguagesComponents
          TranslateComponent={EntityTranslateFormWithStyles}
          showDescription={showDescription}
          showProbingQuestions={showProbingQuestions}
          showRatings={showRatings}
          entityText={entityText}
          initialValues={initialValues}
          change={this.props.change}
        />
      </Form>
    );
  }
}

export default reduxForm<{}, EntityTranslateFullAddEditFormProps>({
  form: 'translateQuestions',
})(EntityTranslateFullAddEditForm);
