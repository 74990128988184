import React from 'react';
import { renderSelectField, renderTextField } from '../../../components/ReduxFormFields/';
import {Field} from 'redux-form';
import {MenuItem, withStyles, InputLabel} from '@material-ui/core/';
import {
  JnJGridRow,
  JnJGridColumn,
  JnJButton,
  PlusIcon,
  SecondaryLink,
  DumbIcon } from '@jaj/ui-components';

const styles = theme => ({
  addNew:{
    paddingLeft: 0,
    display: 'flex',
    alignItems: 'center',
    '&:hover':{
      backgroundColor: 'transparent'
    }
  },
  label:{
    fontSize: 14,
    lineHeight: '18px',
    fontWeight: 100
  },
  menuItem: {
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 100
  },
  editButton: {
    width: '100%'
  },
  textField: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 19
    }
  }
});

const RenderTranslation = ({ fields, meta: { error, submitFailed },
  languages,
  initialValues,
  classes
}) => {
  let {langOptionsArray} = languages;
  const formValues = fields.getAll();
  const langObject = languages.reduce((agr, {code, name}) => ({...agr, [code]: name}), {});
  let choosenLanguages = formValues.map(lang => {
    return lang ? lang.languageCode : ''
  });

  const currentOptions = (arr1, val) => {
    return arr1.filter(item => !val.includes(item.code));
  };

  langOptionsArray = currentOptions(languages, choosenLanguages);

  return (
   <React.Fragment>
     {fields.map((name, index) => (
        <JnJGridRow key={index}>
          <JnJGridColumn xs={12} md={1} />
          <JnJGridColumn xs={12} md={2}>
            <InputLabel
              className={classes.label}
              disabled={index === 0}
            >
              {index !== 0 ? 'Translation' : 'Required'}
            </InputLabel>
            <Field
              name={`${name}.languageCode`}
              component={renderSelectField}
              displayEmpty
              disabled={index === 0}
            >
              <MenuItem value="" disabled className={classes.menuItem}>
                Language
              </MenuItem>
              {(choosenLanguages[index] !== '') &&
                <MenuItem value={choosenLanguages[index]} className={classes.menuItem}>
                  {langObject[choosenLanguages[index]]}
                </MenuItem>
              }
              {langOptionsArray && langOptionsArray.map((language, index) => (
                <MenuItem key={index} value={language.code} className={classes.menuItem}>{language.name}</MenuItem>
              ))}
            </Field>
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={5}>
            <Field
              name={`${name}.text`}
              component={renderTextField}
              className={classes.textField}
            />
          </JnJGridColumn>
          
          {index === 0 ? (
            <JnJGridColumn xs={8} sm={6} md={2}>           
            </JnJGridColumn>
          ) : (          
            <JnJGridColumn xs={4} sm={6} md={2}>
              {index !== 0 ?
                <JnJButton onClick={() => fields.remove(index)}>
                  <DumbIcon />
                </JnJButton> : null
              }
            </JnJGridColumn>
          )}
         </JnJGridRow>
     ))}
     <JnJGridRow>
      <JnJGridColumn xs={12} md={1} />
       <JnJGridColumn xs={12} md={11}>
        <JnJButton
          onClick={() => fields.push()}
          className={classes.addNew}
        >
            <PlusIcon />
            <SecondaryLink className="mleft-10">
              Add translation
            </SecondaryLink>
        </JnJButton>
       </JnJGridColumn>
    </JnJGridRow>
   </React.Fragment>
)}


export default withStyles(styles)(RenderTranslation)
