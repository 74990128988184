import React, { Component } from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import { actions } from '../../store/customQuestions/action';
import { actions as questionActions } from '../../store/questions';

import YourGuideCategory from './YourGuideCategory';
import YourGuideCategoryQuestions from './YourGuideCategoryQuestions';
import { getCategoryIndexById } from '../../services/categoryService';

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    paddingTop: '40px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '10px',
      paddingBottom: '5px'
    }
  },
  headerWrapper: {
    //display: 'flex',
    display: 'none',
    alignItems: 'baseline',
    marginBottom: '25px',
  },

  header: {
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      fontSize: '24px',
      lineHeight: '42px',
    }
  },

  headerWrapperGuideInfo: {
    fontSize: '36px',
    lineHeight: '42px',
    fontWeight: '300',
    paddingLeft: '10px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '25px',
    }
  },


});

class YourGuide extends Component {

  static propTypes = {
    chosenQuestions: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        categoryId: PropTypes.number.isRequired,
      }).isRequired,
    ),
    questionCategories: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
    customQuestions: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      }).isRequired,
    ),
  }


  renderCategories() {

    const { chosenQuestions, questionCategories, customQuestions, categoryStore, onChangeTab, removeQuestion, toggle, competency } = this.props;

    return questionCategories.map((category, index) => {
      const hasSubHeader = category.text.includes('Functional') || category.text.includes('Leadership');
      let categoryQuestions = chosenQuestions.filter(el => categoryStore[el.categoryId].parents.includes(category.id));
      const options = {
        hasSubHeader,
        category,
        categoryQuestions,
        removeQuestion: toggle,
        key: category.id,
        categoryId: competency
      };
      if (
        chosenQuestions.length > 0
        && categoryQuestions.length > 0
        && index + 1 < 4
      ) {
        if (hasSubHeader) {
          categoryQuestions = categoryQuestions.reduce((result, question) => ({
            ...result,
            [categoryStore[question.categoryId].text]: [
              ...(result[categoryStore[question.categoryId].text] || []),
              question,
            ],
          }),
            {});
          return <YourGuideCategoryQuestions
            {...options}
            categoryQuestions={categoryQuestions}
          />;
        }
        if (categoryQuestions.length > 0) {
          return <YourGuideCategoryQuestions {...options} />;
        }
      }

      // for Custom questions
      if (customQuestions.length > 0 && index + 1 === 4) {
        let categoryChosenQuestions = customQuestions.filter(el => el.text.length > 0);
        if (categoryChosenQuestions.length > 0) {
          return <YourGuideCategoryQuestions
            {...options}
            removeQuestion={removeQuestion}
            categoryQuestions={categoryChosenQuestions}
          />;
        }
      }
      return <YourGuideCategory key={category.id} index={index} tab={getCategoryIndexById(index)} category={category} onChangeTab={onChangeTab} categoryId={competency} />;
    });
  }

  render() {

    const { chosenQuestions, questionCategories, customQuestions, classes } = this.props;
    let customQuestionsSize = 0;
    customQuestions.forEach(element => {
      if (element.text.length > 0) {
        customQuestionsSize += 1;
      }
    });
    const allChosenQuestionsSize = chosenQuestions.length + customQuestionsSize;
    return (
      <div className={classes.root}>
        <div className={classes.headerWrapper}>
          <Typography className={classes.header} variant="h2">
            Your Guide:
        </Typography>
          {allChosenQuestionsSize === 0 ?
            <p className={classes.headerWrapperGuideInfo}>Time to add some questions!</p>
            :
            <p className={classes.headerWrapperGuideInfo}>{allChosenQuestionsSize} {allChosenQuestionsSize > 1 ? 'questions' : 'question'}</p>}
        </div>
        <div>
          {questionCategories &&
            this.renderCategories()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  chosenQuestions: state.questions && state.questions.map(id => state.category.questionStore[id]),
  questionCategories: state.category.questionCategory && state.category.questionCategory.map(id => state.category.categoryStore[id]),
  customQuestions: state.customQuestion,
  categoryStore: state.category.categoryStore,
  competency: state.category.competency,
  functionCategoryId: state.category.guide.functionCategoryId
});

const mapDispatchToProps = {
  removeQuestion: actions.removeQuestion,
  toggle: questionActions.toggle
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(YourGuide));
