import React, { useState } from 'react';
import { JnJButton, ModalCloseIcon } from '@jaj/ui-components';
import {
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme) => createStyles({
  dialogContent: {
    width: '100%',
    backgroundColor: '#f4f4f4',
    padding: '10px 10px 25px 10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },
  },
  closeIcon: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '22px',
    minWidth: '22px',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  header: {
    minHeight: '10px',
    color: '#000099',
    fontSize: ' 24px',
    fontWeight: ' 900',
    lineHeight: ' 28px',
  },
  dialogContentText: {
    display: 'flex',
    color: '#ff0000',
    flexDirection: 'column',
    padding: '4px 0 9px 0',
    justifyContent: 'end',
  },
  /* dialogMessage: {
    minHeight: '30px',
    fontSize: '20px',
    fontWeight: ' 300',
    lineHeight: ' 30px',
    padding: '20px 0',
  }, */
  /* selectedItem: {
    minHeight: '26px',
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '26px',
    padding: '20px 0',
    [theme.breakpoints.down("md")]: {
      display: 'flex',
      flexDirection: 'column',
    },
  }, */
  dialogTitle: {
    padding: '4px 0',
  },
  /* buttons: {
    margin: 0,
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      minHeight: '160px',
      alignItems: 'unset',
      justifyContent: 'space-around',
      flexDirection: 'column-reverse',
    }
  },
  deleteButton: {
    minWidth: '180px'
  },
  cancelButton: {
    minWidth: '130px',
    [theme.breakpoints.down('md')]: {
      minWidth: '180px',
    }
  },
  selectedItemInfo: {
    fontWeight: 'bold',
    marginLeft: '15px',
    [theme.breakpoints.down("md")]: {
      marginLeft: '0px',
    },
  } */
});

const ErrorModalDialog = (props) => {
  const { open, errorCode, errorMsg, classes } = props;
  const [modalOpen, setModalOpen] = useState(open);

  const modalClose = () => {
      setModalOpen(false);
  }

  var dialogTitle = ""; 
  if (errorCode != null) {
    dialogTitle = <DialogTitle className={classes.dialogTitle}>
        <div className={classes.header}>
          {errorCode}
        </div>
    </DialogTitle>;
  }  
  return (
    <div>
      <Dialog
        open={modalOpen}
        onClose={() => modalClose()}
        maxWidth="md"
        fullWidth
        PaperProps={{
          style: {
            backgroundColor: '#f4f4f4',
            width: '240px',
            textAlign: "center"
          },
        }}
      >
        <div className={classes.dialogContent}>
          <JnJButton onClick={() => modalClose()} className={classes.closeIcon}>
            <ModalCloseIcon />
          </JnJButton>
          {dialogTitle}
          <DialogContent className={classes.dialogContentText}>
            <div>
              {errorMsg}
            </div>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default withStyles(styles)(ErrorModalDialog);
