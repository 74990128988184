import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import {
  JnJButton,
} from '@jaj/ui-components';
import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    padding: '60px',
    margin: '10px 0',
    backgroundColor: '#F4F4F4',
    [theme.breakpoints.down('md')]: {
      padding: '25px',
    }
  },
  categoryNameHeader: {
    marginBottom: '17px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '26px',
      marginBottom: '11px',
    }
  },
  mainSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    }
  },
  categoryDescription: {
    flex: 8,
    fontSize: '16px',
    lineHeight: '26px',
    fontWeight: '300',
  },
  addQuestionButtonWrapper: {
    flex: 2,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: '20px',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0',
      marginTop: '10px'
    }
  },
  addQuestionButton: {
    whiteSpace: 'nowrap',
  }
});

class YourGuideCategory extends Component {
  onAddQuestions = () => {
    this.props.onChangeTab(null,  this.props.tab);
  };

  render() {
    const {category, index, currentCompetency, classes} = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.categoryNameHeader} variant="h3" color="secondary">
          {
            index === 2 && currentCompetency && currentCompetency.text
            ? `${category.text}: ${currentCompetency && currentCompetency.text}`
            : category && category.text
          }
        </Typography>
        <div className={classes.mainSection}>
          <p className={classes.categoryDescription}>{category && category.description ? category.description : 'These questions help you explore how the candidate demonstrates and inspires the behaviors that reinforce Our Credo.'}</p>
          <div className={classes.addQuestionButtonWrapper}>
            <JnJButton
                className={classes.addQuestionButton}
                color="secondary"
                size="small"
                variant="contained"
                onClick={this.onAddQuestions}
                disabled={(this.props.functionCategoryId === 1 || this.props.functionCategoryId === null) && category.text === "Functional Competencies"}
                >
              Add Questions
            </JnJButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({category}, {categoryId}) => ({
    currentCompetency: category.categoryStore[categoryId] || '',
    functionCategoryId: category.guide.functionCategoryId
});

export default connect(mapStateToProps)(withStyles(styles)(YourGuideCategory));
