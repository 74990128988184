import React from "react"
import { JnJButton } from "@jaj/ui-components";
import { withStyles } from "@material-ui/core";
// import { downloadReport } from '../../services/reportService';
// import { onHistoryLinkClicked } from '../../services/historyLinksService';


const useStyles = theme => ({
    button: {
        padding: '8px 10px',
        fontSize: '14px',
        fontWeight: 'bold',
        textTransform: 'none',
    },
    previewButton: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'center',
        '&:hover': {
            '& img': {
                filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(182deg) brightness(108%) contrast(101%)',
            }
        }
    },
    downIcon: {
        marginRight: '10px',
    },
});


const PreviewButton = ({ classes, onClick}) => (
    <JnJButton
      type={'submit'}
      variant={'outlined'}
      color={'primary'}
      className={`${classes.button} ${classes.previewButton}`}
      onClick={onClick}
    >
        <img src={require('../../styles/assets/download.svg')} alt='download' className={classes.downIcon} />
        {'Preview & Download'}
    </JnJButton>
)





export default withStyles(useStyles)(PreviewButton);
