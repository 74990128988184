import React from "react"
import { DumbIcon } from "@jaj/ui-components";
import { IconButton, withStyles } from "@material-ui/core";

const useStyles = theme => ({
  button: {
    padding: '8px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "pointer"
    }
  },
})

const TrashButton = ({ classes, trashButtonClick, label }) => (
  <div >
    <IconButton onClick={trashButtonClick} className={classes.iconButton}>
      <DumbIcon />
      {label}
    </IconButton>
  </div>
)

export default withStyles(useStyles)(TrashButton);