import React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { JnJButton, DownloadIcon, LinkArrowIcon } from '@jaj/ui-components';
import { Theme } from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';

const styles = (theme: Theme) => createStyles({
  resourcesItem: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '35px 0',
    borderBottom: '1.5px solid #D8D8D8'
  },
  typography: {
    fontWeight: 300,
    fontSize: 18,
    lineHeight: '30px',
    [theme.breakpoints.up('lg')]: {
      fontWeight: 300,
      fontSize: 28,
    },
  },
  resourcesItemDownload: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  typographyFileFormat: {
    display: 'block',
    marginRight: "100px",
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  typographyFileFormatMobile: {
    display: 'none',
    [theme.breakpoints.down('md')]: {
      display: 'block',
      fontWeight: 300,
      marginTop: 0,
    },
  },
  resourceTitle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexGrow: 1,
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
    },
  },
  descriptionText: {
    marginTop: 13,
    paddingRight: 210,
    fontSize: 16,
    fontWeight: 300,
    lineHeight: '26px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: 0,
    },
  },
});

interface ResourcesProps {
  title: string,
  path?: string,
  description?: string | React.ReactChild ,
  fileFormat: string,
  onDownload: Function,
  redirectable?: boolean
}

interface Options {
  href: string,
  target?: string,
  download?: string,
}

const Resources = (props: ResourcesProps & WithStyles) => {
  const {
    classes,
    fileFormat,
    title,
    path,
    description,
    onDownload,
    redirectable
  } = props;

  const options: Options = 
     {
      href: redirectable ? path : '/downloads/resource/'+ path,
      download: path,
      target: '_blank'
    };

  return (
    <div className={classes.resourcesItem}>
      <a {...options} style={{ width: `100%` }} className={classes.resourcesItemDownload} onClick={(e) => onDownload(e)}>
        <div className={classes.resourceTitle}>
          <div>
            <Typography
              variant="h3"
              color="secondary"
              className={classes.typography}
            >
              {title}
            </Typography>
            <Typography
              variant='subtitle1'
              className={classes.typographyFileFormatMobile}
            >
              {fileFormat}
            </Typography>
            {typeof description === 'string' ? <Typography
              variant="subtitle1"
              className={classes.descriptionText}
            >
              {description}
            </Typography>
            : <>{description}</>}

          </div>
          <Typography
            variant='subtitle1'
            className={classes.typographyFileFormat}
          >
            {fileFormat}
          </Typography>
        </div>
        <div >        
          <JnJButton>
            {redirectable ? <LinkArrowIcon/> : <DownloadIcon /> }
          </JnJButton>        
        </div>
      </a>
    </div>
  );
};

export default withStyles(styles)(Resources);
