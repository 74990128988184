import React from 'react'
import LinkNav from '../LinkNav';
import { JnJFooter } from '@jaj/ui-components';

export default function Footer() {
  return (
    <JnJFooter
      linkComponent={LinkNav}
      links={[
        {
          label: 'Terms of Use',
          url: 'https://www.jnj.com/corporate/legal-notice',
        },
        {
          label: 'Privacy Statement',
          url: 'https://www.jnj.com/corporate/privacy-policy',
        }
      ]}
    />
  )
}
