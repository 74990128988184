import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Typography from "@material-ui/core/Typography";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { Theme, Button } from "@material-ui/core";
import { JnJGridRow, JnJGridColumn, JnJButton } from "@jaj/ui-components";
import cs from 'classnames';
import Container from "../../components/Container/Container";
import { Select } from "../../components/Select";
import { Input } from "../../components/Input";
import { RadioGroup } from "../../components/RadioGroup";
import { actions as selectCategoryActions } from "../../store/selectCategory";
import { Duration } from "../../store/selectCategory/type";
import { actions as editCategoryActions } from "../../store/editCategory";
import { CategoryDTO } from "../../store/selectCategory/type";

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: "#fff",
    paddingTop: "100px",
    paddingBottom: "55px",
  },
  typographyTitle: {
    marginBottom: 40,
    letterSpacing: "-0.5px",
  },
  nameInput: {
    maxWidth: "490px",
  },
});

interface ResourcesPageProps extends WithStyles{
  categories?: CategoryDTO[],
  isCategoriesLoaded?: boolean,
  getCompetenty: Function,
  init:Function,
  selectGuideType:Function,
  fetchGuideTypes: Function,
  saveCategory:Function,
  selectCompetency: Function,
  selectDuration: Function
}

interface ResourcesPageState {
  contributor: number,
  text: string,
  ownsRatings?: boolean,
  ownsProbingQuestions?: boolean,
  loading:boolean,
  saving:boolean
}
class AddCompetencyPage extends React.Component<ResourcesPageProps, ResourcesPageState> {
  constructor(props){
    super(props);
    this.state = {
      contributor: 0,
      text: "",
      loading: false,
      saving: false
    }
  }
  componentDidMount() {
    const guideType = parseInt(this.props.match.params.id);
    if (guideType && this.state.contributor != guideType) {
      const isInit = this.props.categories && this.props.categories.length;
      if (!isInit) {
        this.props.init();
      }
      this.setState({ contributor: guideType });
    }
  }

  componentWillReceiveProps(nextProps:ResourcesPageProps) {
    if (nextProps.categories && nextProps.categories.length > 0) {
      if (!nextProps.isCategoriesLoaded && this.state.contributor && !this.state.loading) {
        this.setState({ loading: true });
        nextProps.selectGuideType(this.state.contributor);
      }
    }
  }

  getContributorOptions() {
    return this.props.categories.map((category: CategoryDTO) => {
      return {
        title: category.text.replace(",", ""),
        value: category.id.toString(),
      }
    });
  }

  getRatingOptions() {
    return [
      {
        title: "A rating scale will be provided with each question",
        value: "false",
      },
      {
        title: "One rating scale will be provided for each competency",
        value: "true",
      },
    ];
  }

  getProbingQuestionOptions() {
    return [
      {
        title: "Probing questions will be provided with each question",
        value: "false",
      },
      {
        title: "One set of probing questions will be provided for each competency",
        value: "true",
      },
    ];
  }

  onChangeContributor = (value: string) => {
    this.setState({
      contributor: parseInt(value),
    });
  };

  onChangeRating = (value: string) => {
    this.setState({
      ownsRatings: value === 'true',
    });
  };

  onChangeProbingQuestions = (value: string) => {
    this.setState({
      ownsProbingQuestions: value === 'true',
    });
  };

  onChangeFunctionName = (value: string) => {
    this.setState({
      text: value,
    });
  };

  onSaveCompetency = () => {
    const competency = this.props.getCompetenty(this.state.contributor);
    const categoryToSave = {
      ...this.state,
      parentId: competency ? competency.id : this.state.contributor,
      type: "FunctionalCompetency",
    };
    this.setState({saving:true});
    this.props.saveCategory(categoryToSave).then(cat => {
      const newCategory = cat.payload;
      this.props.selectCompetency(newCategory.id);
      this.props.selectDuration(Duration.hour);
      this.props.history.push('/interview');
    });
  };

  onCancel = () => {
    this.props.history.push('/interview');
  };

  getButtonStatus = (): boolean => {
    return !this.state.contributor ||
      !this.state.text ||
      this.state.ownsRatings === undefined ||
      this.state.ownsProbingQuestions === undefined ||
      this.state.saving;
  };

  render() {
    const {classes} = this.props;
    return (
      <Container
        static
        className={classes.root}
      >
        <JnJGridRow className="justify-center">
          <JnJGridColumn xs={12} md={10}>
            <Typography variant="h1" color="secondary" className="margin-bottom-20">Add Functional Competency</Typography>
            <Typography variant="h5" className="margin-bottom-30">After we collect everything for English, you will have the opportunity to add translations.</Typography>
            <div className="margin-bottom-20">
              <Select
                label="For"
                options={this.getContributorOptions()}
                value={this.state.contributor.toString()}
                onChange={this.onChangeContributor}
                disabled={true}
              />
            </div>
            <div className={cs('margin-bottom-60', classes.nameInput)}>
              <Input label="Enter Function Name (English)" value={this.state.text} onChange={this.onChangeFunctionName} fullWidth />
            </div>
            <div className="margin-bottom-20">
              <Typography variant="h5" className="margin-bottom-15">Rating Scale Format:</Typography>
              <RadioGroup options={this.getRatingOptions()} value={this.state.ownsRatings !== undefined ? this.state.ownsRatings.toString() : ""} onChange={this.onChangeRating}  />
            </div>
            <div className="margin-bottom-20">
              <Typography variant="h5" className="margin-bottom-15">Probing Questions Format:</Typography>
              <RadioGroup options={this.getProbingQuestionOptions()} value={this.state.ownsProbingQuestions !== undefined ? this.state.ownsProbingQuestions.toString() : ""} onChange={this.onChangeProbingQuestions}  />
            </div>
          </JnJGridColumn>
        </JnJGridRow>
        <JnJGridRow className="justify-center margin-top-50">
          <JnJGridColumn className="flex justify-space-between" xs={12} md={10}>
            <JnJButton color="primary" variant="outlined" onClick={this.onCancel}>Cancel</JnJButton>
            <JnJButton color="primary" variant="contained" disabled={this.getButtonStatus()} onClick={this.onSaveCompetency}>Save & Continue</JnJButton>
          </JnJGridColumn>
        </JnJGridRow>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  categories: state.category.categories.map(id => state.category.categoryStore[id]),
  isCategoriesLoaded: state.category.categoryStore && Object.keys(state.category.categoryStore).length > 10,
  getCompetenty: (guideType:number) => {
    for(const catId in state.category.categoryStore) {
      const cat = state.category.categoryStore[catId];
      if (cat.parentId == guideType && cat.text.indexOf('Functional')>=0)
        return cat;
    }
  }
});

const mapDispatchToProps = {
  fetchGuideTypes: selectCategoryActions.fetchGuideTypes,
  saveCategory: editCategoryActions.saveCategory,
  selectGuideType: selectCategoryActions.selectGuideType,
  selectCompetency: selectCategoryActions.selectCompetency,
  selectDuration: selectCategoryActions.selectDuration,
  init: selectCategoryActions.init
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(AddCompetencyPage)));
