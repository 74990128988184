import React from "react"
import { withStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";

const HoverInfoStyledTooltip = withStyles({
  tooltip: {
    backgroundColor: '#000',
    color: '#fff',
    fontSize: 14,
    borderRadius: 3,
    fontWeight: 100,
    marginTop: 10,
    wordBreak: 'break-all',
    display: 'block',
    padding: 10,
    ['@media (max-width:767px)']: {
      width: 230
    },
  }
})(Tooltip);

const ToolTipHover = ({ children, title }) => {
  return (<HoverInfoStyledTooltip title={title} placement="bottom-start" disableFocusListener disableTouchListener> 
    { children }
   </HoverInfoStyledTooltip>)
}

export default ToolTipHover;