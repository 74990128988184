import { ActionType, createAction } from 'typesafe-actions';
import {IQuestion} from "../../models/questionModels";
// import {Action} from 'redux';

const addQuestion = createAction('@@customQuestion/ADD_QUESTION', resolve => {
  return (text: string) => resolve(text);
});

const addQuestions = createAction('@@customQuestion/ADD_QUESTIONS', resolve => {
  return (questions: string[]) => resolve({questions});
});

const removeQuestion = createAction('@@customQuestion/REMOVE_QUESTION', resolve => {
  return (id: number) => resolve(id);
});

const updateQuestion = createAction('@@customQuestion/UPDATE_QUESTION', resolve => {
  return (id: number, text: string) => resolve({id, text});
});

const resetAll = createAction('@@customQuestions/RESET_ALL');

export const actions = {
  resetAll,
  addQuestion,
  updateQuestion,
  removeQuestion,
  addQuestions,
};

export type Actions =
  | ActionType<typeof resetAll>
  | ActionType<typeof addQuestion>
  | ActionType<typeof updateQuestion>
  | ActionType<typeof removeQuestion>
  | ActionType<typeof addQuestions>;
