import { createAction, createAsyncAction } from "typesafe-actions";
import { loadQuestionWithTranslationsRequest, saveQuestionWithTranslationsRequest, createQuestionRequest, deleteQuestionRequest, loadQuestionsByCategory } from "../../services/question";
import * as addEditRatingActions from '../../store/addEditRating/addEditRatingActions';
import { createPromisedAction } from "../../services/actionService";
import { QuestionFullInformation } from './type'
import { IEntityTranslation, IQuestionWithTranslations, IQuestion } from '../../models/questionModels';
import { IRatingWithTranslations } from '../../models/ratingModels';

export const fetchQuestion = createAsyncAction(
  '@@editQuestion/FETCH_REQUEST',
  '@@editQuestion/FETCH_SUCCESS',
  '@@editQuestion/FETCH_FAIL'
)<void, IEntityTranslation[], string>();
export const saveQuestion = createAsyncAction(
  '@@addEditQuestion/SAVE_REQUEST',
  '@@addEditQuestion/SAVE_SUCCESS',
  '@@addEditQuestion/SAVE_FAIL'
)<IEntityTranslation[], IEntityTranslation[], string>();

export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const LOAD_QUESTION = "LOAD_QUESTION";
export const CREATE_QUESTION = "CREATE_QUESTION";
export const LOAD_QUESTION_FULL = "LOAD_QUESTION_FULL";
export const SAVE_QUESTION_FULL = "SAVE_QUESTION_FULL";

export const updateQuestion = (id: number, translations: IEntityTranslation[]) => {
  return createPromisedAction(saveQuestionWithTranslationsRequest(id, translations), UPDATE_QUESTION);
}

export const createQuestionPromise = (groupId: number, translations: IEntityTranslation[], questionType = 'Simple', parentId?:number): Promise<IEntityTranslation[]> => {
  if (!Array.isArray(translations) || translations.length === 0) {
    return new Promise<IEntityTranslation[]>((resolve) => {
      resolve([]);
    });
  }
  const question = {
    ...translations[0],
    categoryId: groupId,
    type: questionType,
    parentId: parentId
  };
  
  const response = createQuestionRequest(question as any).then((data) => {
    return saveQuestionWithTranslationsRequest(data.id, translations)
  });
  return response;
};

export const createQuestion = (groupId: number, translations: IEntityTranslation[], questionType = 'Simple') => {
  return createPromisedAction(createQuestionPromise(groupId, translations, questionType), CREATE_QUESTION);
};
export const loadQuestion = (id: number) => {
  return createPromisedAction(loadQuestionWithTranslationsRequest(id), LOAD_QUESTION);
};
export const deleteQuestion = (id: number) => {
  return createPromisedAction(deleteQuestionRequest(id), DELETE_QUESTION);
}

export const loadProbingQuestionPromise = (categoryId: number, parentId?:number): Promise<IQuestionWithTranslations> => {
  const response = loadQuestionsByCategory('en', categoryId, 'Probing').then((data) => {
    if (data && data.length > 0) {
      const question = parentId 
        ? data.find(q => q.parentId == parentId)
        : data[0];
      if (question) {
        return loadQuestionWithTranslationsRequest(question.id).then((translations) => {
          return {
            ...question,
            translations:translations
          } as IQuestionWithTranslations;
        });
      }
    }    
    return new Promise<IQuestionWithTranslations>((resolve) => {
      resolve(undefined);
    });
  });

  return response;
};

export const loadQuestionFullInformation = (categoryId:number, questionId:number) => {
  return createPromisedAction(loadQuestionFullInformationPromise(categoryId, questionId), LOAD_QUESTION_FULL);
}

const loadQuestionFullInformationPromise = (categoryId:number, questionId:number):Promise<QuestionFullInformation> => {
  if(questionId) {
    return loadQuestionWithTranslationsRequest(questionId).then(translations => {

      const pqPromise =  loadProbingQuestionPromise(categoryId, questionId);
      const ratPromise = addEditRatingActions.loadRatingsPromise(categoryId, questionId);
      
      return Promise.all([pqPromise, ratPromise]).then(values => {
        return buildQuestionFullInformation(translations, values[0], values[1]);
      });

    });
  } else if(categoryId) {
    return new Promise<QuestionFullInformation>((resolve) => {
      resolve(buildQuestionFullInformation([], undefined, undefined));
    });
  }
  else {
    return new Promise<QuestionFullInformation>((resolve) => {
      resolve(undefined);
    });
  }
}

const buildQuestionFullInformation = (translations:IEntityTranslation[], probingQuestion?: IQuestionWithTranslations, ratings?: IRatingWithTranslations[]):QuestionFullInformation => {
  let initValues = translations.reduce((agr, item) => ({...agr, [item.languageCode]: item}), {}) as any;
  if (probingQuestion && probingQuestion.translations) {
    probingQuestion.translations.forEach(question => {
      initValues[question.languageCode].probingQuestions = question.description && question.description.length > 0 ? question.description : question.text
    });
  }

  if (ratings && ratings.length > 0) {
    ratings.forEach(rating => {
      if (rating && rating.translations) {
        rating.translations.forEach(translation => {
          initValues[translation.languageCode]["custom_rating"+rating.value] = translation.text
        });
      }
    });
  }

  return {
    translations, 
    probingQuestion,
    ratings,
    formValues:initValues,
  };
}

export const saveQuestionFullInformation = (categoryId:number, questionId:number,
  currentQuestionData: QuestionFullInformation,
  translations:IEntityTranslation[], probingQuestionItems?: IEntityTranslation[], ratings?: IRatingWithTranslations[]) => {
  return createPromisedAction(saveQuestionFullInformationPromise(categoryId, questionId, currentQuestionData, translations, probingQuestionItems, ratings), SAVE_QUESTION_FULL);
}

const saveQuestionFullInformationPromise = (categoryId:number, questionId:number,
  currentQuestionData: QuestionFullInformation,
  translations:IEntityTranslation[], probingQuestionItems?: IEntityTranslation[], ratings?: IRatingWithTranslations[]):Promise<boolean> => {
  
  if(categoryId && !questionId) {    
    const engTranslation = translations.find(val => val.languageCode === 'EN');
    const question = {
      languageCode: 'EN',
      text:engTranslation ? engTranslation.text : '',
      categoryId: categoryId,
      type: 'Simple',
    } as IQuestion;
    return createQuestionRequest(question).then(newQuestion => {
      return saveQuestionAdditionInfo(categoryId, newQuestion.id ? newQuestion.id : 0, currentQuestionData, translations, probingQuestionItems, ratings);       
    });
  } else {
    return new Promise<boolean>((resolve) => {
      resolve(saveQuestionAdditionInfo(categoryId, questionId, currentQuestionData, translations, probingQuestionItems, ratings));
    });
  }  
}


const savingRatingsPromise = (categoryId:number, questionId:number, 
  currentQuestionData: QuestionFullInformation, ratings: IRatingWithTranslations[]):Promise<boolean> => {  

  const enrichedRatings = addEditRatingActions.enrichRatingFromExisted(categoryId, questionId, ratings, currentQuestionData ? currentQuestionData.ratings : undefined);

  if (currentQuestionData && currentQuestionData.ratings && currentQuestionData.ratings.length > 0) {
    if (ratings && ratings.length > 0) {      
      return addEditRatingActions.updateRatingsPromise(enrichedRatings).then(_ => {
        return true;
      });
    }
    else { //deleting
      return addEditRatingActions.deleteRatingsPromise(currentQuestionData.ratings).then(_ => {
        return true;
      });
    }
  }
  else if (ratings && ratings.length > 0) {
    return addEditRatingActions.createRatingsPromise(enrichedRatings).then(_ => {
      return true;
    });
  }
  else {
    return new Promise<boolean>((resolve) => {
      resolve(true);
    });
  }
}

const savingProbingQuestionPromise = (categoryId:number, questionId:number, 
  currentQuestionData: QuestionFullInformation,
  probingQuestionItems?: IEntityTranslation[], ratings?: IRatingWithTranslations[]):Promise<boolean> => {

  if (currentQuestionData && currentQuestionData.probingQuestion && currentQuestionData.probingQuestion.id) {
    const questionId = currentQuestionData.probingQuestion.id as number;
    if (probingQuestionItems && probingQuestionItems.length > 0) {
      return saveQuestionWithTranslationsRequest(questionId, probingQuestionItems).then(_ => {
        return true;
      });
    }
    else { //deleting
      return deleteQuestionRequest(questionId).then(_ => {
        return true;
      });
    }
  }
  else if (probingQuestionItems && probingQuestionItems.length > 0) {
    return createQuestionPromise(categoryId, probingQuestionItems, 'Probing', questionId).then(_ => {
      return true;
    });
  }
  else {
    return new Promise<boolean>((resolve) => {
      resolve(true);
    });
  }
}

const saveQuestionAdditionInfo = (categoryId:number, questionId:number, 
  currentQuestionData: QuestionFullInformation,
  translations:IEntityTranslation[], probingQuestionItems?: IEntityTranslation[], ratings?: IRatingWithTranslations[]):Promise<boolean> => {

  const result = saveQuestionWithTranslationsRequest(questionId, translations).then(_ => {
    
    const pqPromise = savingProbingQuestionPromise(categoryId, questionId, currentQuestionData, probingQuestionItems);    
    const rtPromise = savingRatingsPromise(categoryId, questionId, currentQuestionData, ratings ? ratings : []);

    return Promise.all([pqPromise, rtPromise]).then(values => {
      return values[0] && values[1] ? true : false;
    });

  });

  return result;
}
