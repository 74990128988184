import React from 'react'
import { FieldArray, reduxForm } from 'redux-form'
import RenderTranslation from './FormArray';
import { JnJGridRow, JnJGridColumn, JnJButton } from '@jaj/ui-components';
import {Typography, TextField} from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';
import validate from './validate';
import {DeleteModalDialog} from '../../../components/ModalDialog';
import * as constants from '../../../components/Constants';
import Container from '../../../components/Container/Container';

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    paddingTop: '30px',
    paddingBottom: '30px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '150px',
      paddingBottom: '150px'
    }
  },
  styledButton: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      marginLeft: 'auto'
    }
  },
  addNew:{
    paddingLeft: 0,
    '&:hover':{
      backgroundColor: 'transparent'
    }
  }
});


const EditTranslationsForm = props => {
  const {
    handleSubmit,
    languages,
    initialValues,
    history,
    classes,
    category,
    categoryId,
    open,
    openModal,
    closeModal,
    submitting
  } = props;
  const currentCategory = category.categoryStore[categoryId];
  const isCompetency = currentCategory && currentCategory.type === "FunctionalCompetency";
  
  return (
    <form onSubmit={handleSubmit}>
      <Container static className={classes.root}>
        <JnJGridRow className='mbottom-50'>
          <JnJGridColumn xs={12} md={1} />
          <JnJGridColumn xs={12} md={11}>
            <Typography
              variant='h2'
              color='secondary'
              className='mbottom-20'
            >
              {`Edit ${isCompetency ? "Functional Competency" : (currentCategory && currentCategory.text)}`}
            </Typography>
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={1} />
          <JnJGridColumn xs={12} md={3}>
            <TextField
              value={category.categoryStore[category.guide.guideTypeCategoryId] && category.categoryStore[category.guide.guideTypeCategoryId].text}
              label='For'
              disabled
              fullWidth
            />
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={4}>
            <TextField
              value={currentCategory && currentCategory.ownsRatings ?
                constants.FUNCTIONAL_MODEL_FORMAT_TRUE :
                constants.FUNCTIONAL_MODEL_FORMAT_FALSE}
              label='Functional Competency Model Format'
              disabled
              fullWidth
            />
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={3}>
            <TextField
              value={currentCategory && currentCategory.ownsProbingQuestions ?
                constants.PROBING_QUESTION_FORMAT_TRUE :
                constants.PROBING_QUESTION_FORMAT_FALSE}
              label='Probing Question Format'
              disabled
              fullWidth
            />
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={1} />
        </JnJGridRow>
        <FieldArray
          name="translation"
          component={RenderTranslation}
          props = {{
            languages,
            initialValues
          }}
        />
        <JnJGridRow>
          <JnJGridColumn xs={12} md={1} />
          <JnJGridColumn xs={12} md={2}>
            <JnJButton
              variant='outlined'
              color={'primary'}
              onClick={()=> history.push('/interview/select-guide-type')}
            >
              Cancel
            </JnJButton>
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={1}  className={classes.styledButton}/>
          {isCompetency && 
          <JnJGridColumn xs={12} md={2}>
            <JnJButton
              variant='outlined'
              color={'primary'}
              onClick = {openModal}
            >
              Delete Function
            </JnJButton>
          </JnJGridColumn>
          }
          <JnJGridColumn xs={12} md={2} >
            <JnJButton
              variant='contained'
              color={'primary'}
              type='submit'
              disabled={submitting}
            >
              Save Changes
            </JnJButton>
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={1} />
        </JnJGridRow>
        <DeleteModalDialog
          open={open}
          modalClose={closeModal}
          deletedElement={currentCategory.text}
          question='Are you sure you want to delete this competency?'
        />
      </Container>
    </form>
  )
}

export default reduxForm({
  form: 'editGroup',
  validate
})(withStyles(styles)(EditTranslationsForm))
