import React, { Component } from 'react';
import { connect } from "react-redux";
import { actions } from "../../store/selectCategory";
import Input from "../Input/Input";
import { JnJButton } from '@jaj/ui-components/build';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { onHistoryLinkClicked } from '../../services/historyLinksService';
import {selectGuide, selectReportDocRequest} from '../../store/selectCategory/selectors';
import {
  JnJGridRow,
  JnJGridColumn
} from '@jaj/ui-components';
import {guideStatus, guideViewStatus} from "../../models/guideModel";
import { withRouter } from 'react-router-dom';

const styles = theme => createStyles({
  root: {
    cursor: 'pointer',
    width: 30,
    height: 30,
    boxSizing: 'border-box',
    overflow: 'hidden'
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  button: {
    /*padding: '11px 25px',
    marginLeft: '15px',*/
    padding: '11px 21px',
    marginLeft: '15px',
    maxHeight: '44px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0
    }
  },
  input: {
    marginRight: '40px',
    width: '100%'
  },
  draftAndPreview: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
    }
  },
  asteriskColor: {
    color: '#CA001B'
  },
  inputLabel: {
    fontSize: '14px',
    lineHeight: '24px',
  },
});

class GuideForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      name: this.props.guide.name,
      isFormValid: false,
      isValidAsDraft: false,
    };
  }

  componentDidMount() {
    this.validateForDraft(this.state.name);
  }

  validateForDraft = (value) => {
    if (value.trim() !== '' && value.length <= 150) {
      this.props.changeGuideName(value);
      return this.setState({
        name: value,
        isValidAsDraft: true
      });
    }
    this.setState({
      isValidAsDraft: false,
      name: '',
    });
  };

  validateForm(question, hasCustomQuestion, name) {
    return name && (question.length || hasCustomQuestion);
  }

  save = () => {
    this.props.saveDraft(this.state.name);
  };

  render() {
    const {
      classes,
      questions,
      customQuestions
    } = this.props;

    const hasCustomQuestion = customQuestions.some(question => question.text);
    const controlsDisabled = !this.validateForm(questions, hasCustomQuestion, this.state.name);
    return (<>
      <div className={classes.form}>
        <JnJGridRow>
          <JnJGridColumn xs={12} md={12} lg={7}>
            <div className={classes.input}>
              <Input required={true}
                     fullWidth={true}
                     inputLabelClass={classes.inputLabel}
                     label={'Name of Guide'}
                     value={this.state.name}
                     onChange={this.validateForDraft}
                     asteriskClass={classes.asteriskColor}
                     defaultValue={this.props.guide.name}/>
            </div>
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={12} lg={5} className={classes.draftAndPreview}>
            <JnJButton type={'submit'} disabled={!this.state.isValidAsDraft && controlsDisabled} className={classes.button}
              variant={'outlined'}
              color={'primary'} onClick={this.save}>Save as draft</JnJButton>
            {/*TODO: in future change value for disabled with validation*/}
            <JnJButton className={classes.button}
              disabled={controlsDisabled}
              variant={'contained'}
              color={'primary'}
              onClick={() => onHistoryLinkClicked({
                category: 'questions',
                action: 'preview',
                label: 'preview',
                onClick: () => {
                  this.props.createGuide(this.props.guide, this.state.name, guideStatus.preview)
                }
              })}>

              {'Preview & Download'}
            </JnJButton>
          </JnJGridColumn>
        </JnJGridRow>
      </div>
    </>
    )
  }
}

const mapStateToProps = (state) => ({
  questions: state.questions,
  guide: selectGuide(state),
  customQuestions: state.customQuestion,
  viewStatus: state.category.guideViewStatus,
});

const mapDispatchToProps = {
  saveDraft: actions.saveDraft,
  createGuide: actions.openPreviewModal,
  changeGuideName: actions.changeGuideName
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(GuideForm)));




