import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { actions } from '../../store/selectCategory/action';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  JnJButton,
  ModalCloseIcon
} from '@jaj/ui-components';
import { selectGuide, selectReportDocRequest } from '../../store/selectCategory/selectors';
import { getLanguageCode } from '../../store/language/selector';
import Loading from "../../components/Loading/Loading";
import LanguageSelector from '../../components/LanguageSelector';
import { withRouter } from 'react-router-dom';
import { renderModal } from './ModalPreview/ModalPreviewRender';
import {guideStatus} from "../../models/guideModel";

const styles = theme => ({
  styledHeader: {
//    padding: '0px',
     padding: '35px 0 0 0',
    '& h2': {
      color: theme.palette.secondary.main,
      marginBottom: 10,
      lineHeight: '28px',
      fontWeight: '900',
      fontSize: '24px',
    },
    [theme.breakpoints.down('md')]: {
      flex: 'auto',
    }
  },
  content: {
    backgroundColor: '#fff',
  },
  closeIcon: {
    //marginLeft: 'auto',
    //right: 50,
    right: 15,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  loading: {
    paddingLeft: 5,
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: 60
  },
  mainContent: {
    margin: 24,
    padding: 36,
  },
  dialogContent: {
    //maxHeight: 475,
    maxHeight: 430,
    backgroundColor: '#fff',
  },
  dialogActions: {
    display: 'flex',
    margin: '28px 0 0 0',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      minHeight: '100px',
      justifyContent: 'space-between',
    }
  },
  publishButton: {
    minWidth: 178,
    lineHeight: '15px',
    alignSelf: 'stretch',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  buttonBarRight: {
    display: 'flex',
    margin: 0,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '55px',
      width: '100%'
    }
  },
  languageButton: {
    minWidth: 215,
    marginRight: '15px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  continueEditingButton: {
    display: 'flex',
    alignItems: 'stretch',
    margin: '0',
    lineHeight: '15px',
    background: 'white',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    }
  },
  confirmationContent: {
    //minHeight: '188px',
    minHeight: '75px',
    fontSize: '20px',
    color: '#212121',
    fontWeight: '300',
    lineHeight: '30px',
    padding: '0',
  },
  confirmationButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '28px 0 0 0',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      justifyContent: 'space-around',
      minHeight: '160px',
    }
  },
  publishedGuideButtons: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '28px 0 0 0',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  modalContainer: {
    //padding:46,
    padding:'20px 46px',
    [theme.breakpoints.down('md')]: {
      //padding: 20
      padding: '30px 20px'
    },
  },
  modalContainerOnDownload: {
    padding: '20px 60px',
    [theme.breakpoints.down('md')]: {
      padding: 20
    },
  }
});

class ModalPreviewPage extends Component {
  constructor(props) {
    super(props);
    this.renderModal = renderModal.bind(this);
    this.state = {
      publishing: false,
      modalVIewStatus: this.props.modalVIewStatus || guideStatus.preview,
      publishAndDownload: false,
      value: props.selectedFormat,
      guideName: ''
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && this.state.value !== nextProps.selectedFormat) {
      this.setState({
        value: nextProps.selectedFormat
      })
    }
  };

  handleChange = event => {
    this.setState({ value: event.target.value });
  };

  onPublish = () => {
    this.props.publishGuide();
  };

  onClose = () => {
    this.setState({
      modalVIewStatus: guideStatus.preview,
    });
  };

  checkModalStatus = () => {
    return !this.props.initializing;
  };

  render() {
    const { initializing, open, scroll, closePreviewModal, classes } = this.props;
    const modalView = this.renderModal(this.props.modalVIewStatus || guideStatus.preview);

    return (
      <div className={classes.mainContent}>
        <Dialog
          open={open}
          scroll={scroll}
          // onClose={closePreviewModal}
          // on={this.checkModalStatus()}
          aria-labelledby="modal-preview"
          className={classes.mdSizePadding}
          PaperProps={{
            style: { backgroundColor: '#f4f4f4' },
          }}
          maxWidth="md"
          fullWidth
        >
          <div
            className={this.props.modalVIewStatus === guideStatus.preview ? classes.modalContainerOnDownload : classes.modalContainer}>

            {initializing ? <Loading loadingMsg="Please wait! Loading... " />:
              <>
                <div>
                  <JnJButton onClick={closePreviewModal} className={classes.closeIcon}>
                    <ModalCloseIcon />
                  </JnJButton>
                </div>

                <div className={classes.dialogHeader}>
                  <DialogTitle id="modal-preview" className={classes.styledHeader}>
                    {modalView.title}
                  </DialogTitle>
                </div>

                <DialogContent className={modalView.classes.contentParent}>
                  <div className={modalView.classes.contentChild}
                    dangerouslySetInnerHTML={{ __html: modalView.content }} />
                </DialogContent>

                <DialogActions
                  className={modalView.buttons.class}>

                  {modalView.buttons.left &&
                    <JnJButton
                      color={modalView.buttons.left.color}
                      variant={modalView.buttons.left.variant}
                      className={modalView.buttons.left.className}
                      onClick={modalView.buttons.left.onClick}>
                      {modalView.buttons.left.label}
                    </JnJButton>
                  }

                  {modalView.buttons.right &&
                    <div className={classes.buttonBarRight}>
                      <>
                        {modalView.buttons.right.langSelector &&
                          <LanguageSelector
                            modalVIewStatus={this.props.modalVIewStatus}
                            specialClassName={modalView.buttons.right.langSelector.specialClassName}
                            inputlabel={modalView.buttons.right.langSelector.inputLabel}
                            languageSelect={this.props.setLanguage}
                          />
                        }

                        {
                          modalView.buttons.right.submit &&
                          <JnJButton
                            className={modalView.buttons.right.submit.className}
                            disabled={modalView.buttons.right.submit.isDisabled}
                            variant={modalView.buttons.right.submit.variant}
                            color={modalView.buttons.right.submit.color}
                            onClick={modalView.buttons.right.submit.onClick}
                          >
                            {modalView.buttons.right.submit.label}
                          </JnJButton>
                        }
                      </>
                    </div>
                  }
                </DialogActions>
              </>}
          </div>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  content: state.category.previewModal.content,
  open: state.category.previewModal.open,
  initializing: state.category.previewModal.initializing,
  reportDocRequest: selectReportDocRequest(state),
  languageCode: getLanguageCode(state),
  guide: selectGuide(state),
  modalVIewStatus: state.category.previewModal.viewStatus
});

const mapDispatchToProps = {
  closePreviewModal: actions.closePreviewModal,
  openPreviewModal: actions.openPreviewModal,
  downloadGuideAsync: actions.downloadGuideAsync.request,
  fetchGuideAsync: actions.fetchGuideAsync.request,
  publishGuide: actions.publishGuide,
  setLanguage: actions.setLanguage,
  changeGuideModalStatus: actions.changeGuideModalStatus
};

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ModalPreviewPage)));
