import { Dispatch } from "redux";

export function getActionStartType(baseType: string) {
  return `@@${baseType}_START`;
}

export function getActionEndType(baseType: string) {
  return `@@${baseType}_END`;
}

export function getActionFailureType(baseType: string) {
  return `@@${baseType}_FAILURE`;
}

export function createPromisedAction<T>(promise: Promise<T>, baseType: string) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: getActionStartType(baseType),
    });

    return promise.then(response => dispatch({
        type: getActionEndType(baseType),
        payload: response,
    })).catch(error => dispatch({
        type: getActionFailureType(baseType),
        payload: error.toString(),
    }));
  };
};
