import * as React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { JnJGridRow, JnJGridColumn, JnJLink } from '@jaj/ui-components';
import { HeadWithImage } from '../../components/HeadWIthImage';
import { SectionWithText } from '../../components/SectionWithText';
import { SectionWithImage } from '../../components/SectionWithImage';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '../../components/Container/Container';
import ModalFeatures from '../../components/ModalFeatures/ModalFeatures';


const styles = theme => ({
  root: {
    backgroundColor: '#fff'
  },
  loadingContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

class HomePage extends React.PureComponent {
  getUserName(user) {
    return user && user.userName
      ? user.userName.split(' ')[0]
      : '';
  }

  render() {
    const { classes, user } = this.props;

    if (user.loading) {
      return (
        <div className={classes.loadingContent}>
          <CircularProgress />
        </div>
      )
    }

    return (
      <div>
        <HeadWithImage
          className="homePageHeading"
          name={`Hi ${this.getUserName(user.data)}`}
          title="Are you ready to interview your next candidate?"
          page={this.props.page}
        />
        <Container static className={classes.root}>
          <JnJGridRow>
            <JnJGridColumn xs={12}>
              <SectionWithText
                title="People are our greatest asset"
                text={[
                  "Cutting-edge science, digital technology and new ways of thinking are improving how people are cared for around the globe, and we are at the forefront. Each employee can bring a unique set of talent, experiences, cultural background and thinking style that contributes to the richness of our companies, and better serves our diverse customers around the globe.",
                  "Our interview guide will help you have rich and rewarding conversations with candidates so you can help us identify and hire the talented people who share our vision for caring.",
                ]}
              >
                <Link to='/interview/select-guide-type'>
                  <JnJLink>Customize your Interview Guide</JnJLink>
                </Link>
              </SectionWithText>
            </JnJGridColumn>
          </JnJGridRow>
          {user.data.isUserGuideAttendance ? null : <ModalFeatures />}
        </Container>
        <SectionWithImage
          title="How to get the most out of your interview"
          text="From preparation to how you share your thoughts and feedback, here are some tips to help make the interview process a success."
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  page: state.category.page,
});


export default withStyles(styles)(connect(mapStateToProps)(HomePage));
