import React, { PureComponent } from 'react'
import PropTypes from 'prop-types';
import { withStyles, createStyles } from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import { createSelector } from 'reselect';
import { connect } from 'react-redux';
import { List } from '@material-ui/core';
import QuestionItem from './QuestionItem';
import { questionSelector } from '../../../store/questions/selectors';
import { selectIsWriter } from '../../../store/user/selector';
import { QUESTION_ACTIONS } from '../../../components/Constants';
import { actions } from '../../../store/selectCategory/action';
import * as editQuestionActions from '../../../store/addEditQuestion/addEditQuestionActions';
import {actions as questionActions} from '../../../store/questions';
import {DeleteModalDialog} from '../../../components/ModalDialog';

import {
  SecondaryLink,
  PlusIcon,
} from '@jaj/ui-components';

const styles = () => createStyles({
  link: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center'
  },
  linkText: {
    fontSize: 18,
    marginLeft: 10,
    whiteSpace: 'nowrap'
  },
  plusIcon: {
    width: 15,
    height:15
  }
});

export class QuestionsList extends PureComponent {
  static propTypes = {
    groupId: PropTypes.number.isRequired,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
      }).isRequired,
    ).isRequired,
    toggle: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    removeQuestionFromStore: PropTypes.func.isRequired
  }
  state = {
    openDeleteDialog: false,
    deletingQuestionId:0,
    deletingQuestionText:'',
    isDeletingQuestionSelected:false
  };

  handleCloseDeleteDialog = (isPositiveAnswer) => {
    if (isPositiveAnswer) {      
      this.performQuestionDeletion(this.state.deletingQuestionId, this.state.isDeletingQuestionSelected)
    }
    this.setState({ 
      openDeleteDialog: false,
      deletingQuestionId:0,
      deletingQuestionText:'',
      isDeletingQuestionSelected:false
     });
  };

  navigateToAdd = () => {
    this.props.history.push(`/interview/question/${QUESTION_ACTIONS.add}/${this.props.groupId}`);
  };

  navigateToEdit = (id) => {
    this.props.history.push(`/interview/question/${QUESTION_ACTIONS.edit}/${id}`);
  };

  deleteQuestion = (question, selected) => {
    this.setState({ 
      openDeleteDialog: true,
      deletingQuestionId: question.id,
      deletingQuestionText: question.text,
      isDeletingQuestionSelected:selected
    });
  }

  performQuestionDeletion = (id, selected) => {
    this.props.delete(id);
    if(selected) {
      this.props.toggle(id);
    }
    this.props.removeQuestionFromStore(id);
  }

  render() {
    const {
      classes,
      questions,
    } = this.props;

    return (
      <div>
        <List>
          {questions.map((question) => (
            <QuestionItem
              key={'QuestionItem' + question.id}
              data={question}
              onEdit={this.navigateToEdit}
              onDelete={this.deleteQuestion}
            />
          ))}
        </List>
        { this.props.isWriter &&
          <div>
            <button onClick={this.navigateToAdd} className={classes.link}>
              <PlusIcon className={classes.plusIcon}/>
              <SecondaryLink className={classes.linkText}>
                Add question
              </SecondaryLink>
            </button>
          </div>
        }
        <DeleteModalDialog
          open={this.state.openDeleteDialog}
          modalClose={this.handleCloseDeleteDialog}
          deletedElement={this.state.deletingQuestionText}
          question='Are you sure you want to delete this question?'
        />
      </div>
    )
  }
}

const selectGroupId = (_, props) => props.groupId;

const mapStateToProps = (state, props) => ({  
  ...createSelector(
    questionSelector,
    selectGroupId,
    (questions, groupId) => ({
      questions: questions.filter(({categoryId}) => categoryId === groupId)
    })
  )(state, props),
  isWriter: selectIsWriter(state),
});

const mapDispatchToProps = {
  removeQuestionFromStore: actions.removeQuestionFromStore,
  delete: editQuestionActions.deleteQuestion,
  toggle: questionActions.toggle,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(QuestionsList)));
