import * as React from 'react'
import cs from 'classnames';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';

export interface Props {
    static?: boolean,
    className?: string,
    children: React.ReactNode
};

const styles = (theme:Theme) => createStyles({
    root: {
      boxSizing: 'border-box',
      margin: 0,
      width: '100vw',
      paddingLeft: 25,
      paddingRight: 25,
      [theme.breakpoints.up('sm')]: {
        margin: '0 auto',
        paddingLeft: 16,
        paddingRight: 16,
      },
      [theme.breakpoints.up('md')]: {
        paddingLeft: 28,
        paddingRight: 28,
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: 1280,
        paddingLeft: 50,
        paddingRight: 50,
      },
    },
});

const Container = (props: Props & WithStyles) => {
    const {classes} = props;
    return (
        <div className={cs({
            [classes.root]: props.static
        }, props.className)}>
            {props.children}
        </div>
    )
}

export default withStyles(styles)(Container);
