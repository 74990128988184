import {Reducer} from 'redux';
import * as actions from './action';
import { Language, LanguageCode } from './type';
import { getType, ActionType } from 'typesafe-actions';

type State =  {
  readonly code: LanguageCode;
  readonly modalCode: LanguageCode;
  readonly loading: boolean,
  readonly languages: Language[]
}

type Actions = ActionType<typeof actions>;

const initialState: State = {
  code: LanguageCode.en,
  modalCode: LanguageCode.en,
  loading: true,
  languages: [],
};

export const reducer: Reducer<State, Actions> = (state = initialState, action) => {
  switch(action.type) {
    case getType(actions.fetch.request):
      return {
        ...state,
        loading: true
      };
    case getType(actions.fetch.success):
      return {
        ...state,
        languages: action.payload,
        loading: false
      };
    case getType(actions.fetch.failure):
      return {
        ...state,
        loading: false
      };
    case getType(actions.syncGuideLanguage):
    case getType(actions.changeModalLanguage):
      return {
        ...state,
        modalCode: action.payload,
        code: action.payload
      };
    case getType(actions.select):
      return {
        ...state,
        code: action.payload,
        modalCode: action.payload
      };
    default:
      return state;
  }
};
