const validate = values => {
  const errors = {}
  const translationArrayErrors = []
  values.translation.forEach((line, index) => {
    const translationErrors = {}
    if (!line || !line.text) {
      translationErrors.text = 'Translation is required'
      translationArrayErrors[index] = translationErrors
    }
    if (!line || !line.languageCode) {
      translationErrors.languageCode = 'Select a language'
      translationArrayErrors[index] = translationErrors
    }
  });
  if(translationArrayErrors.length) {
    errors.translation = translationArrayErrors
  }
  return errors
}

export default validate
