import * as React from "react";
import { FormControl, InputLabel, Select as MaterialSelect, MenuItem, Input } from "@material-ui/core";

interface ISelectOption {
  title: string;
  value: string;
}

interface ISelectProps {
  label: string;
  value: string;
  options: ISelectOption[];
  name?: string;
  disabled?:boolean,
  onChange: (value: string) => void;
}

export default class Select extends React.PureComponent<ISelectProps, {}> {
  onChange = (event: React.ChangeEvent<HTMLSelectElement>, child: React.ReactNode) => {
    this.props.onChange(event.target.value);
  }

  render() {
    return (
      <FormControl>
        {this.props.label && <InputLabel>{this.props.label}</InputLabel>}
        <MaterialSelect
          onChange={this.onChange}
          value={this.props.value}
          disabled={this.props.disabled}
          input={<Input name={this.props.name} />}
        >
          {this.props.options && this.props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>{option.title}</MenuItem>
          ))}
        </MaterialSelect>
      </FormControl>
    );
  }
}
