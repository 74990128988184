import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { JnJGridRow, JnJGridColumn, JnJButton } from '@jaj/ui-components';
import Background from './assets/headingImage.png';
import Container from '../Container/Container';
import {Link} from "react-router-dom";

const styles = theme => ({
  root: {
    padding: '0',
    backgroundColor: '#ffffff',
  },
  image: {
    padding: '40px 0 20px 0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '250px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: `url(${Background})`,
    [theme.breakpoints.up('md')]: {
      height: '450px',
      marginBottom: 0,
      padding: '110px 0 20px 0'
    }
  },
  heading: {
    color: '#fff',
    fontSize: '30px',
    lineHeight: '32px',
    fontWeight: 500,
    textAlign: 'left',
    spacing: '-0.75px',
    [theme.breakpoints.up('md')]: {
      fontSize: '48px',
      lineHeight: '53px',
      letterSpacing: '-1.2px'
    }
  },
  button: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      padding: '17px 26px',
      marginTop: '33px',
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '20px',
      textTransform: 'none',
    }
  },
  mobileButton: {
    display: 'block',
    padding: '17px 26px',
    marginTop: 40,
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '20px',
    textTransform: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  }
});

const HeadWithImage = (props) => {
  const {classes, name, title, page} = props;
  return(
    <Container static className={classes.root}>
      <div className={classes.image}>
        <JnJGridRow style={{justifyContent: 'center'}}>
          <JnJGridColumn xs={9} md={6}>
            <p className={classes.heading}>
              {name},
            </p>
            <p className={classes.heading}>
              {title}
            </p>
            <Link to='/interview/select-guide-type'>
              <JnJButton
                className={classes.button}
                variant={'contained'}
                color={'primary'}
              >
                Create your custom Interview Guide
              </JnJButton>
            </Link>
          </JnJGridColumn>
        </JnJGridRow>
      </div>
      <div className="flex justify-center">
        <Link to='/interview/select-guide-type'>
          <JnJButton
            className={classes.mobileButton}
            variant={'contained'}
            color={'primary'}
          >
            Create your custom Interview Guide
          </JnJButton>
        </Link>
      </div>
    </Container>
  )
};

export default withStyles(styles)(HeadWithImage);
