import React from "react"
import { connect } from "react-redux";
import { withStyles, Typography } from "@material-ui/core";
import { JnJGridRow, JnJGridColumn } from "@jaj/ui-components";
import Loading from "../../components/Loading/Loading";
import Container from '../../components/Container/Container';
import { SectionWithImage } from '../../components/SectionWithImage';
import GuideCard from './GuideCards';
import { Tabs, Tab } from "@material-ui/core";
import { actions } from "../../store/selectCategory";
import NotificationMessage from "../../components/NotificationMessage/NotificationMessage";
import ModalPreviewPage from "../InterviewGuidePage/ModalPreviewPage";
import { guideViewStatus } from "../../models/guideModel";

const styles = theme => ({
  tab: {
    minWidth: 0,
    padding: 0,
    fontSize: '15px',
  },
  tabLabel: {
    minWidth: 0,
    margin: 0,
    padding: '0 10px',
  },
  tabIndicator: {
    maxHeight: '3px',
    backgroundColor: '#CA100B',
  },
  tabsWrapperContent: {
    flex: 1,
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  titlePrimary: {
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "34px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "40px",
    },
  },
  flexContainer: {
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-evenly',
    }
  },
  guideTabs: {
    height: '18px',
    color: '#212121',
    fontSize: '14px',
    fontWeight: '300',
    marginRight: '38px',
    lineHeight: '18px',
    textTransform: 'none',
    letterSpacing: '0.47px',
    minWidth: '0px',
    '& span span': {
      padding: '0px',
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'space-evenly',
    }
  },
  myInterviewGuides: {
    height: '70px',
    color: '#000099',
    fontWeight: '900',
    lineHeight: '48px',
    letterSpacing: '-0.5px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      fontSize: '30px',
      justifyContent: 'center',
    },
  },
  overflo: {
    overflowX: 'auto',
  },
  root: {
    paddingTop: '20px',
    paddingBottom: '60px',
    backgroundColor: '#fff',
    [theme.breakpoints.up('md')]: {
      paddingTop: '50px',
      paddingBottom: '100px'
    },
  },
  notification: {
    margin: 'auto',
    maxWidth: '1280px'
  },
  removeTabHover: {
    top: 'unset',
    left: 'unset',
    width: 'unset',
    display: 'unset',
  },
  reminder: {
    fontSize: '16px',
    fontWeight: '300',
    lineHeight: '26px',
    fontFamily: '"J&J Circular","sans-serif"',
    color: '#212121',
    minWidth: '135%',
    [theme.breakpoints.down('md')]: {
      minWidth: 'auto',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column'
    },
  },
  bold: {
    fontWeight: '900'
  },
  titleClass: {
    display: 'flex',
    flexDirection: 'column',
  }
});

class ShowInterviewGuides extends React.Component {

  constructor() {
    super(...arguments);
    this.fetchGuides();
    const publishedOrDraftChecker = this.props.location.state && this.props.location.state.value;
    this.state = {
      value: publishedOrDraftChecker || 0,
      showMessage: this.props.isGuideDownloaded
    };
  };

  componentDidMount() {
    if(!this.state.value) { // exclude for draft
      var browsingHistory = [];
      window.history.pushState(browsingHistory, "", "/");
      window.history.pushState(browsingHistory, "", "/interview");
    }  
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.isGuideDownloaded !== this.state.showMessage) {
      this.setState({ showMessage: this.props.isGuideDownloaded })
    }
  };

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.guide.id === this.props.deletedGuide && this.props.deletedGuide !== 0) {
      this.fetchGuides();
    };
    if (this.props.guide.id && this.props.guideViewStatus === guideViewStatus.copy) {
      this.props.history.push('interview/questions-list-page');
    };
    return null;
  };

  componentWillUnmount() {
    this.props.changeDownloadStatus(false);
  };

  fetchGuides = () => {
    this.props.fetchGuides();
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  guideCardRender = guides => {
    return (
      <JnJGridRow>
        <GuideCard item={true} onClick={() => this.props.history.push('/interview/select-guide-type')} />
        {guides && guides.map((item, index) => (
          <GuideCard key={index} item={item} />
        ))}
      </JnJGridRow>)
  };

  checkGuideListType = (data, tabValue) => {
    if (this.props.initialize) {
      return <Loading />;
    }
    if (tabValue === 0) {
      return this.guideCardRender(data.published);
    }
    if (tabValue === 1) {
      return this.guideCardRender(data.drafts);
    }
  };

  handleMessage() {
    this.props.changeDownloadStatus(false);
    this.setState({ showMessage: false });
  };

  render() {
    const { value } = this.state;
    const { initialize, classes, drafts, published } = this.props;
    return (initialize ? (
      <Loading />
    ) : (
        <div>
          {value === 0 && this.state.showMessage ?
            <Container className={classes.notification}>
              <NotificationMessage
                notificationText={`Success! We've downloaded the guide in Word Doc format to the "Downloads" folder on your computer.`}
                onClick={this.handleMessage.bind(this)}
              />
            </Container> :
            null}
          <Container static className={classes.root}>
            <JnJGridRow>
              <JnJGridColumn xs={false} lg={1} />
              <JnJGridColumn xs={12} lg={10}>
                <JnJGridRow>
                  <JnJGridColumn xs={12} lg={9}>
                    <div className={classes.sectionHeading}>
                      <Typography variant="h2" color="secondary" className={classes.myInterviewGuides}>
                        {'My Interview Guides'}
                      </Typography>
                    </div>
                    <h6 className={classes.reminder}>
                      <span className={classes.bold}>Reminder: </span>
                      You cannot edit Published Guides. If you want to update a guide, click the three dots in the box to make a copy that you can revise.
                      </h6>
                    <div className={classes.tabsWrapperContent}>
                      <Tabs
                        value={value}
                        scrollButtons="off"
                        variant="scrollable"
                        onChange={this.handleChange}
                        classes={{ indicator: classes.tabIndicator, scrollable: classes.overflo, flexContainer: classes.flexContainer }}
                      >
                        <Tab label='Published Guides'
                          className={classes.guideTabs}
                          TouchRippleProps={{ classes: { child: classes.removeTabHover } }}
                        />
                        <Tab label="Draft Guides"
                          className={classes.guideTabs}
                          TouchRippleProps={{ classes: { child: classes.removeTabHover } }}
                        />
                      </Tabs>
                    </div>
                  </JnJGridColumn>
                </JnJGridRow>

                {this.checkGuideListType({ published, drafts }, value)}

              </JnJGridColumn>
            </JnJGridRow>
            {this.props.modalVIewStatus &&
              <ModalPreviewPage
                scroll="paper"
                selectedFormat='pdf'
              />}
          </Container>

          <SectionWithImage
            title="How to get the most out of your interview"
            text="From preparation to how you share your thoughts and feedback, here are some tips to help make the interview process a success."
          />
        </div>)
    )
  }
}


const mapStateToProps = ({ category }) => ({
  drafts: category.drafts,
  published: category.published,
  initialize: category.initializing,
  isGuideDownloaded: category.isGuideDownloaded,
  modalVIewStatus: category.previewModal.viewStatus,
  guide: category.guide,
  deletedGuide: category.deletedGuide,
  guideViewStatus: category.guideViewStatus
});

const mapDispatchToProps = {
  fetchGuides: actions.fetchGuides,
  fetchLanguages: actions.fetchLanguages,
  changeDownloadStatus: actions.changeDownloadStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ShowInterviewGuides))
