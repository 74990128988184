import * as React from 'react';
import { Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { JnJGridRow, JnJGridColumn, TwoPersonsIcon } from '@jaj/ui-components';
import Container from '../Container/Container';

const styles = theme => ({
  root: {
    paddingTop: '30px',
    paddingBottom: '30px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '100px',
      paddingBottom: '100px'
    }
  },
  heading: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      fontSize: '36px'
    }
  },
  text: {
    marginBottom: '10px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '20px'
    }
  },
  row: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center'
    }
  },
  sectionHeading: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'flex-start',
      marginBottom: '30px'
    }
  },
  iconWrapper: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right'
    }
  },
  bodyContent: {
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      marginBottom: '30px'
    }
  }
});

const SectionWithText = (props) => {
  const {classes, title, text, children} = props;
  const bodyContent = text.map((string, i) => (
      <Typography variant="body2" className={classes.text} key={i}>
        {string}
      </Typography>
    )
  );
  return(
    <Container className={classes.root}>
      <JnJGridRow className={classes.row}>
        <JnJGridColumn xs={12} md={1} />
        <JnJGridColumn xs={12} md={1}>
          <div className={classes.iconWrapper}>
            <TwoPersonsIcon />
          </div>
        </JnJGridColumn>
        <JnJGridColumn xs={12} md={9}>
          <div className={classes.sectionHeading}>
            <Typography
              variant="h3"
              color="secondary"
              className={classes.heading}
            >
              {title}
            </Typography>
          </div>
          <div className={classes.body}>
            <div className={classes.bodyContent}>
              {bodyContent}
            </div>
            {children}
          </div>
        </JnJGridColumn>
        <JnJGridColumn xs={12} md={1} />
      </JnJGridRow>
    </Container>
  )
}

export default withStyles(styles)(SectionWithText);
