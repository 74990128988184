import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import QuestionsList from './QuestionsList';
import {
  CollapsedItem,
  LightbulbIcon,
  LightbulbInfo,
  JnJGridColumn,
  JnJGridRow,
  JnJButton,
  EditIcon,
  DumbIcon
} from '@jaj/ui-components';
import { Typography, withStyles } from '@material-ui/core';
import { relative } from 'path';
import { selectTip } from '../../services/tipService';
import { selectIsWriter } from '../../store/user/selector';
import { COMPETENCY_ACTIONS } from '../../components/Constants';
import { DeleteModalDialog } from '../../components/ModalDialog';
import { actions as editCategoryActions } from '../../store/editCategory';
import { actions as selectCategoryActions } from "../../store/selectCategory";
import {
  SecondaryLink,
  PlusIcon,
} from '@jaj/ui-components';

const styles = (theme) => ({
  hint: {
    display: 'flex',
    alignItems: 'center',
  },
  hintIcon: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
  categoryHint: {
    width: 350,
    position: relative,
    marginRight: -60,
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  mobileHint: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
    margin: '-60px 45px 0'
  },
  header: {
    marginBottom: 25,
  },
  link: {
    border: 'none',
    outline: 'none',
    backgroundColor: 'transparent',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10
  },
  linkText: {
    fontSize: 18,
    marginLeft: 10,
    whiteSpace: 'nowrap'
  },
  plusIcon: {
    width: 15,
    height:15
  },
  competencyAdminPanel: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  competencyToolbar: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
});

export class QuestionCategory extends Component {
  static propTypes = {
    categoryId: PropTypes.number.isRequired,
    groups: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }),).isRequired,
  }
  state = {
    openDeleteDialog: false,
    deletingEntityId:0,
    deletingEntityText:'',
    deletingEntityType:'',
    processing:false
  }
  constructor(props) {
    super(props);    
    this.handleCloseDeleteDialog = this.handleCloseDeleteDialog.bind(this);
  }
  onAddCompetency = () => {
    this.props.history.push(`/interview/competency/${COMPETENCY_ACTIONS.add}/${this.props.categoryId}`);
  };
  onEditCompetency = (groupId) => {
    this.props.history.push(`/interview/competency/${COMPETENCY_ACTIONS.edit}/${groupId}`);
  };
  onDeleteCompetency = (group) => {    
    this.setState({ 
      openDeleteDialog: true,
      deletingEntityId: group.id,
      deletingEntityText: group.text,
      deletingEntityType: 'Competency'
    });
  }
  onDeleteFunctionalCompetency = () => {    
    this.setState({ 
      openDeleteDialog: true,
      deletingEntityId: this.props.categoryId,
      deletingEntityText: this.props.category.text,
      deletingEntityType: 'Functional Competency'
    });    
  }
  handleCloseDeleteDialog = (isPositiveAnswer) => {    
    if (isPositiveAnswer && this.state.deletingEntityId) {      
      this.setState({processing:true});
      let catId = this.state.deletingEntityId;
      let isFuncCompetency = this.state.deletingEntityType === 'Functional Competency' ;
      let guideType = this.props.currentGuideType;
      this.props.deleteCompetency(catId, null).then(_ => {
        this.props.removeCategoryFromStorage(catId);        
        if (isFuncCompetency) { 
          this.props.selectDuration(0);
          this.props.selectGuideType(guideType);
        }
        else {
          this.setState({processing:false});
        }
      });
    }
    this.setState({ 
      openDeleteDialog: false,
      deletingEntityId:0,
      deletingEntityText:'',
      deletingEntityType:''
     });
  };
  renderSingleItem() {
    const [group] = this.props.groups;
    console.log(group);
    var questionList = <QuestionsList />;
    if (group !== undefined) {
      questionList = <QuestionsList groupId={group.id} />
    }
    return (
      <div>
        {this.renderHeader()}
        {questionList}
      </div>
    );
    // return (
    //   <div>
    //     {this.renderHeader()}
    //     <QuestionsList groupId={group.id} />
    //   </div>
    // );
  }

  renderTitle() {
    const { category, classes } = this.props;
    
    if (category && category.type === "FunctionalCompetency") {
      return (
        <Typography variant="h3" className={classes.header}>Select your functional competency questions for {category.text}</Typography>
      );
    }
    
    if (category && category.description) {
      return (
        <Typography variant="h3" className={classes.header}>{category.description}</Typography>
      );
    }
  }

  renderHeader() {
    const {
      classes,
      tip
    }
     = this.props;

    if (!tip) {
      return this.renderTitle();
    }

    return (
      <JnJGridRow>
        <JnJGridColumn xs={12} md={8}>
          {this.renderTitle()}
        </JnJGridColumn>
        <JnJGridColumn xs={12} md={4}>
          <div className={classes.categoryHint}>
            <LightbulbInfo>
              {tip.text}
            </LightbulbInfo>
          </div>
        </JnJGridColumn>
      </JnJGridRow>
    );
  }

  renderGroups() {
    const { groups, classes, category, isWriter } = this.props;
    const isFunctionalCompetency = category && category.type === "FunctionalCompetency";

    return (
        <div>
          {this.renderHeader()}
          {groups.map((group) =>
            <CollapsedItem
              key={group.id}
              title={group.text}
              size={isFunctionalCompetency ? "small" : ""}
            >
            {
              isWriter && 
              <JnJGridRow>
                <JnJGridColumn xs={6} md={8}>
                </JnJGridColumn>
                <JnJGridColumn xs={12} md={4} className={classes.competencyToolbar}>
                  <Typography variant="h5" color="secondary">Edit Competency:</Typography>
                  <JnJButton name="Edit" onClick={() => this.onEditCompetency(group.id)} >
                    <EditIcon />
                  </JnJButton>
                  {isFunctionalCompetency && 
                    <JnJButton name="Delete" onClick={() => this.onDeleteCompetency(group)} >
                      <DumbIcon />
                    </JnJButton>
                  }
                </JnJGridColumn>
              </JnJGridRow>
            }
              {group.description &&
              <div className={classes.hint}>
                <LightbulbIcon className={classes.hintIcon}/>
                <Typography variant="h5">{group.description}</Typography>
              </div>
              }
              <QuestionsList key={group.id} groupId={group.id}/>
            </CollapsedItem>
          )}
        </div>
    )
  }

  functionalCompetencyAdminButtons() {
    const { groups, category, classes } = this.props;

    return (<div>
      {groups.length == 0 && 
        <QuestionsList groupId={category.id} />}
      {!this.props.isQuestionExist() && 
        <button onClick={this.onAddCompetency} className={classes.link}>
          <PlusIcon className={classes.plusIcon}/>
          <SecondaryLink className={classes.linkText}>
            Add competency
          </SecondaryLink>
        </button>}
      <button onClick={this.onDeleteFunctionalCompetency} className={classes.link}>
        <SecondaryLink className={classes.linkText}>
          Delete Functional Competency
        </SecondaryLink>
      </button>       
    </div>);
  }

  render() {
    const { groups, isWriter, classes, category, className, tip } = this.props;
    const isFunctionalCompetency = category && category.type === "FunctionalCompetency";

    return (<React.Fragment>
      {!this.state.processing &&
      <div className={className}>
        {groups.length > 1 || isFunctionalCompetency
          ? this.renderGroups()
          : this.renderSingleItem()}
        {isWriter && isFunctionalCompetency 
          ? this.functionalCompetencyAdminButtons()
          : null}
      </div>
      }
      {
        tip && (<div className={classes.mobileHint}>
          <LightbulbInfo>
            {tip.text}
          </LightbulbInfo>
        </div>)
      }
      <DeleteModalDialog
          open={this.state.openDeleteDialog}
          modalClose={this.handleCloseDeleteDialog}
          deletedElement={this.state.deletingEntityText}
          question={`Are you sure you want to delete this ${this.state.deletingEntityType}?`}
        />
    </React.Fragment>);
  }
}

const mapStateToProps = (state, {categoryId}) => {
  const currentCategory = state.category.categoryStore[categoryId];
  return {
    category: currentCategory,
    groups: state.category.groups
      .map(id => state.category.categoryStore[id])
      .filter(cat => cat && cat.parentId === categoryId),
    tip: currentCategory && currentCategory.type === "FunctionalCompetency" 
      ? selectTip(state.tip.tipStore, currentCategory.parentId, state.category.duration)
      : selectTip(state.tip.tipStore, categoryId, state.category.duration),
    isWriter: selectIsWriter(state),
    isQuestionExist: () => state.category.questions.some(qId => state.category.questionStore[qId].categoryId === categoryId),
    currentDuration: state.category.duration,
    currentGuideType: state.category.guide.guideTypeCategoryId,
    currentLanguage: state.language.code
  };
};

const mapDispatchToProps = {
  deleteCompetency: editCategoryActions.deleteCategory,
  selectCompetency: selectCategoryActions.selectCompetency,
  selectGuideType: selectCategoryActions.selectGuideType,
  selectDuration: selectCategoryActions.selectDuration,
  removeCategoryFromStorage: selectCategoryActions.removeCategoryFromStorage
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(withRouter(QuestionCategory)));
