import ReactAI from 'react-appinsights';
import ReactGA from 'react-ga';

type HistoryLinkProps = {
    category?: string,
    action?: string,
    label: Object,
    onClick: Function
};

//save link action to google analytics
export function onHistoryLinkClicked(props:HistoryLinkProps, htmlEvent?:any) {
    if (!props)
        return;

    if (props.label && typeof props.label === "string") {
        const data = {
            category: props.category && typeof props.category === "string" ? props.category : 'link-click',
            action: props.action && typeof props.action === "string" ? props.action : 'default',
            label: props.label
        };
        ReactGA.event(data);
        ReactAI.ai().trackEvent('history-link-clicked', data);

        // console.log('GA sent event -> {category:' + data.category+';action:' + data.action+';label:'+data.label+'}');
    }
  if (props.onClick)
        props.onClick(htmlEvent);
}
