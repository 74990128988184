import React from "react";
import { connect } from "react-redux";
import { CallbackComponent } from "redux-oidc";
import { userManager } from "../../services/userManager";
import * as userActions from '../../store/user/action';
import {actions} from "../../store/selectCategory";

class CallbackPage extends React.Component {
  render() {
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={(e) => {
          localStorage.setItem('auth', 'ok');
          this.props.getUser();
          this.props.history.push(e.state && e.state.redirectUrl ? e.state.redirectUrl : '/');
        }}
        errorCallback={error => {
          localStorage.setItem('auth', 'error');
          this.props.history.push('/');
          console.error(error);
        }}
        >
        <div>Redirecting...</div>
      </CallbackComponent>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  getUser: userActions.fetchUser.request,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallbackPage);
