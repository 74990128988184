import { createAsyncAction } from "typesafe-actions";
import { UserDTO } from "../../services/type";
import { createPromisedAction } from "../../services/actionService";
import { switchRoleTo, setUserGuideAttendance } from "../../services/user";

export const fetchUser = createAsyncAction(
  "@@user/FETCH_REQUEST",
  "@@user/FETCH_SUCCESS",
  "@@user/FETCH_FAIL"
)<void, UserDTO, string>();

export const SWITCH_USER_ROLE = "SWITCH_USER_ROLE";
export const switchCurrentUserRoleTo = (roleName: string) => {
  return createPromisedAction(switchRoleTo(roleName), SWITCH_USER_ROLE);
}

export const userAttende = createAsyncAction(
  "@@user/ATTEND_REQUEST",
  "@@user/ATTEND_SUCCESS",
  "@@user/ATTEND_FAIL"
)<void, void, string>();
