import React, { PureComponent } from "react";
import { connect } from 'react-redux'
import { JnJThemeProvider } from "@jaj/ui-components";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { ResourcesPage } from "./pages/ResourcesPage";
import { HomePageArticle } from "./pages/HomePageArticle";
import "./App.css";
import "./App.scss";

import {
  SelectDuration,
  SelectGuideType,
  QuestionsListPage,
  SelectFunctionalCompetency
} from "./pages/InterviewGuidePage/index";

import CallbackPage from "./pages/Login/CallbackPage";
import { EditCompetency } from "./pages/EditCompetency";
import { AddCompetencyPage } from "./pages/AddCompetencyPage";
import { getValidatedUserState } from './services/userManager'
import { QuestionAddEditPage } from "./pages/InterviewGuidePage/QuestionAddEdit";
import { CompetencyAddEditPage } from "./pages/InterviewGuidePage/CompetencyAddEdit";
import { config } from './services/config';
import Header from "./components/Header";
import Footer from "./components/Footer";
import ReactGA from 'react-ga';
import ReactAI from 'react-appinsights';
import ShowInterviewGuides from './pages/ShowInterviewGuides/ShowInterviewGuides';
import SharedGuide from "./components/SharedGuide/SharedGuide";
import {ErrorModalDialog} from './components/ModalDialog';

import RefreshCache from "./components/RefreshCache/RefreshCache";

ReactAI.init({ instrumentationKey:config.appInsight });
ReactGA.initialize(config.gaTrackingId);

class App extends PureComponent {

  componentWillReceiveProps(nextProps) {
    // When props change, check if the URL has changed or not
    const currentLocation = this.props.location;
    const newLocation = nextProps.location;
    if (currentLocation.pathname !== newLocation.pathname
        || currentLocation.search !== newLocation.search) {
        this.sendPageChange(newLocation.pathname, newLocation.search)
    }
  }


  sendPageChange(pathname, search='') {
      const { user } = this.props;
      const page = pathname + search;
      const userId = user && user.data && user.data.currentUser ? user.data.currentUser : '';
      const level = user && user.data ? (user.data.userIsInRoleWriter ? 'admin' : 'user') : '';

      ReactGA.set({ page, userId, level });
      ReactGA.pageview(page);

      //ReactAI.setAppContext({urlReferrer: document.referrer});
      const appInsights = ReactAI.ai();
      appInsights.setAuthenticatedUserContext(userId);
      appInsights.trackPageView(document.title, page, {
          userId, level
      });
  }

  render() {
    if (this.props.location.pathname && this.props.location.pathname.indexOf('/login/callback') < 0) {
      this.props.validateAuth();

      if (!this.props.user.loading && this.props.user.error)
      {
        return (<div>Unexpected error: {this.props.user.error}</div>)
      }
      else if (this.props.location.pathname.indexOf('/downloads/resource') >= 0)
      {
        return (<Route exact path="/downloads/:resource?/:name?" component={ResourcesPage} />)
      }
    }

    if (!this.props.user.loading && this.props.user.data)
    {
      if (window.location.href.search(/^(.*)(#error)$/) != -1) {
        localStorage.setItem('displayErrorPopUp', 'true')
      }
      var errorModalDialog;
      if (localStorage.getItem('displayErrorPopUp') === 'true') {
        errorModalDialog = <ErrorModalDialog
          open={true}
          errorCode="Sorry."
          errorMsg="It's not you. It's us."
        />;
      } else {
        errorModalDialog = "";
      }

      return (
        <RefreshCache>
          {({loading, refreshRequired, refreshCacheAndReload}) => {
            if (loading) return null;
            if (!loading && refreshRequired) {
              refreshCacheAndReload();
            }
            return (
              <JnJThemeProvider>
                <Header pageUri={this.props.location.pathname} />
                <div id="content">
                  <Switch>
                    <Route exact path="/" component={HomePage} />
                    <Route exact path="/how-to-get-most-of-your-interview" component={HomePageArticle} />
                    <Route exact path="/edit-competency/:guideType/:id" component={EditCompetency} />
                    <Route exact path="/downloads/:resource?/:name?" component={ResourcesPage} />
                    <Route exact path="/add-competency/:id" component={AddCompetencyPage} />
                    <Route exact path="/interview/select-functional-competency" component={SelectFunctionalCompetency} />
                    <Route exact path="/interview/questions-list-page" component={QuestionsListPage} />
                    <Route exact path="/interview/select-guide-type" component={SelectGuideType} />
                    <Route exact path="/interview/select-duration" component={SelectDuration} />
                    <Route path="/interview/competency/:action/:id?" component={CompetencyAddEditPage} />
                    <Route path="/interview/question/:action/:id?" component={QuestionAddEditPage} />
                    <Route path="/interview/share/:key" component={SharedGuide} />
                    <Route path="/interview" component={ShowInterviewGuides} />
                    <Redirect from='*' to='/'  />
                  </Switch>
                </div>
                <Footer/>
                {errorModalDialog}
              </JnJThemeProvider>
            );
          }}
        </RefreshCache>
      );  
    }
    else
    {
      return (<Switch>
        <Route path="/login/callback" component={CallbackPage} />
      </Switch>);
    }
  }
}


const mapStateToProps = (state) => ({
  user: state.user,
  validateAuth: () => getValidatedUserState(state)
});

export default withRouter(connect(mapStateToProps)(App));
