import * as React from "react";
import {withStyles, StyleRulesCallback} from "@material-ui/core/styles";
import { WithStyles } from "@material-ui/core";
import Card from "../Card/Card";

export interface Props extends WithStyles<typeof styles> {
  icon?: React.ReactNode,
  className?: string,
  children?: React.ReactNode,
  onClick?: (() => void),
}

const styles: StyleRulesCallback = theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: "25px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      padding: "50px 35px 90px"
    }
  },
  body: {
    marginLeft: "20px",
    [theme.breakpoints.up("md")]: {
      marginLeft: 0,
      marginTop: "25px"
    }
  },
  iconWrapper: {
    width: "55px",
    [theme.breakpoints.up("md")]: {
      width: "auto"
    }
  },
  textItem: {
    lineHeight: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "25px"
    }
  }
});

const IconCard = (props:Props) => {
  const {classes, icon, className, children, onClick} = props;

  return (
    <Card className={className} onClick={onClick}>
      <div className={classes.root}>
        <div className={classes.iconWrapper}>
          {icon}
        </div>
        <div className={classes.body}>
          <div className={classes.textItem}>
            {children}
          </div>
        </div>
      </div>
    </Card>
  );
};


export default withStyles(styles)(IconCard);
