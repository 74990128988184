import { Action as ReduxAction } from 'redux';
import { ActionType, createAsyncAction, createAction } from 'typesafe-actions';
import { CategoryID, CategoryDTO, GuidePages, Duration, CategoryTab, ReportDocRequestDTO } from './type';
import { IQuestion } from '../../models/questionModels';
import { Tip } from '../tip/tipType';

export interface Action<
  T extends string,
  P = undefined
  > extends ReduxAction {
  type: T
  payload: P
}

export const SELECT_POSITION = '@@selectCategory/SELECT_POSITION';
export const SET_PAGE_PATH ='@@selectCategory/SET_PAGE_PATH'
export const SELECT_TAB = '@@selectCategory/SELECT_TAB';
export const SELECT_FUNCTIONAL_COMPETENCY = '@@selectCategory/SELECT_FUNCTIONAL_COMPETENCY';
export const SELECT_DURATION = '@@selectCategory/SELECT_DURATION';

export const OPEN_PREVIEW_MODAL = '@@selectCategory/OPEN_PREVIEW_MODAL';
export const PREVIEW_GUIDE_MODAL = '@@selectCategory/PREVIEW_GUIDE_MODAL';
export const EDIT_GUIDE = '@@selectCategory/EDIT_GUIDE';
export const COPY_GUIDE = '@@selectCategory/COPY_GUIDE';
export const DOWNLOAD_SHARED_GUIDE = '@@selectCategory/DOWNLOAD_SHARED_GUIDE';
export const CLOSE_PREVIEW_MODAL = '@@selectCategory/CLOSE_PREVIEW_MODAL';
export const CHANGE_MODAL_VIEW_STATUS = '@@selectCategory/CHANGE_MODAL_VIEW_STATUS';
export const STOP_INITIALIZE_PREVIEW_MODAL = '@@selectCategory/STOP_INITIALIZE_PREVIEW_MODAL';

export const SAVE_DRAFT = '@@SAVE_DRAFT';
export const SET_LANGUAGE = '@@SET_LANGUAGE';
export const PUBLISH_GUIDE = '@@PUBLISH_GUIDE';
export const DELETE_GUIDE = 'DELETE_GUIDE';

const init = createAction('@@selectCategory/INIT');
const fetchLanguages = createAction('@@FETCH_LANGUAGES');
const resetAll = createAction('@@selectCategory/RESET_ALL');
const clearGuide = createAction('@@selectCategory/CLEAR_GUIDE');
const resetAllQuestion = createAction('@@selectCategory/RESET_QUESTION');
const fetchGuideTypes = createAction('@@selectCategory/FETCH_GUIDE_TYPES');
const fetchDrafts = createAction('@@selectCategory/FETCH_DRAFTS');
const fetchGuides = createAction('@@selectCategory/FETCH_GUIDES');
const fetchPublished = createAction('@@selectCategory/FETCH_PUBLISHED');
const stopPreviewDoc = createAction('@@selectCategory/STOP_PREVIEW_DOC');


const changeDownloadStatus = createAction('@@selectCategory/CHANGE_DOWNLOAD_STATUS', resolve => {
  return (status: boolean) => resolve({ status });
});

const changeDraftStatus = createAction('@@selectCategory/CHANGE_DRAFT_STATUS', resolve => {
  return (status: number) => resolve({ status });
});

const changeGuideName = createAction('@@selectCategory/CHANGE_GUIDE_NAME', resolve => {
  return (name: string) => resolve({ name });
});

const goToPage = createAction('@@selectCategory/GO_TO_PAGE', resolve => {
  return (page: string) => resolve({
    page
  });
})
const removeQuestionFromStore = createAction('@@selectCategory/REMOVE_QUESTION_FROM_STORAGE', resolve => {
  return (questionId: number) => resolve({
    questionId
  });
});
const selectGuideType = createAction(SELECT_POSITION, resolve => {
  return (position: CategoryID, guideTypeCategoryName: string ) => resolve({
    position,
    guideTypeCategoryName,
  });
});
const setPagePath = createAction(SET_PAGE_PATH, resolve => {
  return (path: string, ) => resolve({
    page: path,
  });
});
const saveDraft = createAction(SAVE_DRAFT, resolve => {
  return (name: string) => resolve({ name });
});

const setLanguage = createAction(SET_LANGUAGE, resolve => {
  return (languageCode: string) => resolve(languageCode);
});

const publishGuide = createAction(PUBLISH_GUIDE);

interface SelectCompetencyAction extends Action<typeof SELECT_FUNCTIONAL_COMPETENCY, {
  competency: CategoryID;
  functionCategoryName: string,
  page: string;
}> { }

function selectCompetency(competency: CategoryID, functionCategoryName: string): SelectCompetencyAction {
  return {
    type: SELECT_FUNCTIONAL_COMPETENCY,
    payload: {
      competency,
      functionCategoryName,
      page: GuidePages.duration
    }
  }
}

const selectDuration = createAction(SELECT_DURATION, resolve => {
  return (duration: Duration, text: String) => resolve({
    duration,
    text,
    page: GuidePages.questions
  });
});

export const selectTab = createAction(SELECT_TAB, resolve => {
  return (tab: CategoryTab, categoryId: number) => resolve({
    tab,
    categoryId,
  });
});

const fetchGuideTypeAction = createAsyncAction(
  '@@selectCategory/FETCH_GUIDE_TYPE_REQUEST',
  '@@selectCategory/FETCH_GUIDE_TYPE_SUCCESS',
  '@@selectCategory/FETCH_GUIDE_TYPE_FAIL',
)<void, CategoryDTO[], string>();

const fetchCategoriesAction = createAsyncAction(
  '@@selectCategory/FETCH_CATEGORIES_REQUEST',
  '@@selectCategory/FETCH_CATEGORIES_SUCCESS',
  '@@selectCategory/FETCH_CATEGORIES_FAIL',
)<void, CategoryDTO[], string>();

const downloadSharedGuideAsync = createAsyncAction(
  'DOWNLOAD_SHARED_GUIDE_REQUEST',
  'DOWNLOAD_SHARED_GUIDE_SUCCESS',
  'DOWNLOAD_SHARED_GUIDE_FAIL',
)<string, ReportDocRequestDTO, string>();
// )<{[key: string]: string}, ReportDocRequestDTO, string>();

const removeCategoryFromStorage = createAction('@@selectCategory/REMOVE_CATEGORY_FROM_STORAGE', resolve => {
  return (categoryId: number) => resolve({
    categoryId
  });
});

const previewGuideModal = createAction(PREVIEW_GUIDE_MODAL, resolve => {
  return (guide: ReportDocRequestDTO, viewStatus: string) => resolve({ guide, viewStatus })
});

const editGuide = createAction(EDIT_GUIDE, resolve => {
  return (guide: ReportDocRequestDTO) => resolve({ guide })
});

const copyGuide = createAction(COPY_GUIDE, resolve => {
  return (guide: ReportDocRequestDTO) => resolve({ guide })
});

const deleteGuide = createAction(DELETE_GUIDE, resolve => {
  return (guide: ReportDocRequestDTO) => resolve({
    guide
  });
});

const deleteGuideAsync = createAsyncAction(
  '@@selectCategory/DELETE_GUIDE_REQUEST',
  '@@selectCategory/DELETE_GUIDE_SUCCESS',
  '@@selectCategory/DELETE_GUIDE_FAIL'
)<number, number, string>();

const fetchQuestionsAction = createAsyncAction(
  '@@selectCategory/FETCH_QUESTIONS_REQUEST',
  '@@selectCategory/FETCH_QUESTIONS_SUCCESS',
  '@@selectCategory/FETCH_QUESTIONS_FAIL',
)<void, IQuestion[], string>();
const openPreviewModal = createAction(OPEN_PREVIEW_MODAL, resolve => {
  return (guide: ReportDocRequestDTO, name: string, viewStatus: string) => resolve({ guide: { ...guide, name }, viewStatus })
});

const changeGuideModalStatus = createAction(CHANGE_MODAL_VIEW_STATUS, resolve => {
  return (viewStatus: string) => resolve({ viewStatus })
});

const closePreviewModal = createAction(CLOSE_PREVIEW_MODAL);

const stopInitializingPreviewModal = createAction(STOP_INITIALIZE_PREVIEW_MODAL);

const fetchPreviewDocAction = createAsyncAction(
  '@@selectCategory/FETCH_PREVIEW_DOC_REQUEST',
  '@@selectCategory/FETCH_PREVIEW_DOC_SUCCESS',
  '@@selectCategory/FETCH_PREVIEW_DOC_FAIL',
)<string, ReportDocRequestDTO, string>();

const fetchTips = createAsyncAction(
  '@@tip/FETCH_TIPS_REQUEST',
  '@@tip/FETCH_TIPS_SUCCESS',
  '@@tip/FETCH_TIPS_FAIL',
)<void, Tip[], string>();

const saveGuideAsDraft = createAsyncAction(
  '@@SAVE_DRAFT_REQUEST',
  '@@SAVE_DRAFT_SUCCESS',
  '@@SAVE_DRAFT_FAIL',
)<void, ReportDocRequestDTO, string>();

const publishGuideAsync = createAsyncAction(
  '@@PUBLISH_GUIDE_REQUEST',
  '@@PUBLISH_GUIDE_SUCCESS',
  '@@PUBLISH_GUIDE_FAIL',
)<void, ReportDocRequestDTO, string>();

const downloadGuideAsync = createAsyncAction(
  '@@DOWNLOAD_GUIDE_REQUEST',
  '@@DOWNLOAD_GUIDE_SUCCESS',
  '@@DOWNLOAD_GUIDE_FAIL',
)<ReportDocRequestDTO, ReportDocRequestDTO, string>();

const fetchGuideAsync = createAsyncAction(
  '@@FETCH_GUIDE_REQUEST',
  '@@FETCH_GUIDE_SUCCESS',
  '@@FETCH_GUIDE_FAIL',
)<number, ReportDocRequestDTO, string>();

const fetchDraftsList = createAsyncAction(
  '@@fetch_DRAFTs_REQUEST',
  '@@fetch_DRAFTs_SUCCESS',
  '@@fetch_DRAFTs_FAIL',
)<void, ReportDocRequestDTO[], string>();

const fetchPublishedList = createAsyncAction(
  '@@fetch_PUBLISHED_REQUEST',
  '@@fetch_PUBLISHED_SUCCESS',
  '@@fetch_PUBLISHED_FAIL',
)<void, ReportDocRequestDTO[], string>();

const fetchGuideList = createAsyncAction(
  '@@FETCH_GUIDES_REQUEST',
  '@@FETCH_GUIDES_SUCCESS',
  '@@FETCH_GUIDES_FAIL',
)<void, { [key: string]: ReportDocRequestDTO[] }, string>();

export const actions = {
  init,
  fetchLanguages,
  resetAll,
  resetAllQuestion,
  removeQuestionFromStore,
  goToPage,
  fetchGuideTypeAction,
  fetchCategoriesAction,
  fetchQuestionsAction,
  selectDuration,
  selectGuideType,
  selectCompetency,
  selectTab,
  openPreviewModal,
  stopPreviewDoc,
  changeGuideModalStatus,
  previewGuideModal,
  closePreviewModal,
  fetchPreviewDocAction,
  stopInitializingPreviewModal,
  fetchTips,
  fetchGuideTypes,
  removeCategoryFromStorage,
  saveDraft,
  saveGuideAsDraft,
  fetchDraftsList,
  fetchDrafts,
  fetchGuides,
  publishGuide,
  publishGuideAsync,
  downloadGuideAsync,
  fetchPublishedList,
  fetchGuideList,
  fetchPublished,
  editGuide,
  copyGuide,
  downloadSharedGuideAsync,
  clearGuide,
  deleteGuide,
  deleteGuideAsync,
  changeDraftStatus,
  changeDownloadStatus,
  changeGuideName,
  setLanguage,
  fetchGuideAsync,
  setPagePath
};

export type Actions =
  | ActionType<typeof actions>
  | SelectCompetencyAction
