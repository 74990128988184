import { axiosInstance } from './apiService';
import { QuestionId, IQuestion, IEntityTranslation } from '../models/questionModels';

export async function loadQuestionWithTranslationsRequest(questionId: QuestionId): Promise<IEntityTranslation[]> {
  const res = await axiosInstance.get(`/questions/${questionId}/translations`);
  return res.data;
}
export async function loadQuestionsByCategory(languageCode = 'en', categoryId: number, questionType?:string): Promise<IQuestion[]> {
  const questionTypeQuery = questionType ? ('?questionType='+questionType) : '';
  const res = await axiosInstance.get(`/questions/${languageCode}/${categoryId}${questionTypeQuery}`);
  return res.data;
}

export async function saveQuestionWithTranslationsRequest(
  questionId: QuestionId,
  questionsToSave: IEntityTranslation[]): Promise<IEntityTranslation[]> {

  await axiosInstance.post(`/questions/${questionId}/translations`, questionsToSave);

  return questionsToSave;
}

export async function createQuestionRequest(question: IQuestion) {
  const response = await axiosInstance.put(`/questions/${question.languageCode}`, question);
  return response.data;
}

export async function deleteQuestionRequest(questionId: QuestionId) {
  const response = await axiosInstance.delete(`/questions/${questionId}`);
  return response.data;
}
