import * as React from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import { JnJGridRow, JnJGridColumn, JnJLink, LightbulbInfo } from '@jaj/ui-components';
import RatingScaleDialog from './RatingScaleDialog';
import cs from 'classnames';

const styles = theme => ({
  textSection: {
    padding: '35px 0',
    borderBottom: '1px solid #D8D8D8',
    [theme.breakpoints.down('md')]: {
      padding: 0,
      borderBottom: 0
    }
  },
  learnMore: {
    marginTop: 20,
    marginBottom: 45,
    float: 'right',
    [theme.breakpoints.down('md')]: {
      float: 'none',
      marginBottom: 15
    }
  },
  tipContent: {
    maxWidth: 325,
    display: 'flex',
    marginLeft: -110,
    marginTop: 157,
    [theme.breakpoints.down('md')]: {
      margin: '0 0 30px 0'
    }
  },
  title: {
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  }
});

export interface InterviewingBasicsProps extends WithStyles {
  onNext: () => void
}

export interface InterviewingBasicsState {
  showRatingScaleDialog: boolean;
}

class InterviewingBasics extends React.Component<InterviewingBasicsProps, InterviewingBasicsState> {
  state: InterviewingBasicsState = {
      showRatingScaleDialog: false
  };

  onShowRatingScaleDialog = () => {
    this.setState({ showRatingScaleDialog: true });
  };

  onCloseRatingScaleDialog = () => {
    this.setState({ showRatingScaleDialog: false });
  };

  render() {
    const {onNext, classes} = this.props;
    return (
      <div className={classes.root}>
        <JnJGridRow className="justify-center">
          <JnJGridColumn xs={12} md={10}>
            <div className={classes.textSection}>
              <JnJGridRow>
                <JnJGridColumn xs={12} lg={9}>
                  <Typography
                    variant="h3"
                    color="secondary"
                    className={cs("margin-bottom-20", classes.title)}
                  >
                    Interviewing basics
                  </Typography>
                  <Typography
                    variant="body1"
                    className="margin-bottom-20"
                  >
                    An interview is an opportunity to assess a candidate’s strengths and weaknesses, skills, and ability to help us live up to Our Credo. And while this “getting to know you” process may seem like it should be instinctive, successful interviewing isn’t about following your gut. At Johnson & Johnson companies, we apply the same rigorous standards to interviewing that we apply to every aspect of our businesses. That means we make sure every interview:
                  </Typography>
                  <ul className="list">
                    <li>
                      Is behavioral
                    </li>
                    <li>
                      Is structured
                    </li>
                    <li>
                      Is based on a validated model
                    </li>
                    <li>
                      Treats all candidates fairly
                    </li>
                  </ul>
                  <Typography
                    variant="h5"
                    className="margin-top-20 margin-bottom-15"
                  >
                    Behavioral
                  </Typography>
                  <Typography
                    variant="body1"
                    className="margin-bottom-20"
                  >
                    Behavioral interviews ask questions about experiences that demonstrate important competencies (skills).
                    Encourage candidates to respond with the STAR model: Describe the Situation/Task, Action taken, and
                    Result. Include questions to evaluate:
                  </Typography>
                  <ul className="list">
                    <li>
                      Leadership competencies (Johnson & Johnson Leadership Imperatives)
                    </li>
                    <li>
                      Technical/functional competencies (specific to the function)
                    </li>
                  </ul>
                  <Typography
                    variant="h5"
                    className="margin-top-20 margin-bottom-15 weight-black"
                  >
                    Structured
                  </Typography>
                  <Typography
                    variant="body1"
                    className="margin-bottom-30"
                  >
                    When interviewing, consistency is key! Ask all candidates about the same competencies using the same or similar questions. Use a consistent rating scale to promote a fair comparison of candidates and help reduce any potential for unconscious bias. (Click <a onClick={this.onShowRatingScaleDialog}>here</a> to see an example of the rating scale we use.)
                  </Typography>
                  <Typography
                    variant="h5"
                    className="margin-bottom-15"
                  >
                    Based on a validated model
                  </Typography>
                  <Typography
                    variant="body1"
                    className="margin-bottom-30"
                  >
                    Use questions based on proven competency models related to success in the position. Follow our Interview
                    Guide in our interview tool to ensure that you’re using a validated model.
                  </Typography>
                  <Typography
                    variant="h5"
                    className="margin-bottom-15"
                  >
                    Treats candidates fairly
                  </Typography>
                  <Typography
                    variant="body1"
                    className="margin-bottom-30"
                  >
                    Write and ask questions in a way that respects candidates and seeks relevant information without preconceptions about the candidate or about what a candidate for the role should look like. For example, if you’re hiring for an entry-level role and you’re interviewing an older candidate, don’t let that affect the questions you ask.
                  </Typography>
                </JnJGridColumn>
                <JnJGridColumn xs={12} lg={3}>
                  <div className={classes.tipContent}>
                    <LightbulbInfo direction="vertical">
                      Plan ahead! It will probably take 3 - 5 days to schedule and prepare for interviews, 5 - 10 days to
                      conduct interviews, and 1 - 2 days to debrief your team and make a decision.
                    </LightbulbInfo>
                  </div>
                </JnJGridColumn>
              </JnJGridRow>
              <Typography
                variant="h4"
                color="secondary"
                className="margin-bottom-15"
              >
                What’s the advantage of this approach?
              </Typography>
              <Typography
                variant="body1"
                className="margin-bottom-15"
              >
                Structured, behavioral-based interviews (BBI) will help you and your team:
              </Typography>
              <JnJGridRow>
                <JnJGridColumn md={9}>
                  <ul className="list">
                    <li>
                      Consistently evaluate and compare multiple candidates
                    </li>
                    <li>
                      Focus on competencies, skills and experiences that would help a candidate be successful in the
                      position and in our company
                    </li>
                    <li>
                      Identify strengths and/or development opportunities that can be addressed during onboarding and on an
                      ongoing basis
                    </li>
                  </ul>
                </JnJGridColumn>
              </JnJGridRow>
            </div>
            <div className={classes.learnMore} onClick={onNext}>
              <JnJLink>Learn about interview styles</JnJLink>
            </div>
          </JnJGridColumn>
        </JnJGridRow>
        <RatingScaleDialog
          open={this.state.showRatingScaleDialog}
          onClose={this.onCloseRatingScaleDialog}
        />
      </div>
    );
  }
}

export default withStyles(styles)(InterviewingBasics);
