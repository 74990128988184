import { RootState } from "..";

export function selectPositionId(state: RootState) {
  return state.category.guide.guideTypeCategoryId;
}

export function selectCategoryId(state: RootState): number {
  return state.category.categoryId || 0;
}

export function selectDuration(state: RootState) {
  return state.category.duration;
}
