import React from "react"
import { PlusIcon } from "@jaj/ui-components";
import { Card, CardMedia, Typography, withStyles } from "@material-ui/core";


const useStyles = theme => ({
  cardSize: {
    height: '300px',
    maxWidth: '300px',
    padding: '35px 30px',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      height: '65px',
      width: '305px',
    }
  },
  cardInner: {
    height: '100%',
    width: '100%',
    position: 'relative',
    fontFamily: "J&J Circular",
  },
  createGuide: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'row',
    }
  },
  cardTitlePrimary: {
    fontSize: '24px',
    lineHeight: '30px',
    fontWeight: 300,
    color: '#000099',
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      fontSize: ' 16px',
      fontWeight: ' 300',
      lineHeight: ' 20px',
    }
  },
  greyPlusIcon: {
    width: '74px',
    height: '74px',
    filter: 'brightness(0) saturate(100%) invert(94%) sepia(1%) saturate(70%) hue-rotate(360deg) brightness(93%) contrast(93%)',
    [theme.breakpoints.down('md')]: {
      width: '29px',
      height: '29px',
    }
  },
  greyBg: {
    backgroundColor: '#F4F4F4',
    cursor: 'pointer'
  },
});

const EmptyCard = ({ classes, onClick }) => (
  <Card className={`${classes.cardSize} flex-grow ${classes.greyBg}`} onClick={onClick} >
    <div className={classes.cardInner}>
      <div className={classes.createGuide}>
        <CardMedia image={'none'}>
          <PlusIcon className={classes.greyPlusIcon} />
        </CardMedia>
        <div>
          <Typography variant="h2" color="secondary" className={classes.cardTitlePrimary}>
            Create a new Interview Guide
                        </Typography>
        </div>
      </div>
    </div>
  </Card>
);


export default withStyles(useStyles)(EmptyCard);
