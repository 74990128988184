import React, { Component } from 'react'
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import {
  JnJLink,
  LinkCard,
  JnJGridColumn,
  JnJGridRow,
  EditIcon,
  SecondaryLink,
  PlusIcon,
  LightbulbInfo,
} from '@jaj/ui-components';
import { Link } from 'react-router-dom';
import { NextIcon } from '@jaj/ui-components';
import { selectTip } from '../../services/tipService';
import { selectIsWriter } from '../../store/user/selector';
import { actions } from '../../store/selectCategory/action';
import CircularProgress from '@material-ui/core/CircularProgress';
import { onHistoryLinkClicked } from '../../services/historyLinksService';
import Container from '../../components/Container/Container';
import {Redirect} from 'react-router-dom';

const styles = theme => ({
  adminLinkCard: {
    display: 'flex',
    alignItems: 'center'
  },
  editLink: {
    alignSelf: 'stretch',
    display: 'flex',
  },
  editLinkIcon: {
    alignItems: 'center',
    display: 'flex',
  },
  tip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-start',
    },
  },
  tipContent: {
    padding: "30 0",
    maxWidth: 450,
  },
  listItem: {
    height: 70,
    display: 'flex',
    alignItems: 'center',
    padding: '0 30px'
  },
  loadingContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50vh'
  },
  skipButton: {
    cursor: 'pointer',
    color: '#CA001B',
    fontSize: 18,
    fontWeight: 500,
    justifyContent: 'flex-end',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    },
  },
  skipDescription: {
    [theme.breakpoints.down('md')]: {
      order: 2
    },
  },
  nextIcon: {
    verticalAlign: -5,
    marginLeft: 4,
    [theme.breakpoints.down('md')]: {
      order: 1,
    },
  },
  skipButtonContainer: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'center'
    },
  },
  footer: {
    alignItems: 'center'
  },
  root: {
    backgroundColor: '#fff',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '50px',
      paddingBottom: '100px'
    },
  }
});

class SelectFunctionalCompetency extends Component {
  constructor() {
    super(...arguments);
    this.props.init();
  }

  componentDidMount() {
    window.onbeforeunload = () => true;
    // if (!this.props.position) {
    if (!this.props.guide.guideTypeCategoryId) {
      this.props.history.push('/interview/select-guide-type')
    };

  }

  componentWillUnmount() {
    window.onbeforeunload = null;
  }

  render() {
    const {
      initializing,
      classes,
      position,
      credoId,
      individualId,
      isWriter,
    } = this.props;

    // Redirecting back to Question List page
    if(document.documentURI.search(/\?reload=yes/) != -1) {
      return <Redirect to="/interview/questions-list-page"/>;
    }

    return initializing ? (
      <div className={classes.loadingContent}>
        <CircularProgress />
      </div>
    ) : (
        <Container static className={classes.root}>
          <JnJGridRow>
            <JnJGridColumn xs={false} lg={1} />
            <JnJGridColumn xs={12} lg={10}>
              <div className="pbottom-40 mtop-20">
                <div className="mbottom-10" onClick={() => this.props.history.push('/interview/select-guide-type')}>
                  <JnJLink left>Back to Position Level</JnJLink>
                </div>
                <Typography variant="h2" color="secondary" className='mbottom-40 mtop-50'>
                  What is the functional competency?
              </Typography>
                <JnJGridRow>
                  {this.props.getCategories().map(({ id, text }) => (
                    <JnJGridColumn xs={12} md={4} key={id}>
                      {!isWriter ?
                        <LinkCard
                          onClick={() => {
                            this.props.history.push('/interview/select-duration');
                            return onHistoryLinkClicked({
                              category: 'competency',
                              action: 'select',
                              label: text,
                              onClick: () => this.props.select(id, text)
                            })
                          }}
                          className={classes.listItem}
                        >{text}
                        </LinkCard> :
                        <div className={classes.adminLinkCard}>
                          <LinkCard
                            onClick={() => {
                              this.props.history.push('/interview/select-duration');
                              return onHistoryLinkClicked({
                                category: 'competency',
                                action: 'select',
                                label: text,
                                onClick: () => this.props.select(id, text, '/interview/select-duration')
                              })
                            }}>{text}</LinkCard>
                          <Link to={`/edit-competency/${position}/${id}`} className={classes.editLink}>
                            <LinkCard className={classes.editLinkIcon}>
                              <EditIcon color='#212121' />
                            </LinkCard>
                          </Link>
                        </div>
                      }
                    </JnJGridColumn>
                  ))}
                  {isWriter && (
                    <React.Fragment>
                      <JnJGridColumn xs={12}>
                        <Link to={`/add-competency/${position}`} className={classes.adminLinkCard}>
                          <PlusIcon />
                          <SecondaryLink className='mleft-10'>
                            Add a new function
                        </SecondaryLink>
                        </Link>
                      </JnJGridColumn>
                      <JnJGridColumn xs={12}>
                        <Link to={`/edit-competency/${position}/${credoId}`} className={classes.adminLinkCard}>
                          <PlusIcon />
                          <SecondaryLink className='mleft-10'>
                            Edit Credo
                        </SecondaryLink>
                        </Link>
                      </JnJGridColumn>
                      <JnJGridColumn xs={12}>
                        <Link to={`/edit-competency/${position}/${individualId}`} className={classes.adminLinkCard}>
                          <PlusIcon />
                          <SecondaryLink className='mleft-10'>
                            Edit Leadership Imperatives
                        </SecondaryLink>
                      </Link>
                    </JnJGridColumn>
                  </React.Fragment>
                )}
              </JnJGridRow>
              <JnJGridRow className={classes.footer}>
                {this.props.getTip() && (
                  <JnJGridColumn xs={12} md={6}>
                    <div className={classes.tip}>
                      <div className={classes.tipContent}>
                        <LightbulbInfo>{this.props.getTip().text}</LightbulbInfo>
                      </div>
                    </div>
                  </JnJGridColumn>
                )}
                <JnJGridColumn xs={12} md={6} className={classes.skipButtonContainer}>
                  <span
                    className={classes.skipButton}
                    onClick={() => {
                      this.props.history.push('/interview/select-duration');
                      return onHistoryLinkClicked({
                        category: 'competency',
                        action: 'select',
                        onClick: () => this.props.select(1, '', '/interview/select-duration')
                      })
                    }}
                  >
                    <span><pre>Skip. </pre></span>
                    <span className={classes.skipDescription}>My Functional Competency isn't listed.</span>
                    <NextIcon color='#CA001B' className={classes.nextIcon} />
                  </span>
                </JnJGridColumn>
              </JnJGridRow>
            </div>
          </JnJGridColumn>
        </JnJGridRow>
      </Container>
    )
  }
}

//Hide perticular Functional competency by Category ID
let HIDE_FC_LIST_IDS = [];

if (process.env.NODE_ENV === "production") {
  HIDE_FC_LIST_IDS = [3082, 3198, 3315, 3056, 3061, 3159, 3164, 3169, 3175, 3041, 3131, 3287, 3088, 3095, 3207];
} else {
  HIDE_FC_LIST_IDS = [3082, 3198, 3315, 3056, 3061, 3159, 3164, 3169, 3175, 3041, 3131, 3287, 3088, 3095, 3207];
}

const mapStateToProps = (state) => ({
  page: state.category.page,
  isWriter: selectIsWriter(state),
  initializing: state.category.initializingFunctionalCompetency,
  getCategories: () => state.category && state.category.functionalCompetency
    ? state.category.functionalCompetency.filter(id => !HIDE_FC_LIST_IDS.includes(id)).map(id => state.category.categoryStore[id])
    : [],
  credoId: state.category.questionCategory[0],
  individualId: state.category.questionCategory[1],
  position: state.category.guide.guideTypeCategoryId,
  getTip: () => state.category && state.category.guide.guideTypeCategoryId
    ? selectTip(state.tip.tipStore, state.category.guide.guideTypeCategoryId.toString(), '0')
    : null,
  guide: state.category.guide
});

const mapDispatchToProps = {
  init: actions.init,
  select: actions.selectCompetency,
}

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SelectFunctionalCompetency))

