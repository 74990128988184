import React from 'react';
import {withStyles} from "@material-ui/core";

const styles = theme => ({
  success: {
    color: 'white',
    display: 'flex',
    margin: '0 auto',
    width: '100%',
    padding: '18px 55px 18px 150px',
    backgroundColor: '#000099',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      padding: '20px 25px',
      alignItems: 'start'
    },
  },
  close: {
    cursor: 'pointer',
  },
  successMessage: {}
});

const NotificationMessage = ({ classes, notificationText, onClick }) => (
    <div className={classes.success}>
        {notificationText}
        <img src={require('../../styles/assets/xModal.svg')} alt='close' className={classes.close} onClick={onClick} />
    </div>
)

export default withStyles(styles)(NotificationMessage)
