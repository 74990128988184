import axios from 'axios';
import { config } from './config';
import store from '../store';
import { signIn, getTokenBy } from './userManager';

export const axiosInstance = axios.create({
  baseURL: config.apiUrl,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  transformRequest: function(data: any, headers?: any):any {
    const access_token = getTokenBy(store.getState());
    if (access_token)
      headers['Authorization'] = `Bearer ${access_token}`;
    if (data)
      return JSON.stringify(data);
    return data;
  },
  transformResponse: function(data: any):any {
    if(typeof data === 'string') {
      data=JSON.parse(data)
    }
    return data && data.result ? data.result: data;
  },
  validateStatus: function (status) {
    localStorage.setItem('displayErrorPopUp', 'false');
    if (status === 401) {
      signIn();
      return false;
    } else {
      if (!(status >= 200 && status < 300)) {
        console.log("Display Error Popup");
        localStorage.setItem('displayErrorPopUp', 'true');
      }
    }
    return status >= 200 && status < 300; // default
  }
});
