import * as React from "react";
import { FormControl, Input as MaterialInput, InputLabel, FormControlLabel } from "@material-ui/core";


interface ISelectOption {
  title: string;
  value: string;
}

interface ISelectProps {
  value: string;
  label?: string;
  name?: string;
  asteriskClass?: string;
  inputLabelClass?: string;
  required?: boolean;
  fullWidth?: boolean;
  onChange: (value: string) => void;
  defaultValue?: string
}

export default class Input extends React.PureComponent<ISelectProps, {}> {
  onChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.props.onChange(event.target.value);
  };

  render() {
    return (
      <FormControl fullWidth={this.props.fullWidth} required={this.props.required}>
        {this.props.label &&
        <InputLabel
            FormLabelClasses={{
              root: this.props.inputLabelClass,
              asterisk: this.props.asteriskClass
            }}
        >
          {this.props.label}
        </InputLabel>}
        <MaterialInput defaultValue={this.props.defaultValue} name={this.props.name} onChange={this.onChange} fullWidth={this.props.fullWidth} />
      </FormControl>
    );
  }
}
