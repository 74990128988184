import React from 'react'
import {TextField} from '@material-ui/core/';

export default function TextFullField(props) {
  const {
    input,
    label,
    ...custom
  } = props;
  return (
    <TextField
      label={label}
      {...input}
      {...custom}
    />
  )
}
