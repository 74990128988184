import React from 'react'
import { TextField } from '@material-ui/core/';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  error : {
    color: theme.palette.primary.main,
    fontSize: 16,
    lineHeight: '20px',
    marginTop: 5,
    display: 'block'
  }
})

const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  classes,
  ...custom
}) => (
  <div>
    <TextField
      placeholder={label}
      label={label}
      fullWidth
      {...input}
      {...custom}
    />
    {touched && error && <span className={classes.error}>{error}</span>}
  </div>
)

export default withStyles(styles)(renderTextField);
