import {axiosInstance} from './apiService';
import {CategoryID, CategoryDTO, CategoryTab} from '../store/selectCategory/type';
import {Category} from '../store/editCategory/type';
import { IQuestion, IEntityTranslation } from '../models/questionModels';

export async function loadGuideTypes(languageCode = 'en'): Promise<CategoryDTO[]> {
  const res = await axiosInstance.get(`/categories/${languageCode}/0`);
  return res.data;
}

export async function loadCategories(guideType: CategoryID, languageCode = 'en') {
  const res = await axiosInstance.get(`/categories/${languageCode}/${guideType}/all`);

  return res.data;
}

export async function loadQuestions(guideType: CategoryID, languageCode = 'en'): Promise<IQuestion[]> {
  const response = await axiosInstance.get<IQuestion[]>(`/questions/${languageCode}/${guideType}/all`, {
    params: {
      questionType: 'Simple'
    }
  });
  return response.data;
}

export async function loadGroups(guideType: CategoryID, languageCode = 'en') {
  const res = await axiosInstance.get(`/categories/${languageCode}/${guideType}/all`, {
    params: {
      categoryType: 'Group'
    }
  });

  return res.data;
}

export async function loadFunctionalCompetencyTranslation(id: CategoryID): Promise<IEntityTranslation[]> {
  const res = await axiosInstance.get(`/categories/${id}/translations`);
  return res.data;
}

export async function saveFunctionalCompetency(languageCode = 'en', functionalCompetency: Category): Promise<Category> {
  const res = await axiosInstance.put(`/categories/${languageCode}`, functionalCompetency);
  return res.data;
}

export async function deleteFunctionalCompetency(id: CategoryID, category: Category): Promise<Category | null> {
  const res = await axiosInstance.delete(`/categories/${id}`);
  return res.data ? category: null;
}

export async function saveFunctionalCompetencyWithTranslations(
  id: CategoryID,
  translations: Array<IEntityTranslation>) {

  const res =await axiosInstance.post(`/categories/${id}/translations`, translations);

  return res.data;
}

function getCategoryIndexMap(): {[key: string]: string} {
  return {
    0: CategoryTab.credo,
    1: CategoryTab.leadrship,
    2: CategoryTab.functional,
    3: CategoryTab.custom,
  };
}

export function getCategoryIndexById(index: number) {
  return getCategoryIndexMap()[index];
}
