import { RootState } from "..";
import { ReportDocRequestDTO, Category } from "./type"
import { selectCategoryId } from "../tip/tipSelector";

import { createSelector } from 'reselect';

const rootSelector =  (state: RootState) => state;

export const selectCategoryStore = (state: RootState) => rootSelector(state).category.categoryStore;
export const selectGuideType = (state: RootState) => rootSelector(state).category.guide.guideTypeCategoryId;

export const selectReportDocRequest = (state: RootState): ReportDocRequestDTO => {
  const selectedQuestions = rootSelector(state).questions;
  const selectedCustomQuestions = rootSelector(state).customQuestion.filter(el=>el.text.length > 0).map(el => el.text);
  const guideTypeCategoryId = rootSelector(state).category.guide.guideTypeCategoryId as number;
  const questionnaireLength = rootSelector(state).category.guide.questionnaireLength as number;
  const name = rootSelector(state).category.guide.name;
  const languageCode = rootSelector(state).category.guide.languageCode;
  const isDraft = rootSelector(state).category.guide.isDraft;
  const functionCategoryName = rootSelector(state).category.functionCategoryName;
  const functionCategoryId = rootSelector(state).category.guide.functionCategoryId;

  // hardcoded for development (Back-end always responses with text/plain - html);
  //@todo it will be fixed when 'PDF' format starts working
  const typeOfReport = "Word";

  const allChosenQuestionsRequest: ReportDocRequestDTO = {
    selectedQuestions,
    selectedCustomQuestions,
    guideTypeCategoryId,
    questionnaireLength,
    typeOfReport,
    functionCategoryId,
    functionCategoryName,
    name,
    isDraft,
    languageCode
  };

  return allChosenQuestionsRequest;
};

export const selectGuide = (state: RootState): ReportDocRequestDTO => {
  const selectedCustomQuestions = rootSelector(state).customQuestion.filter(el=>el.text.length > 0).map(el => el.text);
  const selectedQuestions = rootSelector(state).questions;
  const guide = rootSelector(state).category.guide;
  return {...guide, selectedCustomQuestions, selectedQuestions};
};

export const selectModalViewStatus = (state: RootState): string => {
  const viewStatus = rootSelector(state).category.previewModal.viewStatus;
  return viewStatus;
};

export const selectGuideViewStatus = (state: RootState): string => {
  const viewStatus = rootSelector(state).category.guideViewStatus;
  return viewStatus;
};

export const getGuideName = (state: RootState): string => {
  const guideName = rootSelector(state).category.name;
  return guideName;
};

export const selectPreviouslySentQuestions = (state: RootState) => {
  return rootSelector(state).category.previewModal.previouslySentQuestions;
};

export const selectActiveCategory = createSelector(
  [selectCategoryId, selectCategoryStore],
  (categoryId: number | undefined, categoryStore: {[key: string] : Category}) => {
    if(!categoryId)
      return undefined;
    return categoryStore[categoryId];
  });


export function selectCurrentCompetency(state: RootState): Category {
  const categoryId: number = state.category.categoryId || 0;
  return state.category.categoryStore[categoryId];
}


// export const selectGuide = (state: RootState) => {
//   console.log('yo',state)
// };
// export const getGuideId = (state: RootState) => selectGuide(state).code;
