import { ActionType, createAction } from 'typesafe-actions';

const toggle = createAction('@@chooseQuestion/TOGGLE', resolve => {
  return (questionId: number) => resolve({
    questionId
  });
});

const pushQuestions = createAction('@@chooseQuestion/PUSH_QUESTIONS', resolve => {
  return (questions: number[]) => resolve({
    questions
  });
});

const resetAll = createAction('@@chooseQuestion/RESET_ALL');

export const actions = {
  toggle,
  resetAll,
  pushQuestions
};

export type Actions =
  | ActionType<typeof toggle>
  | ActionType<typeof resetAll>
  | ActionType<typeof pushQuestions>
