import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { JnJGridRow, JnJGridColumn } from '@jaj/ui-components';
import { Typography } from "@material-ui/core";

const styles = theme => ({
  withBorder: {
    borderBottom: '1px solid #D8D8D8',
    padding: '23px 0 23px 20px',
    [theme.breakpoints.down('sm')]: {

    }
  },
  withoutBorder: {
    padding: '23px 20px',
    [theme.breakpoints.down('sm')]: {

    }
  },
  ratingScaleNumberDesktop: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 45,
    height: 45,
    borderRadius: 23,
    border: '2px solid #CC0099',
    marginTop: 19,
  },
  ratingScaleNumberMobile: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 30,
    height: 30,
    borderRadius: 15,
    border: '2px solid #CC0099',
  },
  displayDesktop: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  displayMobile: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  lineHeight: {
    lineHeight: '20px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10
    }
  },
  ratingScaleTextMargin: {
    marginLeft: 20,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  numberMobileWrapper: {
    [theme.breakpoints.down('sm')]: {
      marginLeft: 10,
      marginBottom: 10,
      display: 'flex',
      alignItems: 'center',
    }
  }
});

class RatingScaleItem extends React.Component {
  render() {
    const {title, ratingScaleItemNumber, hasBorder, classes} = this.props;
    return (
      <div className={ hasBorder === "true" ? classes.withBorder : classes.withoutBorder }>
        <JnJGridRow>
          <div className={classes.displayDesktop}>
            <JnJGridColumn xs={12} md={2}>
              <div className={classes.ratingScaleNumberDesktop}>
                <Typography
                  variant="h4"
                  color="secondary"
                >
                  {ratingScaleItemNumber}
                </Typography>
              </div>
            </JnJGridColumn>
          </div>
          <JnJGridColumn xs={12} md={10}>
            <div className={classes.ratingScaleTextMargin}>

              <div className={classes.numberMobileWrapper}>
                <div className={classes.displayMobile}>
                  <JnJGridColumn xs={12} md={2}>
                    <div className={classes.ratingScaleNumberMobile}>
                      <Typography
                        variant="h5"
                        color="secondary"
                      >
                        {ratingScaleItemNumber}
                      </Typography>
                    </div>
                  </JnJGridColumn>
                </div>
                <Typography variant="h5" className={classes.lineHeight}>{title}</Typography>
              </div>

              <Typography variant="body1" className={classes.lineHeight}>{this.props.children}</Typography>
            </div>
          </JnJGridColumn>
        </JnJGridRow>
      </div>
    );
  }
}

export default withStyles(styles)(RatingScaleItem);

