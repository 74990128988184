// Global module imports
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, createStyles } from "@material-ui/core";
import { Typography, JnJButton } from '@jaj/ui-components';

//store imports
import { actions as editCategoryActions } from '../../../store/editCategory';
import { selectIsWriter } from '../../../store/user/selector';
import { RootState } from "../../../store";

//component imports
import EntityTranslateFullAddEditForm from '../../../components/EntityTranslateFullAddEditForm/EntityTranslateFullAddEditForm';
import Container from '../../../components/Container/Container';
import { Loading } from '../../../components/Loading';

//other imports
import { COMPETENCY_ACTIONS } from '../../../components/Constants';
import DynamicContent from './DynamicContent';
import { WithStyles } from '@material-ui/core';
import { Category, CompetencyFullInformation } from '../../../store/editCategory/type';
import { FullEntityDataForm } from '../../../store/selectCategory/type';

const styles = () => createStyles({
  questionAddEditRoot: {
    backgroundColor: '#ffffff',
    padding: '50px 135px',
  },
  title: {
    marginBottom: 50,
  },
  actionButtons: {
    display: 'flex',
  },
  actionButtonCancle: {
    marginLeft: '20px',
  },
  error: {
    color: '#ca001b',
    padding: '10px 0',
  },
});

interface CompetencyAddEditProps extends WithStyles {
  isWriter: boolean,
  history: any,
  location: any,
  match: any,
  translateQuestions: {[key: string]: FullEntityDataForm},
  competencyFullInformation?: CompetencyFullInformation
  error?:string,
  savingResult?: boolean,
  loadingResult?: boolean,
  getCurrentFunctionalCompetency: Function,
  loadCompetencyFullInformation: Function,
  saveCompetencyFullInformation: Function,
}

interface ICompetencyAddEditState {
  error: string,
  saving: boolean,
  loading: boolean,
  currentFunctionalCompetency?: Category,
}

const mapStateToProps = (state: RootState) => ({
  isWriter: selectIsWriter(state),
  getCurrentFunctionalCompetency: () => {
    const categoryId: number = state.category.categoryId || 0;
    return state.category.categoryStore[categoryId];
  },  
  translateQuestions: state.form['translateQuestions'],
  error: state.editCategory.error,
  competencyFullInformation: state.editCategory.fullInformation,
  savingResult: state.editCategory.savingResult,
  loadingResult: state.editCategory.loading
});

const mapDispatchToProps = {
  loadCompetencyFullInformation: editCategoryActions.loadCompetencyFullInformation,
  saveCompetencyFullInformation: editCategoryActions.saveCompetencyFullInformation
};

class CompetencyAddEdit extends React.Component<CompetencyAddEditProps, ICompetencyAddEditState> {  
  state:ICompetencyAddEditState = {
    error: '',
    saving: false,
    loading: true
  }

  getIds = () => {
    const { action, id } = this.props.match.params;
    return {
      categoryId: action === COMPETENCY_ACTIONS.edit && id ? id : 0,
      functionalCompetencyId: action === COMPETENCY_ACTIONS.add && id ? id : 0
    };
  }
  
  componentDidMount() {
    if (!this.state.currentFunctionalCompetency) {
      this.setState({
        currentFunctionalCompetency: this.props.getCurrentFunctionalCompetency()
      }, () => {
        if(!this.props.isWriter || !this.state.currentFunctionalCompetency) {
          this.props.history.push('/');
        }
      });
    }

    const ids = this.getIds();

    if (ids.functionalCompetencyId || ids.categoryId) {
      this.props.loadCompetencyFullInformation(ids.functionalCompetencyId, ids.categoryId);      
    } else {
      this.props.history.push('/');
    }
  }

  componentWillReceiveProps(nextProps:CompetencyAddEditProps) {    
    if (nextProps.savingResult !== undefined && this.props.savingResult === undefined) {
      if (nextProps.savingResult || this.props.savingResult) {
        this.props.history.push('/interview');
      } else {
        this.setState({ 
          saving: false, 
          error: nextProps.error ? nextProps.error : 'Unknowing error during saving' 
        });
      }
    }
    else if (nextProps.loadingResult === false && this.props.loadingResult === true) {
      this.setState({loading:false});
    }
  }

  getTranslations() {
    const data = this.props.translateQuestions.values;
    return Object.keys(data).reduce((agr: Array<any>, languageCode: string) => {
      const {text, description} = data[languageCode];
      if (!!text && text !== '') {
        return [...agr, {
          languageCode,
          text,
          description
        }];
      }
      return agr;
    }, []);
  }

  getProbingQuestions() {
    const data = this.props.translateQuestions.values;
    return Object.keys(data).reduce((agr: Array<any>, languageCode: string) => {
      const {probingQuestions} = data[languageCode];
      if (!!probingQuestions && probingQuestions !== '') {
        return [...agr, {
          languageCode,
          text:probingQuestions,
          description:''
        }];
      }
      return agr;
    }, []);
  }

  getRatings() {
    const data = this.props.translateQuestions.values;    
    const formResultData = data.isCustomRatingsShown ? Object.keys(data).reduce((agr: Array<any>, languageCode: string) => {
      const {custom_rating1, custom_rating2, custom_rating3, custom_rating4} = data[languageCode];
      if ((!!custom_rating1 && custom_rating1 !== '') ||
          (!!custom_rating2 && custom_rating2 !== '') ||
          (!!custom_rating3 && custom_rating3 !== '') ||
          (!!custom_rating4 && custom_rating4 !== '')
       ) {
        return [...agr, {
          languageCode,
          ratings: [ {value:1, text:custom_rating1}, {value:2, text:custom_rating2}, {value:3, text:custom_rating3}, {value:4, text:custom_rating4}]
        }];
      }
      return agr;
    }, []) : [];
    
    if (formResultData && formResultData.length > 0) {
      const result = [1,2,3,4].map(ratingValue => {
        return {
          value: ratingValue,
          translations: formResultData.map(frmDataItem => {
            const ratItem = frmDataItem.ratings.find(_ => _.value == ratingValue);
            return ratItem && ratItem.text && ratItem.text.length > 0  ? {
              languageCode: frmDataItem.languageCode,
              text:frmDataItem.ratings.find(_ => _.value == ratingValue).text
            }: null;
          }).filter(_ => _ != null)
        }
      });
        
      return result;
    }
    return [];
  }

  onSave = () => {
    this.setState({
      error: '',
    });

    const ids = this.getIds();
    const translationItems = this.getTranslations();
    const probingQuestionItems = this.getProbingQuestions();
    const ratingsItems = this.getRatings();

    if (translationItems.length === 0) {
      this.setState({ error: 'Competency is required' });
      return;
    }
    const engTranslation = translationItems.find(val => val.languageCode === 'EN');
    if (!engTranslation) {
      this.setState({ error: 'English translation is obligatory' });
      return;
    }

    const ratWithoutEng = ratingsItems.filter(_ => _.translations && _.translations.length > 0 && !_.translations.find(val => val.languageCode === 'EN'))
    if (ratWithoutEng && ratWithoutEng.length > 0) {
      this.setState({ error: 'English translation is obligatory for rating numbers ' + ratWithoutEng.map(_ => _.value.toString()) });
      return;
    }
        
    if (ids.functionalCompetencyId || ids.categoryId) {
      this.setState({saving:true});
      this.props.saveCompetencyFullInformation(
        ids.functionalCompetencyId, 
        ids.categoryId, 
        this.state.currentFunctionalCompetency,
        this.props.competencyFullInformation, 
        translationItems, 
        probingQuestionItems,
        ratingsItems);
    } else {
      this.props.history.push('/');
    }
  };

  render() {
    const { match, classes } = this.props;
    const { action } = match.params;
    const error = this.state.error;

    if (this.state.loading || this.props.loadingResult === true || this.state.saving) {
      return <Loading />
    }
   
    return (
      <Container static className={classes.questionAddEditRoot}>
        <Typography
          variant="h4"
          color="secondary"
          className={classes.title}
        >
          { `${this.state.currentFunctionalCompetency && this.state.currentFunctionalCompetency.text}: ${DynamicContent[action].title}` }
        </Typography>
          <React.Fragment>
            <EntityTranslateFullAddEditForm
              entityText={'Competency'}
              showDescription={true}
              showProbingQuestions={this.state.currentFunctionalCompetency.ownsProbingQuestions }
              showRatings={this.state.currentFunctionalCompetency.ownsRatings }
              initialValues={this.props.competencyFullInformation.formValues}
            />
            {error && <div className={classes.error}>{error}</div>}
            <div className={classes.actionButtons}>
              <JnJButton
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={this.props.history.goBack}
              >
                Cancel
              </JnJButton>
              <JnJButton
                className={classes.actionButtonCancle}
                variant="contained"
                color="primary"
                onClick={this.onSave}
              >
                {DynamicContent[action].submitButton}
              </JnJButton>
            </div>
          </React.Fragment>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(CompetencyAddEdit)));
