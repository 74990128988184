import { axiosInstance } from './apiService';
import { IEntityTranslation } from '../models/questionModels';
import { IRating } from '../models/ratingModels';

export async function loadRatingWithTranslationsRequest(ratingId: number): Promise<IEntityTranslation[]> {
  const res = await axiosInstance.get(`/ratings/${ratingId}/translations`);
  return res.data;
}
export async function loadRatingsByCategory(languageCode = 'en', categoryId: number): Promise<IRating[]> {
  const res = await axiosInstance.get(`/ratings/${languageCode}/${categoryId}`);
  return res.data;
}

export async function saveRatingTranslationsRequest(
  ratingId: number,
  translationsToSave: IEntityTranslation[]): Promise<IEntityTranslation[]> {

  await axiosInstance.post(`/ratings/${ratingId}/translations`, translationsToSave);

  return translationsToSave;
}

export async function createRatingRequest(rating: IRating) {
  const response = await axiosInstance.put(`/ratings/${rating.languageCode}`, rating);
  return response.data;
}

export async function deleteRatingRequest(ratingId: number) {
  const response = await axiosInstance.delete(`/ratings/${ratingId}`);
  return response.data;
}
