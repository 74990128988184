import React, { Component } from "react"
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core";
import {
  IndividualPersonIcon,
  TwoPersonsIcon,
  ThreePersonsIcon,
  JnJGridColumn,
  JnJGridRow,
  Typography,
} from "@jaj/ui-components";
import withRouter from "react-router-dom/withRouter";
import { actions } from "../../store/selectCategory/action";
import * as languageActions from '../../store/language/action';
import Loading from "../../components/Loading/Loading";
import IconCard from "../../components/IconCard/IconCard";
import { onHistoryLinkClicked } from '../../services/historyLinksService';
import Container from '../../components/Container/Container';

const styles = theme => ({
  textItem: {
    lineHeight: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "20px",
      lineHeight: "25px",
    },
  },
  header: {
    padding: "0 0 20px 0",
    [theme.breakpoints.up("md")]: {
      padding: "20px 0"
    },
  },
  titlePrimary: {
    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
      lineHeight: "34px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: "40px",
    },
  },
  pink: {
    color: "#CC0099",
  },
  root: {
    backgroundColor: '#fff',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '50px',
      paddingBottom: '100px'
    },
  }
});


class SelectGuideType extends Component {
  constructor() {
    super(...arguments);

    this.icons = [
      <IndividualPersonIcon />,
      <TwoPersonsIcon />,
      <ThreePersonsIcon />,
    ];
    this.props.clearGuide();
    this.props.init();
    this.nextPage = '/interview/select-functional-competency';
  }

  componentDidUpdate() {
    // This is here for removing 'getSnapshotBeforeUpdate' warning message.
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.page !== prevProps.page) {
      this.goTo();
    }
    return null;
  }

  goTo() {
    this.props.history.push(this.nextPage);
  }

  next = (id, text, description) => {
    const label = text + description;
    return onHistoryLinkClicked({
      category: 'guideType',
      action: 'select',
      onClick: () => {
        this.props.select(id, label);
        this.props.setPagePath(this.nextPage);
        this.goTo()
      }
    })
  }

  renderContent() {
    const { initializing, categories, classes } = this.props;
    return initializing ? (
      <Loading />
    ) : (
        <JnJGridRow>
          {categories && categories.map(({ id, text, description }, index) => (
            <JnJGridColumn className="flex" xs={12} md={4} key={id}>
              <IconCard
                label={text}
                className="flex-grow"
                icon={this.icons[index]}
                onClick={() => {this.props.syncGuideLanguage('EN'); this.props.resetAllQuestion(); this.next(id, text, description); }}>
                <div>
                  <Typography variant="body1" className={classes.textItem}>{text}</Typography>
                  <Typography variant="body1" className={classes.textItem}>{description}</Typography>
                </div>
              </IconCard>
            </JnJGridColumn>
          ))}
        </JnJGridRow>
      );
  }

  render() {
    const { classes } = this.props;
    return (
      <Container static className={classes.root}>
        <JnJGridRow>
          <JnJGridColumn xs={12} lg={1} />
          <JnJGridColumn xs={12} lg={10}>
            <div className={classes.header}>
              <Typography variant="h5" className={classes.pink}>Awesome!</Typography>
              <Typography variant="h2" color="secondary" className={classes.titlePrimary}>
                Let’s get started! What type of position is this?
            </Typography>
            </div>
            {this.renderContent()}
          </JnJGridColumn>
        </JnJGridRow>
      </Container>
    )
  }
}


const mapStateToProps = ({ category }) => ({
  page: category.page,
  initializing: category.initializing,
  categories: category.categories.map(id => category.categoryStore[id]),
});

const mapDispatchToProps = {
  select: actions.selectGuideType,
  init: actions.init,
  clearGuide: actions.clearGuide,
  setPagePath: actions.setPagePath,
  // guideStep: actions.guideStep,
  syncGuideLanguage: languageActions.syncGuideLanguage,
  resetAllQuestion: actions.resetAllQuestion
}

export default withRouter(withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SelectGuideType)));
