import * as React from "react";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import { JnJGridRow, JnJGridColumn, CollapsedItem, LinkArrowIcon } from "@jaj/ui-components";
import {Typography, createStyles, Theme} from "@material-ui/core";
import classNames from "classnames";
import Container from "../../components/Container/Container";
import { colorPrimary } from "../../styles/colors";
import InterviewContent from './InterviewContent';
import Tabs, { ITabOption } from "../../components/Tabs/Tabs";
import InterviewStyle from './InterviewStyle';
import InterviewingBasics from './InterviewingBasics';
import ChoosingYourTeam from './ChoosingYourTeam';
import ScoringTheInterview from './ScoringTheInterview';
import {ARTICLE_TAB_BASICS, ARTICLE_TAB_STYLES, ARTICLE_TAB_TEAM, ARTICLE_TAB_CONTENT, ARTICLE_TAB_SCORING} from './articleConstants';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: "#fff",
    padding: "20px",
    [theme.breakpoints.up("md")]: {
      padding: "70px 0 16px 0",
    },
  },
  hidden: {
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  },
  justifyCenter: {
    justifyContent: "center",
  },
  image: {
    display: "block",
    width: "100%",
    backgroundImage: `url(${process.env.PUBLIC_URL}/images/great-interview.png)`,
    backgroundSize: "cover",
    minHeight: "217px",
    [theme.breakpoints.up("md")]: {
      minHeight: "315px",
    },
  },
  imageContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0 !important",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "0 !important",
    },
  },
  text: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: "white",
    backgroundColor: colorPrimary,
    padding: "30px !important",
    [theme.breakpoints.up("md")]: {
      padding: "50px !important",
    },
    width: '100%'
  },
  textContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0 !important",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "0 !important",
    },
  },
  desktopContent: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobileContent: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  trainingLink: {
    display: 'flex',
    width: 470,
    padding: 35,
    cursor: 'pointer',
    [theme.breakpoints.down("sm")]: {
      width: 270,
      alignItems: 'flex-end',
      flexDirection: 'column',
      padding: '27px 20px 20px',
      height: '50%',
      justifyContent: 'space-between'
    },
  },
  trainingImage: {
    [theme.breakpoints.down("sm")]: {
      width: 270
    },
  },
  linkDescription: {
    fontWeight: 900
  },
  trainingContainer: {
    padding: '64px 20px',
    backgroundColor: "#fafafa",
  },
  heading: {
    textAlign: 'center'
  },
  divider: {
    height: 2,
    width: 50,
    backgroundColor: '#000099',
    margin: '26px auto 52px'
  },
  trainingImageContainer: {
    backgroundColor: "#fff",
    padding: 0,
    '&:first-child': {
      marginRight: 35,
      [theme.breakpoints.down("sm")]: {
        width: 270,
        marginRight: 20
      },
    }
  },
  trainingLinks: {
    overflowX: "auto",
    overflowY: "hidden",
    justifyContent: 'flex-start',
      [theme.breakpoints.up("md")]: {
      overflowX: "hidden",
      justifyContent: 'center'
    },
  },
  typographyTitle: {
    letterSpacing: "-0.5px",
    marginBottom: 30,
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
      lineHeight: '34px',
      marginBottom: 20
    }
  },
  mainText: {
    color: '#212121',
    fontSize: 20,
    fontWeight: 300,
    lineHeight: '30px',
    marginBottom: 35,
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
      lineHeight: '26px',
      marginBottom: 20
    }
  },
  redSectionTitle: {
    lineHeight: '30px',
    marginBottom: 20,
  },
});

export interface HomePageArticleProps extends WithStyles {
};

export interface HomePageArticleState {
  tab: string;
  expandedItems: {
    [key: string]: boolean
  };
}

class HomePageArticle extends React.PureComponent<HomePageArticleProps, HomePageArticleState> {
  state: HomePageArticleState = {
    tab: ARTICLE_TAB_BASICS,
    expandedItems: {
      [ARTICLE_TAB_BASICS]: false,
      [ARTICLE_TAB_STYLES]: false,
      [ARTICLE_TAB_TEAM]: false,
      [ARTICLE_TAB_CONTENT]: false,
      [ARTICLE_TAB_SCORING]: false
    }
  };

  links: string[] = [
    //'https://urldefense.com/v3/__https:/jnj.csod.com/LMS/LoDetails/DetailsLo.aspx?loid=613bc99d-4a33-4d7e-abff-fa422d22874a__;!K6dmGCEab4ueJg!jpW2sQy7kLDdTaxyKlsZNJkSnVML2mdNXHQwnjoF8fTHzfZGUrgwdWHixC4PqzWa4yI$',
    'https://jnj.csod.com/LMS/LoDetails/DetailsLo.aspx?loid=86dc767b-f364-48f3-b0c6-8419ff182d42',
    'https://jnj.csod.com/LMS/LoDetails/DetailsLo.aspx?loid=c80f7908-0402-473f-b1ba-3c3296be36ac&query=%3fq%3dHiring+and+Promoting+Top+Talent+Inclusively#t=1',
  ];

  getTabOptions(): ITabOption[] {
    return [
      {
        title: "Interviewing Basics",
        value: ARTICLE_TAB_BASICS,
      },
      {
        title: "Interview Styles",
        value: ARTICLE_TAB_STYLES,
      },
      {
        title: "Choosing your team",
        value: ARTICLE_TAB_TEAM,
      },
      {
        title: "Interview content",
        value: ARTICLE_TAB_CONTENT,
      },
      {
        title: "Scoring the interview",
        value: ARTICLE_TAB_SCORING,
      },
    ];
  }

  getTabMap(): {[key: string]: () => React.ReactNode} {
    return {
      basics: () => this.renderBasics(),
      styles: () => this.renderStyles(),
      team: () => this.renderTeam(),
      content: () => this.renderContent(),
      scoring: () => this.renderScoring(),
    };
  }

  onChangeTab = (tab: string, scrollToTop: boolean) => {
    this.setState({
      tab,
      expandedItems: {
        ...this.state.expandedItems,
        [tab]: true
      }
    });
    if (scrollToTop) {
      window.scrollTo(0, 0);
    }
  };

  renderBasics() {
    return (
      <InterviewingBasics onNext={() => this.onChangeTab(ARTICLE_TAB_STYLES, true)}/>
    );
  }

  renderStyles() {
    return (<InterviewStyle onNext={() => this.onChangeTab(ARTICLE_TAB_TEAM, true)}/>);
  }

  renderTeam() {
    return (
      <ChoosingYourTeam onChangeTab={this.onChangeTab}/>
    );
  }

  renderContent() {
    return (<InterviewContent onNext={() => this.onChangeTab(ARTICLE_TAB_SCORING, true)}/>);
  }

  renderScoring() {
    return (
      <ScoringTheInterview/>
    );
  }

  openLink(index: number) {
    window.open(this.links[index], '_blank')
  }

  toggle = (value: string) => {
    this.setState(prevState => ({
      expandedItems: {
        ...prevState.expandedItems,
        [value]: !prevState.expandedItems[value]
      }
    }));
  };

  render() {
    const {classes} = this.props;
    const tabOptions = this.getTabOptions();
    const tabMap = this.getTabMap();

    return(
      <React.Fragment>
        <Container className={classes.root} static>
          <JnJGridRow className={classes.justifyCenter}>
            <JnJGridColumn xs={12} md={10}>
              <Typography
                variant="h1"
                color="secondary"
                className={classes.typographyTitle}
              >
                Great interviews lead to great hires
              </Typography>
              <p className={classes.mainText}>
                It’s one of the most important decisions you’ll make at Johnson & Johnson:
                who to hire. And it’s not as easy as it looks. Even experienced interviewers
                can use some help getting the best responses from candidates—and making the best hiring decisions.
              </p>
            </JnJGridColumn>
          </JnJGridRow>
          <JnJGridRow className={classNames(classes.justifyCenter, "margin-bottom-40")}>
            <JnJGridColumn xs={12} md={5} className={classes.imageContainer}>
              <div className={classes.image} />
            </JnJGridColumn>
            <JnJGridColumn xs={12} md={5} className={classes.textContainer}>
              <div className={classes.text}>
                <Typography
                  variant="h4"
                  color="inherit"
                  className={classes.redSectionTitle}
                >
                  Ready to help us add some new members to the Johnson & Johnson family?
                </Typography>
                <Typography
                  color="inherit"
                >
                  We can help you brush up on the basics, choose the right interview format,
                  line up a great interview team, and create an Interview Guide filled with
                  questions that will help you make a confident, unbiased decision.
                </Typography>
              </div>
            </JnJGridColumn>
          </JnJGridRow>
          <div className={classes.desktopContent}>
            <JnJGridRow className={classes.justifyCenter}>
              <JnJGridColumn xs={12} md={10}>
                <Tabs
                  options={tabOptions}
                  value={this.state.tab}
                  onChange={this.onChangeTab}
                />
              </JnJGridColumn>
            </JnJGridRow>
             {tabMap[this.state.tab]()}
          </div>
          <div className={classes.mobileContent}>
            <JnJGridRow className={classes.justifyCenter}>
              <JnJGridColumn xs={12} md={10}>
                <hr/>
                {tabOptions.map((option) => {
                  return (
                    <CollapsedItem
                      key={option.value}
                      title={option.title}
                      size="small"
                      onClick={() => this.toggle(option.value)}
                      expanded={this.state.expandedItems[option.value]}
                    >
                      {tabMap[option.value]()}
                    </CollapsedItem>
                  );
                })}
              </JnJGridColumn>
            </JnJGridRow>
          </div>
        </Container>
        <Container static className={classes.trainingContainer}>
          <Typography
            variant="h3"
            color="secondary"
            className={classes.heading}
          >
            Training & Guidance
          </Typography>
          <div className={classes.divider} />
          <JnJGridRow className={classes.justifyCenter}>
            <JnJGridColumn xs={12} md={10} className="flex justify-center">
              <div className={classNames("flex", classes.trainingLinks)}>
                <div className={classes.trainingImageContainer}>
                  <img
                    className={classes.trainingImage}
                    src={`${process.env.PUBLIC_URL}/images/Training1.png`}
                    alt="Training1"
                  />
                  <div className={classes.trainingLink} onClick={() => this.openLink(0)}>
                    <Typography className={classes.linkDescription}>
                      Our global interview and training certification: Interview with Purpose
                    </Typography>
                    <LinkArrowIcon/>
                  </div>
                  {/* <div className={classes.trainingLink} onClick={() => this.openLink(2)}>
                    <Typography className={classes.linkDescription}>
                      Interview with Purpose Training and Certification Program
                    </Typography>
                    <LinkArrowIcon/>
                  </div> */}
                </div>
                <div className={classes.trainingImageContainer}>
                  <img
                    className={classes.trainingImage}
                    src={`${process.env.PUBLIC_URL}/images/Training2.png`}
                    alt="Training2"
                  />
                  <div className={classes.trainingLink} onClick={() => this.openLink(1)}>
                    <Typography className={classes.linkDescription}>
                      How you and your team can avoid bias during interviews
                    </Typography>
                    <LinkArrowIcon/>
                  </div>
                </div>
              </div>
            </JnJGridColumn>
          </JnJGridRow>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(HomePageArticle);
