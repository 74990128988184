import * as React from 'react';
import { connect } from 'react-redux';
import EditTranslationsForm from './EditForm/EditTranslationsForm';
import Loading from '../../components/Loading/Loading';
import { actions as selectCategoryActions } from "../../store/selectCategory";
import { actions as editCategoryActions } from "../../store/editCategory";
import { CategoryDTO } from "../../store/selectCategory/type";

interface EditCompetencyPageProps {
  translations: any,
  loading?: boolean,
  languages?: any,
  languagesLoading?: boolean,
  category: any,
  editGroup: any,
  selectCategories?: CategoryDTO[],
  isSelectCategoriesLoaded: Function,
  isEditCategoriesLoaded: Function,
  fetchTranslations:Function,
  init:Function,
  selectGuideType:Function,
  fetchGuideTypes: Function,
  saveCategoryWithTranslations:Function,
  selectCompetency: Function,
  selectDuration: Function,
  deleteCompetency: Function
}

interface EditCompetencyPageState {
  guideType?:number,
  open: boolean,
  loadingGuideTypes: boolean,
  loadingTranslations: boolean,
  saving: boolean
}

class EditCompetency extends React.Component<EditCompetencyPageProps, EditCompetencyPageState> {

  constructor(props){
    super(props);
    this.state = {          
      open: false,
      loadingGuideTypes: false,
      loadingTranslations: false,
      saving:false
    }
  }

  componentDidMount() {
    const guideType = this.props.match.params.guideType;    
    if (guideType && this.state.guideType != guideType) {
      const isInit = this.props.selectCategories && this.props.selectCategories.length;
      if (!isInit) {
        this.props.init();
        this.setState({ guideType: guideType });
      }
      else if (this.props.isEditCategoriesLoaded() || this.props.isSelectCategoriesLoaded()) {
        this.props.fetchTranslations(this.props.match.params.id);
        this.setState({ guideType: guideType, loadingGuideTypes: true, loadingTranslations: true });
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectCategories && nextProps.selectCategories.length > 0) {      
      if (!nextProps.isSelectCategoriesLoaded() && this.state.guideType && !this.state.loadingGuideTypes) {
        this.setState({ loadingGuideTypes: true });
        nextProps.selectGuideType(this.state.guideType);
      }else if (!nextProps.isEditCategoriesLoaded() && !this.state.loadingTranslations) {
        this.setState({ loadingTranslations: true });
        nextProps.fetchTranslations(this.props.match.params.id);
      }
    }
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = (isPositiveAnswer) => {
    this.setState({ open: false });
    if (isPositiveAnswer) {
      const catId = this.props.match.params.id;
      const category = this.props.category.categoryStore[catId];    
        this.props.deleteCompetency(catId, category).then(_ => {
        this.props.history.push('/interview');
      });
    }
  };

  handleSubmit = () =>{    
    const translations = this.props.editGroup.values.translation;
    const categoryId = this.props.match.params.id;
    if (translations)
    {      
      this.setState({saving:true});
      this.props.saveCategoryWithTranslations(categoryId, translations).then(_ => {
        this.props.history.push('/interview');
      });
    }
  };

  render() {
    const {
      translations,
      loading,
      languages,
      languagesLoading,
      category
    } = this.props;
  
    if (loading || languagesLoading || this.state.saving) {
      return (
        <Loading />
      );
    }
    const categoryId = this.props.match.params.id;
    const currentCategory = category.categoryStore[categoryId];
    
    return currentCategory ? (
      <EditTranslationsForm
        initialValues={{
          translation: translations.sort((a, b) => {            
            return a.languageCode === 'EN' ? -1 : 0;
          })
        }}
        languages={languages}
        history={this.props.history}
        categoryId={categoryId}
        category={category}
        handleSubmit={this.handleSubmit}
        openModal={this.handleClickOpen}
        closeModal={this.handleClose}
        open={this.state.open}
      />
    ) : null;
  }
}

const mapStateToProps = ({ editCategory, language, form, category }) => ({
  translations: editCategory.category,
  loading: editCategory.loading || category.initializing,
  languages: language.languages,
  languagesLoading: language.loading,
  category: category,
  editGroup: form.editGroup,
  selectCategories: category.categories.map(id => category.categoryStore[id]),
  isSelectCategoriesLoaded: () => category.categoryStore && Object.keys(category.categoryStore).length > 10,
  isEditCategoriesLoaded: () => editCategory.category && editCategory.category.length > 0
});

const mapDispatchToProps = {
  fetchTranslations: editCategoryActions.fetchFunctionalTranslation.request,
  deleteCompetency: editCategoryActions.deleteCategory,
  saveCategoryWithTranslations: editCategoryActions.saveCategoryWithTranslations,
  selectGuideType: selectCategoryActions.selectGuideType,
  selectCompetency: selectCategoryActions.selectCompetency,
  selectDuration: selectCategoryActions.selectDuration,
  init: selectCategoryActions.init
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCompetency);
