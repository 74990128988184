import {
  call,
  put,
  all,
  fork,
  takeEvery,
  takeLatest,
  take,
  select } from 'redux-saga/effects';
import {
  loadGuideTypes,
  loadCategories,
  loadQuestions,
} from "../../services/categoryService";

import {Actions, actions, DOWNLOAD_SHARED_GUIDE} from './action';
import {CategoryID, ReportDocRequestDTO} from './type';
import { getType } from 'typesafe-actions';
import * as languageActions from '../language/action';
import {getLanguageCode, getModalLanguageCode} from '../language/selector';
import {
  downloadReport,
  downloadSharedGuide,
  getGuideReport,
  loadGuide,
  loadReportPreview
} from "../../services/reportService";
import { saveQuestion as saveQuestionAction } from '../addEditQuestion/addEditQuestionActions';
import {
  selectGuideType,
  selectReportDocRequest,
  selectGuide,
  selectGuideViewStatus,
  selectModalViewStatus
} from './selectors';
import {getDraftGuides, getPublishedGuides, createGuide, updateGuide, deleteUserGuide} from "../../services/guideService";
import {guideStatus, guideViewStatus} from "../../models/guideModel";

function* fetchGuideType() {
  try {
    yield put(actions.fetchGuideTypeAction.request());
    const code = yield getLanguage(true);
    const res = yield call(loadGuideTypes, code);
    yield put(actions.fetchGuideTypeAction.success(res));
  }
  catch(error) {
    yield put(actions.fetchGuideTypeAction.failure('Error Load'));
  }
}

function* fetchCategories(guideType: CategoryID) {
  try {
    yield put(actions.fetchCategoriesAction.request());
    const code = yield getLanguage(true);
    const res = yield call(loadCategories, guideType, code);
    yield put(actions.fetchCategoriesAction.success(res));
  }
  catch(error) {
    yield put(actions.fetchCategoriesAction.failure('Error Load'));
  }
}

function* fetchQuestion(guideType: CategoryID) {
  try {
    yield put(actions.fetchQuestionsAction.request());
    const code = yield getLanguage(true);
    const res = yield call(loadQuestions, guideType, code);
    yield put(actions.fetchQuestionsAction.success(res));
  }
  catch(error) {
    yield put(actions.fetchQuestionsAction.failure('Error Load'));
  }
}

function* getLanguage(isGlobal: boolean) {
  let language: string;
  if (isGlobal) {
    language = yield select(getLanguageCode);
  } else {
    language = yield select(getModalLanguageCode);
  }
  return language;
}

function* fetchGuideDoc() {
  try{
    let res: ReportDocRequestDTO;
    const guide = yield select(selectGuide);
    const code = yield getLanguage(false);
    const viewStatus = yield select (selectModalViewStatus);
    yield put(actions.fetchPreviewDocAction.request(''));

    if (viewStatus === guideStatus.published) {
      res = yield call(loadGuide, guide.id, code);
    } else {
      res = yield call(loadReportPreview, guide, code);
    }

    yield put(actions.fetchPreviewDocAction.success(res));
  }
  catch (e) {
    yield put(actions.fetchPreviewDocAction.failure('Error Load'));
  }
}

function* saveGuideAsDraft() {
  try {
    yield put(actions.saveGuideAsDraft.request());
    const languageCode = yield getLanguage(true);
    let guide;
    const viewStatus = yield select(selectGuideViewStatus);
    let res: ReportDocRequestDTO;
    guide = yield select(selectGuide);
    if (viewStatus === guideViewStatus.edit || (viewStatus === guideViewStatus.copy && guide.id)) {
      res = yield call(updateGuide, {...guide, languageCode});
    } else {
      guide = yield select(selectReportDocRequest);
      res = yield call(createGuide, {...guide, languageCode});
    }
    yield put(actions.saveGuideAsDraft.success(res));
  }
  catch (e) {
    yield put(actions.saveGuideAsDraft.failure("Error Load"))
  }
}

function* downloadGuide() {
  try {
    const guide = yield select(selectGuide);
    const code = yield getLanguage(false);
    const type = 'docx';
    const res = yield call(downloadReport, guide, type, code);
    yield put(actions.downloadGuideAsync.success(res));
    yield put(actions.closePreviewModal());
  }
  catch (e) {
    yield put(actions.downloadGuideAsync.failure('Error Load'))
  }
}

function* fetchGuide(action: any) {
  try {
    const code = yield getLanguage(false);
    const type = 'docx';
    const res = yield call(getGuideReport, action.payload, type, code);
    yield put(actions.fetchGuideAsync.success(res));
    yield put(actions.closePreviewModal());
  }
  catch (e) {
    yield put(actions.fetchGuideAsync.failure('Error Load'))
  }
}

function* fetchSharedGuide(action: any) { //TODO : need to change type
  try {
    const type = 'docx';
    const res = yield call(downloadSharedGuide, action.payload, type);
    yield put(actions.downloadSharedGuideAsync.success(res));
  }
  catch {
    yield put(actions.downloadSharedGuideAsync.failure('Error Load'))
  }
}

function* publishGuide() {
  try {
    yield put(actions.publishGuideAsync.request());
    const languageCode = yield getLanguage(false);
    const guide = yield select(selectGuide);
    let res;
    if (guide.id) {
      res = yield call(updateGuide, {...guide, languageCode});
    } else {
      res = yield call(createGuide, {...guide, languageCode});
    }
    yield put(actions.publishGuideAsync.success(res));
    yield put(actions.downloadGuideAsync.request({...guide, languageCode}));
  }
  catch (e) {
    yield put(actions.publishGuideAsync.failure("Error Load"))
  }
}

function* fetchDrafts() {
  try{
    yield put(actions.fetchDraftsList.request());
    const code = yield getLanguage(true);
    const drafts = yield call(getDraftGuides, code);
    yield put(actions.fetchDraftsList.success(drafts));
  }
  catch {
    yield put(actions.fetchDraftsList.failure('Error Load'));
  }
}

function* fetchPublished() {
  try{
    yield put(actions.fetchPublishedList.request());
    const code = yield getLanguage(true);
    const published = yield call(getPublishedGuides, code);
    yield put(actions.fetchPublishedList.success(published));
  }
  catch {
    yield put(actions.fetchPublishedList.failure('Error Load'));
  }
}

let HIDE_FC_LIST_IDS = [0];
if (process.env.NODE_ENV === "production") {
  HIDE_FC_LIST_IDS = [3082, 3198, 3315, 3056, 3061, 3159, 3164, 3169, 3175, 3041, 3131, 3287, 3088, 3095, 3207];
} else {
  //development
  HIDE_FC_LIST_IDS = [3082, 3198, 3315, 3056, 3061, 3159, 3164, 3169, 3175, 3041, 3131, 3287, 3088, 3095, 3207];
}

function* fetchGuides() {
  try {
    yield put(actions.fetchGuideList.request());
    const code = yield getLanguage(true);
    const [published, drafts] = yield all([
      call(getPublishedGuides, code),
      call(getDraftGuides, code)
    ]);
    /*
    let filterPublished = published.filter((x: { functionCategoryId: number; }) => 
      !HIDE_FC_LIST_IDS.includes(x.functionCategoryId));
    let filterDrafts = drafts.filter((x: { functionCategoryId: number; }) => 
      !HIDE_FC_LIST_IDS.includes(x.functionCategoryId));
    */
    yield put(actions.fetchGuideList.success({published, drafts}))
    /*
    yield put(actions.fetchGuideList.success({
      published: filterPublished, 
      drafts: filterDrafts}))
    */
  }
  catch {
    yield put(actions.fetchGuideList.failure('Error Load'));
  }
}


function* fetchGuideToRemove() {
  try{
    const guide = yield select(selectGuide);
    const guideId = guide.id;
    yield put(actions.deleteGuideAsync.request(guideId));
    yield call(deleteUserGuide, guideId);
    yield put(actions.deleteGuideAsync.success(guideId));
  }
  catch (e) {
    yield put(actions.deleteGuideAsync.failure('Error Load'));
  }
}

//Watchers

function* watchFetchQuestion() {
  yield takeLatest([
    getType(actions.init),
    getType(languageActions.select),
    getType(actions.editGuide),
    getType(actions.copyGuide)
  ],
  function* () {
    const guideType = yield select(selectGuideType);
    if (guideType) {
      yield all([
        fork(fetchQuestion, guideType),
        fork(fetchCategories, guideType)
      ]);
    }
  });
}

function* watchRefreshQuestions() {
  yield takeLatest([getType(saveQuestionAction.success)],
      function* (){
        const guideType = yield select(selectGuideType);
        if (guideType) {
          yield fork(fetchQuestion, guideType)
        }
      }
    );
}

function* watchGuide() {
  yield takeLatest([getType(actions.saveDraft), getType(actions.copyGuide)], saveGuideAsDraft);
  yield takeLatest([getType(actions.publishGuide)], publishGuide);
  yield takeLatest([getType(actions.fetchGuides)], fetchGuides);
  yield takeLatest([getType(actions.fetchDrafts)], fetchDrafts);
  yield takeLatest([getType(actions.fetchPublished)], fetchPublished);
  yield takeLatest([getType(actions.downloadGuideAsync.request)], downloadGuide);
  yield takeLatest([getType(actions.fetchGuideAsync.request)], fetchGuide);
  yield takeLatest([getType(actions.deleteGuide)], fetchGuideToRemove);
  yield takeLatest([getType(actions.downloadSharedGuideAsync.request)], fetchSharedGuide);
}

function* watchBaseActions() {
  yield takeEvery([
    getType(actions.init),
    getType(actions.resetAll),
    getType(actions.fetchGuideTypes),
  ], fetchGuideType);
}

function* watchOpenPreviewModal() {
  yield takeLatest([getType(actions.openPreviewModal), getType(actions.previewGuideModal), getType(languageActions.changeModalLanguage)], fetchGuideDoc);
}

export function* saga() {
  yield all([
    fork(watchBaseActions),
    fork(watchFetchQuestion),
    fork(watchOpenPreviewModal),
    fork(watchRefreshQuestions),
    fork(watchGuide),
  ]);
}
