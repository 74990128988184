
import ReactAI from 'react-appinsights';

export function subscribeGlobalErrorHandle() {
    window.onerror = function(message, url, line, col, error) {
        // Note that col & error are new to the HTML 5 spec and may not be 
        // supported in every browser.  It worked for me in Chrome.
        const extra = (!col ? '' : 'column: ' + col) + (!line ? '' : ';line: ' + line);
        
        ReactAI.ai().trackException(error as Error, undefined, {
            message: message as string, 
            extra: extra
        });

        // If you return true, then error alerts (like in older versions of  Internet Explorer) will be suppressed.
        return false;
    };
}