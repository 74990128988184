import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { ModalCloseIcon, JnJButton } from '@jaj/ui-components';
import { Link } from 'react-router-dom';

const styles = theme => ({
  infoElement: {
    width: '25px',
    height: '25px',
    display: 'flex',
    color: '#ffffff',
    cursor: 'pointer',
    marginLeft: '10px',
    borderRadius: '50%',
    backgroundColor: '#CA001B',
    justifyContent: 'space-around',
  },
  infoIcon: {
    textTransform: 'lowercase',
  },
  arrow: {
    position: 'absolute',
    fontSize: '26px',
    '&::before': {
      width: 0,
      height: 0,
      content: '""',
      margin: 'auto',
      display: 'block',
      borderStyle: 'solid',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  htmlPopper: {
    opacity: 1,
    pointerEvents: 'all',
    top: '60px !important',
    left: '25px !important',
    '&[x-placement*="right"] $arrow': {
      left: 0,
      top: '82px !important',
      width: '0em',
      height: '3em',
      marginLeft: '-1.1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent #EAEBEC transparent transparent`,
      },
    },
    [theme.breakpoints.down('xs')]: {
      top: '50% !important',
      left: '50% !important',
      transform: 'translate(-50%) !important',
      '&::before': {
        borderWidth: '1em 1em 1em 1em',
        borderColor: `transparent transparent #EAEBEC transparent`,
      }
    },
  },
  htmlTooltip: {
    minWidth: 450,
    fontSize: '14px',
    backgroundColor: '#ffff',
    color: 'rgba(0, 0, 0, 0.87)',
    border: '3px solid #EAEBEC',
    '& b': {
      fontWeight: theme.typography.fontWeightMedium,
    },
    [theme.breakpoints.down('md')]: {
      minWidth: '340px',
    }
  },
  poperTitle: {
    fontWeight: 900,
    fontSize: '28px',
    lineHeight: '32px',
  },
  poperText: {
    display: 'flex',
    minHeight: '330px',
    lineHeight: '24px',
    padding: '15px 30px 45px 30px',
    flexDirection: 'column',
    justifyContent: 'space-evenly'
  },
  closeIcon: {
    display: 'flex',
    marginLeft: 'auto',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  tooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    minHeight: '210px'
  }
});

class ArrowTooltips extends React.Component {
  state = {
    arrowRef: null,
    openModal: true,
    mobile: false,
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }

  resize() {
    if (window.innerWidth <= 960) {
      return this.setState({ mobile: true });
    };
    return this.setState({ mobile: false });
  }

  handleArrowRef = node => {
    this.setState({
      arrowRef: node,
    });
  };

  onCloseDialog = (e) => {
    this.setState({
      arrowRef: null,
      openModal: false,
    });
  }

  onOpenDialog = (e) => {
    this.setState({
      arrowRef: null,
      openModal: true,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.infoElement} onMouseEnter={this.onOpenDialog} onMouseLeave={this.onCloseDialog} >
        <Tooltip
          arrow={!this.state.mobile}
          placement={this.state.mobile ? "" : "right"}
          open={this.state.openModal}
          classes={{
            popper: classes.htmlPopper,
            tooltip: classes.htmlTooltip,
          }}
          PopperProps={{
            popperOptions: {
              modifiers: {
                arrow: {
                  enabled: Boolean(this.state.arrowRef),
                  element: this.state.arrowRef,
                },
              },
            },
          }}
          disableFocusListener
          disableTouchListener
          title={
            <div className={classes.poperText}>
              <JnJButton
                onClick={this.onCloseDialog}
                className={classes.closeIcon}
              >
                <ModalCloseIcon />
              </JnJButton>
              <div className={classes.tooltipContent}>
                <p className={classes.poperTitle}>Position Type</p>
                <p>Each position level within a function has a unique set of questions so that interviewers
                ask questions appropriate to the skill and experience level of the candidate. </p>
                <br></br>
                <p> To view questions associated to a different level position, you will need to <Link to='/interview/select-guide-type'>create a new interview guide. </Link></p>
              </div>
              <span className={classes.arrow} ref={this.handleArrowRef} />
            </div>
          }
        >
          <span className={classes.infoIcon}>i</span>
        </Tooltip>
      </div>
    );
  }
}

ArrowTooltips.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ArrowTooltips);
