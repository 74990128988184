import { ActionType, createAsyncAction } from 'typesafe-actions';
import { Category, CompetencyFullInformation } from './type';
import {
  saveFunctionalCompetency,
  deleteFunctionalCompetency,
  saveFunctionalCompetencyWithTranslations,
  loadFunctionalCompetencyTranslation
} from "../../services/categoryService";
import { createPromisedAction } from "../../services/actionService";
import { CategoryID } from '../selectCategory/type';
import { saveQuestionWithTranslationsRequest, deleteQuestionRequest } from "../../services/question";
import { IEntityTranslation, IQuestionWithTranslations } from '../../models/questionModels';
import { IRatingWithTranslations } from '../../models/ratingModels';
import * as addEditQuestionActions from '../../store/addEditQuestion/addEditQuestionActions';
import * as addEditRatingActions from '../../store/addEditRating/addEditRatingActions';

const fetchFunctionalTranslation = createAsyncAction(
  '@@editCategory/FETCH_CATEGORY_TRANSLATION_REQUEST',
  '@@editCategory/FETCH_CATEGORY_TRANSLATION_SUCCESS',
  '@@editCategory/FETCH_CATEGORY_TRANSLATION_FAIL',
)<number, Category[], string>();

const SAVE_CATEGORY = "SAVE_CATEGORY";
const SAVE_CATEGORY_WITH_TRANSLATIONS = "SAVE_CATEGORY_WITH_TRANSLATIONS";
const DELETE_CATEGORY = "DELETE_CATEGORY";
const LOAD_CATEGORY_TRANSLATIONS = "LOAD_CATEGORY_TRANSLATIONS";
const LOAD_CATEGORY_FULL = "LOAD_CATEGORY_FULL";
const SAVE_CATEGORY_FULL = "SAVE_CATEGORY_FULL";

const saveCategory = (category: Category) => {
  return createPromisedAction(saveFunctionalCompetency(undefined, category), SAVE_CATEGORY);
}

const saveCategoryWithTranslations = (categoryId: CategoryID, translations: Array<IEntityTranslation>) => {
  return createPromisedAction(saveFunctionalCompetencyWithTranslations(categoryId, translations), SAVE_CATEGORY_WITH_TRANSLATIONS);
}

const loadCategoryTranslations = (categoryId: CategoryID) => {
  return createPromisedAction(loadFunctionalCompetencyTranslation(categoryId), LOAD_CATEGORY_TRANSLATIONS);
}

const deleteCategory = (categoryId: CategoryID, category: Category) => {
  return createPromisedAction(deleteFunctionalCompetency(categoryId, category), DELETE_CATEGORY);
}

const loadCompetencyFullInformation = (functionalCompetencyId:number, categoryId:number) => {
  return createPromisedAction(loadCompetencyFullInformationPromise(functionalCompetencyId, categoryId), LOAD_CATEGORY_FULL);
}

const loadCompetencyFullInformationPromise = (functionalCompetencyId:number, categoryId:number):Promise<CompetencyFullInformation> => {
  if(categoryId) {
    return loadFunctionalCompetencyTranslation(categoryId).then(translations => {

      const pqPromise =  addEditQuestionActions.loadProbingQuestionPromise(categoryId);
      const ratPromise = addEditRatingActions.loadRatingsPromise(categoryId);
      
      return Promise.all([pqPromise, ratPromise]).then(values => {
        return buildCompetencyFullInformation(translations, values[0], values[1]);
      });
    });
  } else if(functionalCompetencyId) {
    return new Promise<CompetencyFullInformation>((resolve) => {
      resolve(buildCompetencyFullInformation([], undefined, undefined));
    });
  }
  else {
    return new Promise<CompetencyFullInformation>((resolve) => {
      resolve(undefined);
    });
  }
}

const buildCompetencyFullInformation = (translations:IEntityTranslation[], probingQuestion?: IQuestionWithTranslations, ratings?: IRatingWithTranslations[]):CompetencyFullInformation => {
  let initValues = translations.reduce((agr, item) => ({...agr, [item.languageCode]: item}), {}) as any;
  if (probingQuestion && probingQuestion.translations) {
    probingQuestion.translations.forEach(question => {
      initValues[question.languageCode].probingQuestions = question.description && question.description.length > 0 ? question.description : question.text
    });
  }
  if (ratings && ratings.length > 0) {
    ratings.forEach(rating => {
      if (rating && rating.translations) {
        rating.translations.forEach(translation => {
          if (initValues[translation.languageCode]) {
            initValues[translation.languageCode]["custom_rating" + rating.value] = translation.text
          }
        });
      }
    });
  }

  return {
    translations, 
    probingQuestion,
    ratings,
    formValues:initValues,
  };
}

const saveCompetencyFullInformation = (functionalCompetencyId:number, categoryId:number, functionalCompetency:Category, 
  currentCompetencyData: CompetencyFullInformation,
  translations:IEntityTranslation[], probingQuestionItems?: IEntityTranslation[], ratings?: IRatingWithTranslations[]) => {
  return createPromisedAction(saveCompetencyFullInformationPromise(functionalCompetencyId, categoryId, functionalCompetency, 
    currentCompetencyData, translations, probingQuestionItems, ratings), SAVE_CATEGORY_FULL);
}

const saveCompetencyFullInformationPromise = (functionalCompetencyId:number, categoryId:number, functionalCompetency:Category, 
  currentCompetencyData: CompetencyFullInformation,
  translations:IEntityTranslation[], probingQuestionItems?: IEntityTranslation[], ratings?: IRatingWithTranslations[]):Promise<boolean> => {

  if(functionalCompetencyId && functionalCompetency && !categoryId) {
    const engTranslation = translations.find(val => val.languageCode === 'EN');
    const categoryToSave = {
      parentId: functionalCompetencyId,
      languageCode: 'EN',
      type: "Group",
      text: engTranslation ? engTranslation.text : '',
      ownsRatings: functionalCompetency.ownsRatings,
      ownsProbingQuestions: functionalCompetency.ownsProbingQuestions,
    };
    return saveFunctionalCompetency(undefined, categoryToSave).then(newCategory => {      
      return saveCategoryAdditionInfo(newCategory.id ? newCategory.id : 0, currentCompetencyData, translations, probingQuestionItems, ratings);       
    });
  } else {
    return new Promise<boolean>((resolve) => {
      resolve(saveCategoryAdditionInfo(categoryId, currentCompetencyData, translations, probingQuestionItems, ratings));
    });
  }  
}

const savingRatingsPromise = (categoryId:number, currentCompetencyData: CompetencyFullInformation, ratings: IRatingWithTranslations[]):Promise<boolean> => {  

  const enrichedRatings = addEditRatingActions.enrichRatingFromExisted(categoryId, 0, ratings, currentCompetencyData ? currentCompetencyData.ratings : undefined);

  if (currentCompetencyData && currentCompetencyData.ratings && currentCompetencyData.ratings.length > 0) {
    if (ratings && ratings.length > 0) {      
      return addEditRatingActions.updateRatingsPromise(enrichedRatings).then(_ => {
        return true;
      });
    }
    else { //deleting
      return addEditRatingActions.deleteRatingsPromise(currentCompetencyData.ratings).then(_ => {
        return true;
      });
    }
  }
  else if (ratings && ratings.length > 0) {
    return addEditRatingActions.createRatingsPromise(enrichedRatings).then(_ => {
      return true;
    });
  }
  else {
    return new Promise<boolean>((resolve) => {
      resolve(true);
    });
  }
}

const savingProbingQuestionPromise = (categoryId:number, 
  currentCompetencyData: CompetencyFullInformation, probingQuestionItems?: IEntityTranslation[]):Promise<boolean> => {

  if (currentCompetencyData && currentCompetencyData.probingQuestion && currentCompetencyData.probingQuestion.id) {
    const questionId = currentCompetencyData.probingQuestion.id as number;
    if (probingQuestionItems && probingQuestionItems.length > 0) {
      return saveQuestionWithTranslationsRequest(questionId, probingQuestionItems).then(_ => {
        return true;
      });
    }
    else { //deleting
      return deleteQuestionRequest(questionId).then(_ => {
        return true;
      });
    }
  }
  else if (probingQuestionItems && probingQuestionItems.length > 0) {
    return addEditQuestionActions.createQuestionPromise(categoryId, probingQuestionItems, 'Probing').then(_ => {
      return true;
    });
  }
  else {
    return new Promise<boolean>((resolve) => {
      resolve(true);
    });
  }
}

const saveCategoryAdditionInfo = (categoryId:number, 
  currentCompetencyData: CompetencyFullInformation, 
  translations:IEntityTranslation[], probingQuestionItems?: IEntityTranslation[], ratings?: IRatingWithTranslations[]):Promise<boolean> => {
  
  const result = saveFunctionalCompetencyWithTranslations(categoryId, translations).then(_ => {
        
    const pqPromise = savingProbingQuestionPromise(categoryId, currentCompetencyData, probingQuestionItems);    
    const rtPromise = savingRatingsPromise(categoryId, currentCompetencyData, ratings ? ratings : []);

    return Promise.all([pqPromise, rtPromise]).then(values => {
      return values[0] && values[1] ? true : false;
    });
  });

  return result;
}

export const actions = {
  fetchFunctionalTranslation,
  saveCategory,  SAVE_CATEGORY,
  deleteCategory, DELETE_CATEGORY,
  saveCategoryWithTranslations, SAVE_CATEGORY_WITH_TRANSLATIONS,
  loadCategoryTranslations, LOAD_CATEGORY_TRANSLATIONS,
  loadCompetencyFullInformation, LOAD_CATEGORY_FULL,
  saveCompetencyFullInformation, SAVE_CATEGORY_FULL
};

export type Actions = ActionType<typeof actions>;
