import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions as questionActions} from '../../../store/questions';
import {
  JnJButton,
  EditIcon,
  DumbIcon
} from '@jaj/ui-components';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import {selectIsWriter} from '../../../store/user/selector';
import { onHistoryLinkClicked } from '../../../services/historyLinksService';

const styles = (theme) => ({
  root: {
    paddingBottom: 20,
    paddingTop: 20,
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    alignItems: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse'
    }
  },
  toolbars: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },
  text: {
    flex: '1',
  },
  selectedText: {
    color: '#888B8D'
  },
  button: {
    marginRight: 25,
    [theme.breakpoints.up('sm')]: {
      flex: '0 0 72px',
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: 10
    }
  }
});

export class QuestionItem extends PureComponent {
  static propTypes = {
    selected: PropTypes.bool,
    data: PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
    toggle: PropTypes.func.isRequired,
  }

  onClick = () => {
    this.props.toggle(this.props.data.id);
  }
  onEdit = () => {
    this.props.onEdit(this.props.data.id);
  };
  onDelete = () => {
    this.props.onDelete(this.props.data, this.props.selected);
  }
  renderActionButtons() {
    const {classes} = this.props;
    return (
      <div className={classes.toolbars}>
        <JnJButton name="Edit" onClick={this.onEdit} >
          <EditIcon />
        </JnJButton>
        <JnJButton name="Delete" onClick={this.onDelete} >
          <DumbIcon />
        </JnJButton>
      </div>
    )
  }
  render() {
    const {
      isWriter,
      classes,
      data,
      selected
    } = this.props;

    // To display edited question text
    var editedQuestionInfo = localStorage.getItem('editedQuestion');
    var editedQuestion = (editedQuestionInfo != undefined && editedQuestionInfo != '') ? JSON.parse(editedQuestionInfo) : {};
    if (editedQuestion != null && editedQuestion.id != undefined && editedQuestion.id == data.id) {
      data.text = editedQuestion.text;
      localStorage.setItem('editedQuestion', '');
    }
    
    return (
      <div className={classes.root}>
        <JnJButton color="secondary"
          className={classes.button}
          size="small"
          variant={!selected ? 'contained' : 'outlined'}
          onClick={() => onHistoryLinkClicked({ category: 'question', action: !selected ? 'add' : 'remove', label: data.text,
                        onClick: this.onClick
                    })}>
          {!selected ? 'Add' : 'Remove'}
        </JnJButton>

        <Typography variant="body1" className={selected ? classes.selectedText : classes.text}>
          {data.text}
        </Typography>
        
        {isWriter && this.renderActionButtons()}
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  isWriter: selectIsWriter(state),
  selected: state.questions.includes(ownProps.data.id)
});

const mapDispatchToProps = {
  toggle: questionActions.toggle
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuestionItem));
