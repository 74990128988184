import React, { Component } from 'react'
import { connect } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import {
  JnJLink,
  Clock45M,
  Clock1H,
  JnJGridColumn,
  JnJGridRow,
  ClickableCard,
  LightbulbInfo
} from '@jaj/ui-components';
import { withStyles } from '@material-ui/core/styles';
import { actions } from '../../store/selectCategory/action';
import { Duration } from '../../store/selectCategory/type';
import { Loading } from '../../components/Loading';
import { onHistoryLinkClicked } from '../../services/historyLinksService';
import Container from '../../components/Container/Container';

const styles = theme => ({
  content: {
    marginTop: 30,
  },
  tipContent: {
    marginLeft: 0,
    [theme.breakpoints.up('md')]: {
      lineHeight: 24,
      maxWidth: 340,
    }
  },
  tipQuestion: {
    display: 'block',
    fontWeight: 800,
    marginBottom: 20
  },
  root: {
    backgroundColor: '#fff',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '50px',
      paddingBottom: '100px'
    },
  }
});

export class SelectDuration extends Component {
  constructor () {
    super(...arguments);

    this.icons = [
      <Clock45M />,
      <Clock1H />,
    ];
    this.props.init()
  }

  componentDidMount(){
    window.onbeforeunload = () => true;
    if (!this.props.guide.functionCategoryId){
      this.props.history.push('/interview/select-guide-type')
    };
  }

  componentWillUnmount(){
    window.onbeforeunload = null;
  }

  goToPreviousPage = () => {
    this.props.history.goBack();
  }

  renderContent() {
    const { initializing, categories, classes }  = this.props;

    if (initializing) {
      return (
        <Loading />
      );
    }

    return (
      <JnJGridRow className={classes.content}>
        <JnJGridColumn xs={false} lg={1}/>
        <JnJGridColumn xs={12} lg={7}>
          <JnJGridRow>
            {categories && categories.map(({id, text}, index) => (
              <JnJGridColumn xs={12} md={6} key={id}>
                <ClickableCard
                  onClick={() =>{
                    this.props.history.push('/interview/questions-list-page');
                    return onHistoryLinkClicked({
                      category: 'duration',
                      action: 'select',
                      label: text,
                      onClick: () =>  this.props.select(id, text, '/interview/questions-list-page'),
                    })}}
                  text={text}>
                  {this.icons[index]}
                </ClickableCard>
              </JnJGridColumn>
            ))}
          </JnJGridRow>
        </JnJGridColumn>
        <JnJGridColumn xs={12} lg={4}>
          <div className={classes.tipContent}>
            <LightbulbInfo direction="vertical">
              <span className={classes.tipQuestion}>How long should this interview be?</span>
              Interview length will depend on the number of questions asked. A 45 minute interview will often consist of 2-3 Leadership Imperative questions and 2-3 Functional Competency questions. An hour-long interview will often consist of 3 or more Leadership Imperative and Functional Competency questions.
            </LightbulbInfo>
          </div>
        </JnJGridColumn>
      </JnJGridRow>
    );
  }

  render() {
    const {classes} = this.props
    return (
      <Container static className={classes.root}>
        <JnJGridRow>
          <JnJGridColumn xs={false} lg={1}/>
          <JnJGridColumn xs={12} lg={10}>
            <div className="pbottom-40 mtop-20">
              <div className="mbottom-10" onClick={() => this.props.history.push('/interview/select-functional-competency')}>
                <JnJLink small left>Back to Functional Competencies</JnJLink>
              </div>
              <Typography variant="h4" color="primary"></Typography>
              <Typography  variant="h2" color="secondary">
                How long will this interview be?
              </Typography>
            </div>
          </JnJGridColumn>
          <JnJGridColumn xs={false} lg={1}/>
        </JnJGridRow>
        {this.renderContent()}
      </Container>
    )
  }
}

const mapStateToProps = (state) => ({
  page: state.category.page,
  competency: state.category.competency,
  initializing: state.category.initializing,
  categories: [
    {
      id: Duration.mins45,
      text: '45 minutes'
    },
    {
      id: Duration.hour,
      text: '1 hour or more'
    },
  ],
  guide: state.category.guide
});

const mapDispatchToProps = {
  init: actions.init,
  select: actions.selectDuration,
  // goToPage: actions.goToPage,
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SelectDuration))

