export enum GuidePages {
  position = '/interview/select-guide-type',
  competency = '/interview/select-functional-competency',
  duration = '/interview/select-duration',
  questions = '/interview/questions-list-page'
}

export type CategoryID = number;
export type PositionID = number;
export enum Duration {
  mins0 = 0,
  mins45 = 45,
  hour = 60,
}

export enum CategoryTab {
  guide = "guide",
  credo = "credo",
  leadrship = "leadership",
  functional = "functional",
  custom = "custom",
}

export type CategoryDTO = {
  id: number,
  parentId: number,
  text: string,
  description: string,
  type: "GuideType" | "Category" | "Group" | "FunctionalCompetency",
}

export type Category = {
  parents: CategoryID[];
} & CategoryDTO;

export type FullEntityDataForm = {
  text: string;
  description: string;
  probingQuestions: string;
  custom_rating1: string;
  custom_rating2: string;
  custom_rating3: string;
  custom_rating4: string;
}

interface Questions {
    id: number,
    userGuideId: number,
    customQuestion: string[],
    questionId: number,
}


export type ReportDocRequestDTO = {
    id?: number,
    name: string,
    guideTypeCategoryId?: number,
    functionCategoryId?: number,
    questionnaireLength?: number,
    guideTypeCategoryName?: string,
    userKey?: string,
    isDraft: boolean,
    createdDate?: string,
    updatedDate?: string,
    questions?: number[],
    functionCategoryName: string,
    typeOfReport?: string,
    selectedQuestions?: number[],
    selectedCustomQuestions?: string[],
    languageCode?: string
}


export type ReportDocResponseDTO = string;
