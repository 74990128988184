import { axiosInstance } from './apiService';
import { Language } from '../store/language/type';

export async function loadLanguages(): Promise<Language[]> {
  try {
    const res = await axiosInstance.get<Language[]>(`/common/languages`);
    return res.data.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });
  } catch(e) {
    return [];
  }
}
