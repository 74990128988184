import * as actions from './action';
import { getType } from 'typesafe-actions';
import { loadLanguages } from '../../services/language';
import { call, put, fork, all, takeEvery } from 'redux-saga/effects';
import { actions as categoryActions } from '../../store/selectCategory/action';

function* fetchLanguages() {
  try {
    yield put(actions.fetch.request());
    const res = yield call(loadLanguages);
    yield put(actions.fetch.success(res));
  }
  catch(error) {
    yield put(actions.fetch.failure('Error Load'));
  }
}

function* watchBaseActions() {
  yield takeEvery([
    getType(categoryActions.resetAll),
    getType(categoryActions.fetchLanguages)
  ], fetchLanguages);
}

export function* saga() {
  yield all([
    fork(watchBaseActions),
  ]);
}
