import { Reducer } from 'redux';
import { actions } from './tipAction';
import { Tip } from './tipType';
import { getType } from 'typesafe-actions';
import { PayloadAction } from 'typesafe-actions/dist/types';

type TipState =  {
  readonly loading: boolean;
  readonly tipStore: TipStore;
}

export type TipStore = {
  [key: string]: {
    [key: string]: Tip;
  };
}

type TipAction =  PayloadAction<string, TipStore>;

const initialState: TipState = {
  loading: false,
  tipStore: {},
};

const reducerMap: {[key: string]: (state: TipState, action: TipAction) => TipState} = {
  [getType(actions.fetch.request)]: (state: TipState, action: TipAction) => {
    return {
      ...state,
      loading: true,
    };
  },
  [getType(actions.fetch.success)]: (state: TipState, action: TipAction) => {
    return {
      ...state,
      tipStore: action.payload,
      loading: false,
    };
  },
  [getType(actions.fetch.failure)]: (state: TipState, action: TipAction) => {
    return {
      ...state,
      loading: false,
    };
  },
};

export const reducer: Reducer<TipState, TipAction> = (state = initialState, action: TipAction) => {
  const currentReducer = reducerMap[action.type];
  if (typeof currentReducer === "function") {
    return currentReducer(state, action);
  }

  return state;
};
