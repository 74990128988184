import { axiosInstance } from "./apiService";
import { TipStore } from "../store/tip/tipReducer";

export async function loadCategoryTips(categoryId: string, duration: string) {
  const res = await axiosInstance.get(`/tips/${categoryId}/${duration}`);

  return res.data;
}

export function selectTip(tipStore: TipStore, categoryId: string, duration: string) {
  return tipStore[categoryId] && tipStore[categoryId][duration];
}
