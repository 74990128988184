import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tabs from '../../components/Tabs/Tabs';

export class LanguageSelectorTabs extends PureComponent {
  static propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
      code: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired),
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  handleChange = (value) => this.props.onChange(value);

  getTabOptions = (options) => options.map(({name, code}) => ({ title: (name || '').toUpperCase(), value: code }));

  render() {
    const {
      loading,
      value,
      options,
    } = this.props;

    return !loading ? (
      <Tabs
        value={value}
        onChange={this.handleChange}
        options={this.getTabOptions(options)}
       />
    ) : null;
  }
}

const mapStateToProps = (state) => ({
  options: state.language.languages,
  loading: state.language.loading
})

export default connect(mapStateToProps)(LanguageSelectorTabs)
