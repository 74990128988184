import { axiosInstance } from './apiService';
import { UserDTO } from './type';


export async function fetchCurrentUser(): Promise<UserDTO> {
  const res = await axiosInstance.get(`/user`);
  return res.data;
}

export async function switchRoleTo(roleName: string): Promise<string> {
  const res = await axiosInstance.post(`/user/setRole?role=${roleName}`);
  return res.data ? roleName : 'User';
}

export async function setUserGuideAttendance(payload: object) {
  const res = await axiosInstance.post('/user/setGuideAttendance',payload);
}
