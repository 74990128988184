import {Reducer} from 'redux';
import * as actions from './action';
import { getType, ActionType } from 'typesafe-actions';
import { getActionStartType, getActionEndType, getActionFailureType } from '../../services/actionService';
import { UserState } from '../../services/type';

type Actions = ActionType<typeof actions>;

export const reducer: Reducer<UserState, Actions> = (state = {loading: false}, action:any) => {
  switch(action.type) {
    case getType(actions.fetchUser.request):
      return {
        ...state,
        error: undefined,
        loading: true
      };
    case getType(actions.fetchUser.success):
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    case getType(actions.fetchUser.failure):
    case getActionFailureType(actions.SWITCH_USER_ROLE):
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    case getActionEndType(actions.SWITCH_USER_ROLE):
      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          userIsInRoleWriter: action.payload === 'Admin'
        }
      };

    default:
      return state;
  }
};
