import {Reducer} from 'redux';
import {Actions, actions} from './action';
import { IQuestion } from '../../models/questionModels';
import { getType } from 'typesafe-actions';

type State = IQuestion[];

const initialState: State = [
  {
    id: 0,
    text: '',
    categoryId: 0,
    parentId: null,
    type: 'Custom',
    languageCode:'EN'
  }];

export const reducer: Reducer<State, Actions> = (state: State = initialState, action: Actions): State => {
  switch (action.type) {
    case getType(actions.resetAll):
      return [...initialState];
    case getType(actions.addQuestion):
      return [
        ...state,
        {
          id: state[state.length-1].id + 1,
          text: action.payload,
          categoryId: 0,
          parentId: null,
          type: 'Custom',
          languageCode:'EN'
        }
      ];
    case getType(actions.addQuestions):
      const questions = [...action.payload.questions.map((q, i) => {
        return {
          id: i,
          text: q,
          categoryId: 0,
          parentId: null,
          type: 'Custom',
          languageCode: 'EN'
        }
      })];
      if (questions.length) {
        return questions as IQuestion[];
      }
      return [...initialState];
    case getType(actions.updateQuestion):
      return state.map((question, i) => question.id === action.payload.id ? {
        ...question, text: action.payload.text} : question);
    case getType(actions.removeQuestion):
      state = state.filter((question, i) => question.id !== action.payload);
      if (state.length === 0){
        state = [...initialState];
      }
      return state;
    default:
      return state
  }
}

export default reducer;
