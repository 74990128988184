import {guideStatus} from "../../../models/guideModel";

export function renderModal(modalVIewStatus) {
  switch (modalVIewStatus) {
    case guideStatus.preview:
      return {
        title: `Preview`,
        content: this.props.content,
        classes: {
          contentParent: this.props.classes.dialogContent,
          contentChild: this.props.classes.content
        },
        buttons: {
          class: this.props.classes.confirmationButtons,
          left: {
            color: 'primary',
            variant: 'outlined',
            className: this.props.classes.continueEditingButton,
            onClick: this.props.closePreviewModal,
            label: 'Continue Editing'
          },
          right: {
            langSelector: {
              specialClassName: this.props.classes.languageButton,
              inputLabel: 'Publish Word Doc in:'
            },
            submit: {
              isDisabled: this.state.publishing,
              color: 'primary',
              variant: 'contained',
              className: this.props.classes.publishButton,
              onClick: () => this.props.changeGuideModalStatus(guideStatus.pending),
              label: 'Publish & Download'
            }
          }
        }
      };
    case guideStatus.published:
      return {
        title: `Preview`,
        content: this.props.content,
        classes: {
          contentParent: this.props.classes.dialogContent,
          contentChild: this.props.classes.content
        },
        buttons: {
          class: this.props.classes.publishedGuideButtons,
          left: null,
          right: {
            langSelector: {
              specialClassName: this.props.classes.languageButton,
              inputLabel: 'Download Word Doc in:'
            },
            submit: {
              color: 'primary',
              variant: 'contained',
              className: this.props.classes.publishButton,
              onClick: () => this.props.fetchGuideAsync(this.props.guide.id),
              label: 'Download'
            }
          }
        }
      };
    case guideStatus.pending:
      return {
        title: `Are you sure you want to publish this guide?`,
        content: `Your guide will be locked for editing after you publish.`,
        classes: {
          contentParent: this.props.classes.confirmationContent,
          contentChild: ''
        },
        buttons: {
          class: this.props.classes.dialogActions,
          left: {
            color: 'primary',
            variant: 'outlined',
            className: this.props.classes.continueEditingButton,
            onClick: this.props.closePreviewModal,
            label: 'Continue Editing'
          },
          right: {
            langSelector: null,
            submit: {
              color: 'primary',
              variant: 'contained',
              className: this.props.classes.publishButton,
              onClick: () => this.onPublish(),
              label: 'Yes, publish now'
            }
          }
        }
      };
    default:
      return console.error("Something went wrong");
  }
};


