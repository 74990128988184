import { axiosInstance } from './apiService';
import { ReportDocRequestDTO } from "../store/selectCategory/type";

export async function createGuide(guide: ReportDocRequestDTO) {
  const res = await axiosInstance.post(`userGuides/create`, guide);
  return res.data;
}

export async function updateGuide(guide: ReportDocRequestDTO) {
  const res = await axiosInstance.post(`userGuides/update`, guide);
  return res.data;
}

export async function getDraftGuides(language: string) {
  const res = await axiosInstance.get(`userGuides/getUserDraftGuides/${language}`);
  return res.data;
}



export async function getPublishedGuides(language: string) {
  const res = await axiosInstance.get(`userGuides/getUserGuides/${language}`);
  return res.data;
}

export async function deleteUserGuide(id: number) {
  const res = await axiosInstance.delete(`userGuides/${id}`);
  localStorage.setItem('deleteFailed', 'false');
  if(res.data.result == false) {
    localStorage.setItem('deleteFailed', 'true');
  }
  return res.data;
}
