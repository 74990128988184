import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {TextField} from '@material-ui/core/';
import {
  JnJContainer,
  JnJGridRow,
  JnJGridColumn,
  SecondaryLink,
  PlusIcon,
  DumbIcon} from '@jaj/ui-components';
import { connect } from 'react-redux';
import {actions} from '../../store/customQuestions/action';
import cs from 'classnames';

const styles = theme => ({
  root: {
    backgroundColor: '#f4f4f4',
  },
  heading: {
    fontSize: 30,
    lineHeight: '30px',
    fontWeight: 900,
    marginBottom: 20
  },
  description: {
    fontWeight: 300
  },
  descriptionList: {
    fontWeight: 300,
    marginBottom: 60,
    listStyleType: 'decimal',
    '&>li ': {
      listStyle: 'inherit',
      marginLeft: 30
    }
  },
  input: {
    width: '100%',
    marginTop: 15
  },
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    }
  },
  emptyCard: {
    backgroundColor: 'grey'
  },
  link: {
     border: 'none',
     backgroundColor: 'transparent',
     display: 'flex',
     alignItems: 'center',
     flexGrow: 1
  },
  linkText: {
    marginLeft: 10,
    whiteSpace: 'nowrap'
  },
  span: {
    fontSize: 14,
    lineHeight: '18px',
    color: '#63666A',
    marginBottom: 15
  },
  listElement: {
    display: 'block',
    marginBottom: 15,
    '&>div textarea': {
      overflow: 'hidden',
      resize: 'none'
    }
  },
  deleteButton: {
    backgroundColor: 'transparent',
    border: 'none',
    margin: '0 0 10px 10px',
    cursor: 'pointer'
  },
  hidden: {
    visibility: 'hidden'
  }
});

class CustomQuestion extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      isLastQuestionItemEmpty: this.props.customQuestions[this.props.customQuestions.length-1].text.length === 0,
    }
  }

  addCustomQuestion = () => {
    if (!this.state.isLastQuestionItemEmpty){
      this.props.addQuestion('');
    }
    this.setState({
       isLastQuestionItemEmpty: true
    });
  };

  handleChange = (id, value) => {
    this.setState({
      isLastQuestionItemEmpty: false
    });
    this.props.updateQuestion(id, value);
  };

  renderAddButton(index) {
    const {
      classes,
      customQuestions,
    } = this.props;

    if (customQuestions.length === index + 1) {
      return (
        <JnJGridColumn xs={12} md={3} className={classes.linkWrapper}>
          <button disabled={this.state.isLastQuestionItemEmpty} onClick={this.addCustomQuestion} className={classes.link}>
            <PlusIcon />
            <SecondaryLink className={classes.linkText}>
              Add another
            </SecondaryLink>
          </button>
        </JnJGridColumn>
      );
    }
  }

  render(){
    const {
      classes,
      customQuestions,
      removeQuestion,
      className,
    } = this.props;
    return (
      <div className={className}>
        <JnJContainer className={classes.root}>
          <p className={classes.heading}>Add your own questions</p>
          <p className={classes.description}>A good interview question measures something that is:</p>
          <ul className={classes.descriptionList}>
            <li>Important to perform the job.</li>
            <li>Required on day one of the job. Your question should be clear, easy to understand, interpreted the same way by all candidates, and you should have a clear idea of the answer you are looking for.</li>
          </ul>
          {customQuestions && customQuestions.map((question, index) => (
            <div key={question.id}>
              {question.text.length !== 0 && <span className={classes.span}>Custom Question</span>}
              <JnJGridRow>
                <JnJGridColumn xs={12} md={9} className='flex'>
                  <div className={classes.link}>
                    <TextField
                      fullWidth
                      multiline
                      placeholder="Enter your question (optional)"
                      value={question.text}
                      className={classes.listElement}
                      onChange={(event)=> this.handleChange(question.id, event.target.value)}
                    />
                  </div>
                    <button
                      onClick={() => removeQuestion(question.id)}
                      className={cs(classes.deleteButton, question.text.length ? '': classes.hidden)}
                    >
                      <DumbIcon />
                    </button>
                </JnJGridColumn>
                {this.renderAddButton(index)}
              </JnJGridRow>
            </div>
          ))}
        </JnJContainer>
      </div>
    )
  }
}

const mapStateToProps = ({customQuestion}) => ({
  customQuestions: customQuestion
});

const mapDispatchToProps = {
  addQuestion: actions.addQuestion,
  removeQuestion: actions.removeQuestion,
  updateQuestion: actions.updateQuestion
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomQuestion));
