import React from "react"
import { JnJButton } from "@jaj/ui-components";
import { withStyles } from "@material-ui/core";

const useStyles = theme => ({
  button: {
    padding: '8px 45px',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
});


const EditButton = ({ classes, editButtonClick }) => (
  <JnJButton
    onClick={editButtonClick}
    className={classes.button}
    variant={'contained'}
    color={'primary'}
  >
    {'Edit'}
  </JnJButton>
)

export default withStyles(useStyles)(EditButton);
