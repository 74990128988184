import React from "react"
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { actions } from "../../store/selectCategory";
import { actions as customQuestionAction } from "../../store/customQuestions";
import { actions as questionAction } from "../../store/questions";
import * as languageActions from '../../store/language/action';
import {
  Menu,
  Card,
  MenuItem,
  Typography,
  withStyles,
} from "@material-ui/core";
import Tooltip from "./ToolTipComp";
import EmptyCard from './EmptyCard';
import DotsButton from "../../components/DotButton/DotsButton";
import EditButton from "../../components/EditButton/EditButton";
import TrashButton from "../../components/TrashButton/TrashButton";
import PreviewButton from '../../components/PreviewDownloadButton/PreviewAndDownloadButton';

import { JnJGridColumn, CopyIcon, ShareIcon } from "@jaj/ui-components";
import { onHistoryLinkClicked } from "../../services/historyLinksService";
import { DeleteModalDialog, ErrorModalDialog } from '../../components/ModalDialog';
import GuideInfo from "../../components/GuideFormInfo/GuideInfo";
import {guideStatus} from "../../models/guideModel";

const useStyles = theme => ({
  cardSize: {
    height: '300px',
    maxWidth: '300px',
    padding: '35px 30px',
    position: 'relative',
  },
  cardHeader: {
    width: '227px',
    color: '#CA001B',
    fontSize: '14px',
    fontWeight: '900',
    lineHeight: '18px',
    textTransform: 'uppercase',
    marginBottom: '10px'
  },
  cardInner: {
    height: '100%',
    width: '100%',
    position: 'relative',
    fontFamily: "J&J Circular",
  },
  button: {
    padding: '8px 10px',
    fontSize: '14px',
    fontWeight: 'bold',
    textTransform: 'none',
  },
  buttonRow: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0px',
    bottom: '0px',
    width: '100%'
  },
  iconButton: {
    "&:hover": {
      backgroundColor: "transparent",
      cursor: "pointer"
    }
  },
  previewButton: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      '& img': {
        filter: 'brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(182deg) brightness(108%) contrast(101%)',
      }
    }
  },
  justifyCenter: {
    display: 'flex',
    justifyContent: 'center'
  },
  dotsButton: {
    marginLeft: '10px',
    flexShrink: 0,
    flexGrow: 0,
    minWidth: 0,
    '&:hover ': {
      backgroundColor: '#CA001B',
      '& span': {
        '& span': {
          backgroundColor: '#ffffff',
        }
      }
    },
  },
  downIcon: {
    marginRight: '10px',
  },
  dot: {
    marginLeft: '2px',
    height: '5px',
    width: '5px',
    backgroundColor: '#CA001B',
    borderRadius: '50%',
    display: 'inline-block',
  },
  dotsMenu: {
    display: 'flex'
  },
  shareIcon: {
    width: '30px',
    height: '22px',
    padding: '5px',
  },
  copyIcon: {
    width: '25px',
    height: '20px',
    padding: '5px',
  },
  cardText: {
    fontSize: '14px',
    marginTop: '12px',
    fontWeight: '500',
    lineHeight: '18px',
    display: 'flex',
    '& div:first-of-type': {
      '& p': {
        display: 'flex',
        '& span:after':{
          content: '":"',
        }
      }
    }
  },
  cardContentText: {
    width: '177px',
    color: '#212121',
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '18px',
    marginLeft: '15px',
    '& p': {
      width: 'max-content',
    }
  },
  longTextView: {
    maxWidth: '160px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  cardTitle: {
    marginTop: '10px',
    maxHeight: '46px',
    maxWidth: '240px',
    color: '#212121',
    fontSize: '24px',
    fontWeight: '900',
    letterSpacing: '-0.5px',
    lineHeight: '23px',
    whiteSpace: 'normal',
    display: '-webkit-box',
    lineClamp: '2',
    boxOrient: 'vertical',
  },
});

class GuideCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dots: true,
      anchorEl: null,
      openProps: false,
      openDeleteDialog: false,
      shareLink: ''
    }
  }

  editButtonClick = () => {
    this.addQuestions();
    this.props.editGuide(this.props.item);
    localStorage.setItem('editGuideLanguageCode', this.props.item.languageCode);
    this.props.history.push('/interview/questions-list-page');
  };

  copyGuide = () => {
    this.addQuestions();
    this.props.copyGuide(this.props.item);
  };

  addQuestions = () => {
    this.props.addCustomQuestion(this.props.item.selectedCustomQuestions);
    this.props.addQuestion(this.props.item.selectedQuestions);
  };

  shareGuide = (item) => {
    this.props.shareGuide(item);
  };

  trashButtonClick = () => {
    this.setState({
      dots: true,
      openProps: false,
      openDeleteDialog: true,
    });
  };

  onGuideSettingsOpen = (event) => {
    this.getLink();
    this.setState({ openProps: true, anchorEl: event.currentTarget, dots: false })
  };

  onGuideSettingsClose = (event) => {
    this.setState({ openProps: false, anchorEl: null, dots: true })
  };

  handleCloseDeleteDialog = (isPositiveAnswer) => {
    if (isPositiveAnswer) {
      this.props.removeGuide(this.props.item);
    }
    this.setState({
      openDeleteDialog: false,
    });
  };

  getLink() {
    const link = this.props.item.sharingLinks.find((guide) => guide.languageCode === this.props.item.languageCode);
    this.setState({shareLink: link.url})
  }

  render() {
    const { classes, item, onClick } = this.props;
    var deleteFailedModalDialog;
      if (localStorage.getItem('deleteFailed') === 'true') {
        deleteFailedModalDialog = <ErrorModalDialog
          open={true}
          errorCode={null}
          errorMsg="The guide does not exists."
        />;
        localStorage.setItem('deleteFailed', 'false');
      } else {
        deleteFailedModalDialog = "";
      }
    return (
        <JnJGridColumn className={classes.justifyCenter} xs={12} md={4} >
          {!item.name && !item.id ?
            <EmptyCard onClick={() => {this.props.syncGuideLanguage('EN'); this.props.resetAllQuestion(); this.props.onClick()}} />
            :
            <div>
              <Card className={`${classes.cardSize} flex-grow ${item.guideTypeCategoryId ? "" : classes.greyBg}`} onClick={onClick} >
                <div className={classes.cardInner}>
                  <div className={classes.root}>
                    <div>
                      <Typography variant="h5" component="h3" color='primary' className={classes.cardHeader}>
                        {item.guideTypeCategoryName.replace(/,(\s+)?$/, '')}
                      </Typography>
                      <Tooltip title={item.name} disableFocusListener>
						  <h2 title={item.name} className={`${classes.longTextView} ${classes.cardTitle}`}>
							{item.name}
						  </h2>
                      </Tooltip>
                      <GuideInfo
                        cardText={classes.cardText}
                        longTextView={classes.longTextView}
                        cardContentText={classes.cardContentText}
                        guide={item} />
                    </div>
                  </div>
                  <div className={classes.buttonRow}>
                    {!item.isDraft ? <>
                      <PreviewButton onClick={() => onHistoryLinkClicked({
                        category: 'questions',
                        action: 'preview',
                        label: 'preview',
                        onClick: () => {
                          this.props.syncGuideLanguage(item.languageCode);
                          this.props.fetchLanguages();
                          this.props.previewGuideModal(item, guideStatus.published)
                        }
                      })} />
                      <span className={classes.dotsMenu}>
                        <DotsButton dots={this.state.dots} aria-controls="fade-menu" aria-haspopup="true" onClick={this.onGuideSettingsOpen} />
                      </span>
                    </>
                      :
                      <>
                        <EditButton editButtonClick={this.editButtonClick} />
                        <TrashButton trashButtonClick={this.trashButtonClick} />
                      </>}
                  </div>
                </div>

              </Card>
              <Menu
                open={this.state.openProps}
                anchorEl={this.state.anchorEl}
                onClose={this.onGuideSettingsClose}
                className={classes.previewDots}
                disableAutoFocusItem
                PaperProps={{
                  style: {
                    left: '50%',
                    transform: 'translateX(-77%) translateY(32%)',
                  }
                }}
                MenuListProps={{
                  style: {
                    padding: 0,
                  },
                }}
              >
                <MenuItem onClick={() => {this.copyGuide(item); this.props.syncGuideLanguage(item.languageCode); this.props.changeLanguage(item.languageCode); }}>
                  <CopyIcon className={classes.copyIcon} />
                  Create a copy
                </MenuItem>
                <a href={`mailto:?subject=Link to download the IG Template titled as "${item.name}"&body=${this.state.shareLink}`}>
                  <MenuItem>
                    <ShareIcon className={classes.shareIcon}/>
                    Share
                  </MenuItem>
                </a>
                <MenuItem onClick={this.trashButtonClick}>
                  <TrashButton />
                  Delete
                </MenuItem>
              </Menu>
            </div>
          }
          <DeleteModalDialog
            deletedElement={item.name}
            selectedThing={'Interview Guide'}
            open={this.state.openDeleteDialog}
            modalClose={this.handleCloseDeleteDialog}
            question='Are you sure you want to delete this guide?'
          />
          {deleteFailedModalDialog}
        </JnJGridColumn>)
  }
}


const mapStateToProps = ({ category }) => ({
  duration: category.duration,
  drafts: category.drafts,
  published: category.published,
});

const mapDispatchToProps = {
  selectCompetency: actions.selectCompetency,
  selectDuration: actions.selectDuration,
  openPreviewModal: actions.openPreviewModal,
  previewGuideModal: actions.previewGuideModal,
  editGuide: actions.editGuide,
  addCustomQuestion: customQuestionAction.addQuestions,
  addQuestion: questionAction.pushQuestions,
  removeGuide: actions.deleteGuide,
  fetchLanguages: actions.fetchLanguages,
  copyGuide: actions.copyGuide,
  shareGuide: actions.shareGuide,
  syncGuideLanguage: languageActions.syncGuideLanguage,
  changeLanguage: languageActions.select,
  resetAllQuestion: actions.resetAllQuestion
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(useStyles)(GuideCard)));
