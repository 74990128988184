import React, {PureComponent} from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, createStyles, Theme } from "@material-ui/core";
import { JnJGridRow, JnJGridColumn, JnJLink, LightbulbInfo } from "@jaj/ui-components/build";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '35px 280px 35px 0',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    }
  },
  linkContainer: {
    padding: '22px 0',
    [theme.breakpoints.up('sm')]: {
      borderTop: '1px solid #D8D8D8',
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  column: {
    [theme.breakpoints.up('sm')]: {
      '&:first-child': {
        paddingRight: 44,
        paddingBottom: 0,
        borderRight: '1px solid #D8D8D8',
      },
      '&:last-child': {
        paddingLeft: 44,
      }
    }
  },
  title: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  tipContent: {
    maxWidth: 445,
    [theme.breakpoints.down('md')]: {
      marginBottom: 16
    }
  }
});

interface InterviewStyleProps extends WithStyles{
  onNext: () => void
}

interface columnContent {
  heading: string,
  description: string,
  list: string[]
}

class InterviewStyle extends PureComponent<InterviewStyleProps, {}> {
  columnContents: columnContent[] = [
    {
      heading: '1:1 interviews',
      description: 'A 1:1 interview is exactly what it sounds like: an interview between one candidate and one interviewer at a time. Choose this format when multiple interviewers want to interview a candidate separately to dive deep into Technical/Functional competencies and/or Leadership Imperatives.',
      list: [
        'Will interviewers’ scores be combined or averaged?',
        'Will there be a debrief conversation across interviewers, and if so, when?',
        'Will interviewers have to reach consensus?',
        'Will there be any knock-out factors that preclude a candidate from being hired?',
        'How you divide up the questions/competencies and communicate them to the interview team?'
      ]
    },
    {
      heading: 'Panel interviews',
      description: 'In a panel interview, an applicant answers questions asked by a group of people who will make the hiring decision. Choose this format when multiple stakeholders need to have a voice in the hiring process and there’s limited time with the candidate.',
      list: [
        'Will interviewers’ scores be combined or averaged?',
        'Will all panelists need to come to consensus?',
        'How you divide up the questions/competencies and communicate them to the interview team?',
        'Will everyone rate the same questions, or will different panelists rate different questions?'
      ]
    }
  ];

  renderColumns() {
    return this.columnContents.map((column, index) => (
        <JnJGridColumn xs={12} md={6} key={index} className={this.props.classes.column}>
          <Typography
            variant="h4"
            color="secondary"
            className="margin-bottom-20"
          >
            {column.heading}
          </Typography>
          <Typography
            variant="body1"
            className="margin-bottom-20"
          >
            {column.description}
          </Typography>
          <Typography
            variant="body1"
            className="margin-bottom-20"
          >
            If you choose this interview style, answer these questions <i>before</i> your team starts interviewing
            candidates.
          </Typography>
          <ul className='list'>
            {column.list.map((item, index)=>(
              <li key={index}>
                  {item}
              </li>
            ))}
          </ul>
          { index === 0 && (
            <LightbulbInfo direction="vertical" className={this.props.classes.tipContent}>
              Build in at least 5 minutes between interviews for team members to connect.
              This is a chance to give a high-level summary of things the next interviewer should know,
              as well as to discuss concerns and areas that need to be explored further.
            </LightbulbInfo>
          )}
        </JnJGridColumn>
      ))
  }

  render() {
    const {classes, onNext} = this.props;
    return (
      <JnJGridRow className="justify-center">
        <JnJGridColumn xs={12} md={10}>
          <div className={classes.root}>
            <Typography
              variant="h3"
              color="secondary"
              className={`margin-bottom-20 ${classes.title}`}
            >
              Choose your style
            </Typography>
            <Typography
              variant="body1"
              className="margin-bottom-20"
            >
              Before you meet with a candidate, you’ll need to decide what style of interview you’ll use. At Johnson & Johnson companies, we typically use two types:
            </Typography>
          </div>
          <JnJGridRow>
            {this.renderColumns()}
          </JnJGridRow>
          <div className={classes.root}>
            <Typography
              variant="body1"
              className="margin-bottom-15 margin-top-20"
            >
              Additionally, we use two different modes of interviewing: online/virtual and face-to-face.
              Either style of interview can be conducted in either mode.
              Your recruiter will work with you to confirm your preferred interview approach.
              If you have any questions, you can always contact your recruiter.
            </Typography>
            <Typography
              variant="body1"
              className="margin-bottom-15"
            >
              Whichever style and mode you choose, be sure to share details about the position
              (such as location, hours, when a decision is expected, etc.)
              with your interview team at least 3 days before interviews begin.
            </Typography>
          </div>
          <div className={classes.linkContainer} onClick={onNext}>
            <JnJLink>
              How to choose your team
            </JnJLink>
          </div>
        </JnJGridColumn>
      </JnJGridRow>
    );
  }
}

export default withStyles(styles)(InterviewStyle);
