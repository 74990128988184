import * as React from 'react'
import { withStyles, WithStyles } from '@material-ui/core/styles';
import createStyles from '@material-ui/core/styles/createStyles';
import { Theme } from '@material-ui/core';
import {
  colorSecondary,
  colorPrimary,
  colorSelectedText,
  colorText,
} from '../../styles/colors';
import { CSSProperties } from "jss/css";

export interface ITabOption {
  title: string;
  value: string;
}

export interface TabsProps extends WithStyles {
    className?: string,
    value: string,
    options: ITabOption[];
    onChange: (value: string) => void;
}

const tabStyle: CSSProperties = {
  fontSize: '15px',
  borderBottom: `1.58px solid ${colorSecondary}`,
  color: colorText,
  padding: '0 0 10px',
  cursor: 'pointer',
  textAlign: 'center',
};

const styles = (theme: Theme) => createStyles({
  tab: tabStyle,
  selected: {
    ...tabStyle,
    borderBottom: `2.61px solid ${colorPrimary}`,
    color: colorSelectedText,
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

class Tabs extends React.PureComponent<TabsProps> {
  onChange(value: string) {
    if(typeof this.props.onChange === "function") {
      this.props.onChange(value);
    }
  }

  renderOptions() {
    return this.props.options.map((option) => {
      return (
        <div
          key={option.value}
          onClick={() => this.onChange(option.value)}
          className={
            this.props.value === option.value
              ? this.props.classes.selected
              : this.props.classes.tab
          }
        >
          {option.title.toUpperCase()}
        </div>
      );
    });
  }

  render() {
    return (
      <div className={this.props.classes.tabs}>
        {this.renderOptions()}
      </div>
    );
  }
}

export default withStyles(styles)(Tabs);
