import * as React from "react";
import { FormControl, RadioGroup as MaterialRadioGroup, FormControlLabel, Radio } from "@material-ui/core";

interface ISelectOption {
  title: string;
  value: string;
}

interface ISelectProps {
  value: string;
  options: ISelectOption[];
  name?: string;
  onChange: (value: string) => void;
}

export default class RadioGroup extends React.PureComponent<ISelectProps, {}> {
  onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.props.onChange(event.target.value);
  }

  render() {
    return (
      <FormControl>
        <MaterialRadioGroup
          value={this.props.value}
          onChange={this.onChange}
        >
          {this.props.options && this.props.options.map((option) => {
            return (
              <FormControlLabel
                key={option.title}
                value={option.value}
                control={<Radio />}
                label={option.title}
              />
            );
          })}
        </MaterialRadioGroup>
      </FormControl>
    );
  }
}
