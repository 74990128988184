import React, {PureComponent} from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, createStyles, Theme } from "@material-ui/core";
import { JnJGridRow, JnJGridColumn, JnJLink, LightbulbInfo, JnJButton } from "@jaj/ui-components/build";
import {Link} from "react-router-dom";

const styles = (theme: Theme) => createStyles({
  root: {
    padding: '35px 0',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      padding: 0,
    }
  },
  linkContainer: {
    [theme.breakpoints.up('sm')]: {
      borderTop: '1px solid #D8D8D8',
      display: 'flex',
      justifyContent: 'flex-end',
      paddingTop: 22
    },
    [theme.breakpoints.down('md')]: {
      paddingBottom: 22
    }
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      borderLeft: '1px solid #D8D8D8',
      paddingLeft: 44,
      marginLeft: 44,
      marginBottom: 50,
      paddingBottom: 0,
    },
    [theme.breakpoints.down('md')]: {
      order: 1
    }
  },
  title: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    }
  },
  tipContent: {
    maxWidth: 350,
    [theme.breakpoints.down('md')]: {
      marginBottom: 32,
      order: 0
    }
  },
  description: {
    whiteSpace: 'pre-line'
  },
  button: {
    padding: '17px 26px',
    marginTop: 18,
    marginBottom: 44,
    fontSize: '16px',
    textTransform: 'none',
  },
  column: {
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      '.tipContent': {
        order: 0
      },
      '.content': {
        order: 1
      }
    }
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  }
});
interface InterviewContentProps extends WithStyles{
  onNext: () => void
}
interface columnContent {
  description: string,
  list: string[]
}
class InterviewContent extends PureComponent<InterviewContentProps, {}> {
  columnContents: columnContent[] = [
    {
      description: 'To find the right people, you have to ask the right questions. All interviews should include:',
      list: [
        'Technical/functional questions. These get at the technical skills that are needed to perform the job well.',
        'Leadership Imperative (LI)–based questions. These get at the skills that are necessary to be an effective leader at Johnson & Johnson (whether you’ll manage people or not) and include questions on Connect, Shape, Lead and Deliver, as well as Our Credo.'
      ],
    },
    {
      description: 'We recommend asking technical questions before LI questions. This lets a candidate showcase his or her technical skills and ensures that the interviewer gets the answers they need to make a hiring decision. We do recommend that all interviewers ask at least one Credo question.\n\n Keep in mind that:',
      list: [
        'A 45-minute interview should include 2 or 3 Functional competency-based questions and 2 or 3 Leadership Imperatives questions',
        'A 1-hour interview should include at least 3 Functional competency-based questions and at least 3 Leadership Imperatives questions'
      ]
    },
    {
      description: 'The Interview Tool contains some technical/functional questions and all validated Leadership Imperative questions. Need to include some Technical questions that aren’t in the tool? You can create custom questions to ask during your interviews as long as:',
      list: [
        'Those technical skills they assess are critical to perform the job',
        'Those skills will be required on day 1 of the job',
        'All candidates are asked the same questions'
      ]
    }
  ];

  renderColumns(index: number) {
    const column = this.columnContents[index];
    return (
      <div>
        <Typography
          variant="body1"
          className={`margin-bottom-20 ${this.props.classes.description}`}
        >
          {column.description}
        </Typography>
        <ul className='list'>
          {column.list.map((item, index)=>(
            <li key={index}>
              {item}
            </li>
          ))}
        </ul>
      </div>
    )
  }
  render() {
    const {classes, onNext} = this.props;
    return (
      <JnJGridRow className={classes.root}>
        <JnJGridColumn xs={12} md={10}>
          <Typography
            variant="h3"
            color="secondary"
            className={`margin-bottom-20 ${classes.title}`}
          >
            Customize your Interview Guide
          </Typography>
          <JnJGridRow>
            <JnJGridColumn xs={12} md={7}>
              {this.renderColumns(0)}
              {this.renderColumns(1)}
              <Typography
                variant="body1"
                className="margin-bottom-15"
              >
                Follow these guidelines when you use our interactive tool to create a custom interview guide.
                Our tool will allow you to select Credo, Leadership Imperative and
                Functional Competency questions which align to the position level and function.
                At the end, you can generate a PDF or Word document with your questions,
                plus a grading rubric for each competency.
              </Typography>
            </JnJGridColumn>
            <JnJGridColumn xs={12} md={5} className={this.props.classes.column}>
              <div className={classes.content}>
                {this.renderColumns(2)}
              </div>
              <LightbulbInfo direction="vertical" className={this.props.classes.tipContent}>
                For sequential 1:1 interviews, avoid repetition by deciding ahead of time how you’ll divide up the questions.
                Will each interviewer focus on both Technical and Leadership Imperative questions,
                or will different interviewers concentrate on different areas?
              </LightbulbInfo>
            </JnJGridColumn>
          </JnJGridRow>
          <div className={classes.buttonContainer}>
            <Link to='/interview'>
              <JnJButton
                className={classes.button}
                variant='contained'
                color='primary'
              >
                Create your Interview Guide
              </JnJButton>
            </Link>
          </div>
          <div className={classes.linkContainer} onClick={onNext}>
            <JnJLink>
              How to score your interview
            </JnJLink>
          </div>
          </JnJGridColumn>
      </JnJGridRow>
    );
  }
}
export default withStyles(styles)(InterviewContent);
