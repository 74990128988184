import { call, put, all, fork, takeEvery } from 'redux-saga/effects';
import {
  loadFunctionalCompetencyTranslation
} from "../../services/categoryService";
import {actions} from './action';
import { ActionType, getType } from 'typesafe-actions';

function* fetchFunctionalCompetencyTranslations(action: ActionType<typeof actions.fetchFunctionalTranslation.request>) {
  try {
    const res = yield call(loadFunctionalCompetencyTranslation, action.payload);
    yield put(actions.fetchFunctionalTranslation.success(res));
  }
  catch(error) {
    yield put(actions.fetchFunctionalTranslation.failure('Error Load'));
  }
}

export function* watchTranslations() {
  yield takeEvery(getType(actions.fetchFunctionalTranslation.request),
  fetchFunctionalCompetencyTranslations)
}

export function* saga() {
  yield all([
    fork(watchTranslations)
    //fork(watchCategory),
  ]);
}
