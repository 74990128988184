import { COMPETENCY_ACTIONS } from '../../../components/Constants';

export default {
  [COMPETENCY_ACTIONS.add]: {
    title: "Add Competency",
    submitButton: "Save"
  },
  [COMPETENCY_ACTIONS.edit]: {
    title: "Edit Competency",
    submitButton: "Save Changes"
  }
};
