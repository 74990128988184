import { QUESTION_ACTIONS } from '../../../components/Constants';

export default {
  [QUESTION_ACTIONS.add]: {
    title: "Add Question",
    submitButton: "Save"
  },
  [QUESTION_ACTIONS.edit]: {
    title: "Edit Question",
    submitButton: "Save Changes"
  }
};
