// Global module imports
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles, createStyles } from "@material-ui/core";
import { Typography, JnJButton } from '@jaj/ui-components';

// type imports
import { RootState } from "../../../store";
import * as addEditQuestionActions from '../../../store/addEditQuestion/addEditQuestionActions';
import { selectIsWriter } from '../../../store/user/selector';

//component imports
import EntityTranslateFullAddEditForm from '../../../components/EntityTranslateFullAddEditForm/EntityTranslateFullAddEditForm';
import Container from '../../../components/Container/Container';
import { Loading } from '../../../components/Loading';

//other imports
import DynamicContent from './DynamicContent';
import { QUESTION_ACTIONS } from '../../../components/Constants';
import { selectCurrentCompetency } from '../../../store/selectCategory/selectors';
import { QuestionFullInformation } from '../../../store/addEditQuestion/type';
import { Category } from '../../../store/editCategory/type';
import { FullEntityDataForm } from '../../../store/selectCategory/type';

interface IQuestionAddEditProps extends WithStyles {
  save: Function,
  isWriter: boolean,
  history: any,
  location: any,
  match: any,
  currentCompetency: Category,
  translateQuestions: {[key: string]: FullEntityDataForm},
  questionFullInformation?: QuestionFullInformation
  error?:string,
  savingResult?: boolean,
  loadingResult?: boolean,
  loadQuestionFullInformation: Function,
  saveQuestionFullInformation: Function,
  getQuestion: Function
}

const mapStateToProps = (state: RootState) => ({
  isWriter: selectIsWriter(state),
  currentCompetency: selectCurrentCompetency(state),
  translateQuestions:  state.form['translateQuestions'],
  getQuestion: (id) => state.category.questionStore[id],
  error: state.addEditQuestion.error,
  questionFullInformation: state.addEditQuestion.fullInformation,
  savingResult: state.addEditQuestion.savingResult,
  loadingResult: state.addEditQuestion.loading
});

const mapDispatchToProps = {
  loadQuestionFullInformation: addEditQuestionActions.loadQuestionFullInformation,
  saveQuestionFullInformation: addEditQuestionActions.saveQuestionFullInformation
};

const styles = () => createStyles({
  questionAddEditRoot: {
    backgroundColor: '#ffffff',
    padding: '50px 135px',
  },
  title: {
    marginBottom: 50,
  },
  actionButtons: {
    display: 'flex',
  },
  actionButtonCancle: {
    marginLeft: '20px',
  },
  error: {
    color: '#ca001b',
    padding: '10px 0',
  },
});

interface IQuestionAddEditState {
  error: string,
  loading:boolean,
  saving?:boolean
}

class QuestionAddEdit extends React.Component<IQuestionAddEditProps, IQuestionAddEditState> {
  state: IQuestionAddEditState = {
    error: '',
    loading: true
  };
  
  getIds = () => {
    const { action, id } = this.props.match.params;
    let res = {
      questionId: action === QUESTION_ACTIONS.edit && id ? id : 0,
      categoryId: action === QUESTION_ACTIONS.add && id ? id : 0
    };
    if (res.categoryId === 0 && res.questionId !== 0) {
        const curQuestion = this.props.getQuestion(res.questionId);
        if (curQuestion)
          res.categoryId = curQuestion.categoryId;
    }
    return res;
  }

  componentDidMount() {    
    if(!this.props.isWriter || !this.props.currentCompetency) {
      this.props.history.push('/');
    }
    const ids = this.getIds();    
    if (ids.questionId || ids.categoryId) {      
      this.props.loadQuestionFullInformation(ids.categoryId, ids.questionId);
    } else {
      this.props.history.push('/');
    }
  }

  componentWillReceiveProps(nextProps: IQuestionAddEditProps) {
    if (nextProps.savingResult !== undefined && this.props.savingResult === undefined) {
      if (nextProps.savingResult || this.props.savingResult) {
        this.props.history.push('/interview');
      } else {
        this.setState({ 
          saving: false, 
          error: nextProps.error ? nextProps.error : 'Unknowing error during saving' 
        });
      }
    } else if (nextProps.loadingResult === false && this.props.loadingResult === true) {
      this.setState({loading:false});
    }
  }
  
  getTranslations() {
    const data = this.props.translateQuestions.values;
    return Object.keys(data).reduce((agr: Array<any>, languageCode: string) => {
      const {text, description} = data[languageCode];
      if (!!text && text !== '') {
        return [...agr, {
          languageCode,
          text,
          description
        }];
      }
      return agr;
    }, []);
  }

  getProbingQuestionss() {
    const data = this.props.translateQuestions.values;
    return Object.keys(data).reduce((agr: Array<any>, languageCode: string) => {
      const {probingQuestions} = data[languageCode];
      if (!!probingQuestions && probingQuestions !== '') {
        return [...agr, {
          languageCode,
          text:probingQuestions,
          description:''
        }];
      }
      return agr;
    }, []);
  }
  
  getRatings() {
    const data = this.props.translateQuestions.values;    
    const formResultData = data.isCustomRatingsShown ? Object.keys(data).reduce((agr: Array<any>, languageCode: string) => {
      const {custom_rating1, custom_rating2, custom_rating3, custom_rating4} = data[languageCode];
      if ((!!custom_rating1 && custom_rating1 !== '') ||
          (!!custom_rating2 && custom_rating2 !== '') ||
          (!!custom_rating3 && custom_rating3 !== '') ||
          (!!custom_rating4 && custom_rating4 !== '')
       ) {
        return [...agr, {
          languageCode,
          ratings: [ {value:1, text:custom_rating1}, {value:2, text:custom_rating2}, {value:3, text:custom_rating3}, {value:4, text:custom_rating4}]
        }];
      }
      return agr;
    }, []) : [];
    
    if (formResultData && formResultData.length > 0) {
      const result = [1,2,3,4].map(ratingValue => {
        return {
          value: ratingValue,
          translations: formResultData.map(frmDataItem => {
            const ratItem = frmDataItem.ratings.find(_ => _.value == ratingValue);
            return ratItem && ratItem.text && ratItem.text.length > 0  ? {
              languageCode: frmDataItem.languageCode,
              text:frmDataItem.ratings.find(_ => _.value == ratingValue).text
            }: null;
          }).filter(_ => _ != null)
        }
      });
        
      return result;
    }
    return [];
  }

  onSave = () => {
    this.setState({
      error: '',
    });

    const ids = this.getIds();
    const translationItems = this.getTranslations();
    const probingQuestionItems = this.getProbingQuestionss();
    const ratingsItems = this.getRatings();

    if (translationItems.length === 0) {
      this.setState({ error: 'Question is required' });
      return;
    }
    const engTranslation = translationItems.find(val => val.languageCode === 'EN');
    if (!engTranslation) {
      this.setState({ error: 'English translation is obligatory' });
      return;
    }

    const ratWithoutEng = ratingsItems.filter(_ => _.translations && _.translations.length > 0 && !_.translations.find(val => val.languageCode === 'EN'))
    if (ratWithoutEng && ratWithoutEng.length > 0) {
      this.setState({ error: 'English translation is obligatory for rating numbers ' + ratWithoutEng.map(_ => _.value.toString()) });
      return;
    }

    if (ids.questionId || ids.categoryId) {
      this.setState({saving:true});
      this.props.saveQuestionFullInformation(
        ids.categoryId,
        ids.questionId,
        this.props.questionFullInformation, 
        translationItems, 
        probingQuestionItems,
        ratingsItems);
        
        // To handle redirect to question list page with updated texts 
        if (ids.questionId != 0) {
          var editedQuestion:any = {};
          editedQuestion.id = ids.questionId;
          var currentLanguageCode = localStorage.getItem('currentLanguageCode');
          localStorage.setItem('editedQuestionLanguage', currentLanguageCode);
          translationItems.map(item => {
            if(item.languageCode == currentLanguageCode) {
              localStorage.setItem('editedQuestionText', item.text);
              editedQuestion.text = item.text;
            }
          });
          localStorage.setItem('editedQuestion', JSON.stringify(editedQuestion));
          this.props.history.push('/interview/select-functional-competency?reload=yes');
        }  
    } else {
      this.props.history.push('/');
    }
  };

  render() {
    const { match, classes, currentCompetency } = this.props;
    const { action } = match.params;
    const error = this.state.error;

    if (this.state.loading || this.props.loadingResult === true || this.state.saving) {
      return <Loading />
    }

    return (
      <Container static className={classes.questionAddEditRoot}>
        <Typography
          variant="h4"
          color="secondary"
          className={classes.title}
        >
          { `${currentCompetency && currentCompetency.text} Competency: ${DynamicContent[action].title}` }
        </Typography>
          <React.Fragment>
            <EntityTranslateFullAddEditForm
              entityText={'Question'}
              showProbingQuestions={!this.props.currentCompetency.ownsProbingQuestions }
              showRatings={!this.props.currentCompetency.ownsRatings }
              initialValues={this.props.questionFullInformation.formValues}
            />
            {error && <div className={classes.error}>{error}</div>}
            <div className={classes.actionButtons}>
              <JnJButton
                className={classes.actionButton}
                variant="outlined"
                color="primary"
                onClick={this.props.history.goBack}
              >
                Cancel
              </JnJButton>
              <JnJButton
                className={classes.actionButtonCancle}
                variant="contained"
                color="primary"
                onClick={this.onSave}
              >
                {DynamicContent[action].submitButton}
              </JnJButton>
            </div>
          </React.Fragment>
      </Container>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(QuestionAddEdit)));
