import {Reducer} from 'redux';
import * as actions from './addEditQuestionActions';
import { getType, ActionType } from 'typesafe-actions';
import { QuestionId,  IEntityTranslation } from '../../models/questionModels';
import { QuestionFullInformation } from './type';
import { getActionStartType, getActionEndType, getActionFailureType } from '../../services/actionService';

export type QuestionState =  {
  loading: boolean,
  id?: QuestionId;
  data?: IEntityTranslation[];
  error?: string;
  fullInformation?: QuestionFullInformation,
  savingResult?: boolean
}

type Actions = ActionType<typeof actions>;

const initialState: QuestionState = {
  loading: true,
};

export const reducer: Reducer<QuestionState, Actions> = (state = initialState, action: any) => {
  switch(action.type) {
    case getActionStartType(actions.SAVE_QUESTION_FULL):
      return {
        ...state,
        error:'',
        savingResult: undefined,
      };
    case getType(actions.fetchQuestion.request):
    case getType(actions.saveQuestion.request):
    case getActionStartType(actions.UPDATE_QUESTION):
    case getActionStartType(actions.CREATE_QUESTION):
    case getActionStartType(actions.LOAD_QUESTION):
    case getActionStartType(actions.DELETE_QUESTION):
    case getActionStartType(actions.LOAD_QUESTION_FULL):
      return {
        ...state,
        error: '',
        fullInformation: undefined,
        savingResult: undefined,
        loading: true
      };
    case getType(actions.fetchQuestion.failure):
    case getType(actions.saveQuestion.failure):
    case getActionFailureType(actions.UPDATE_QUESTION):
    case getActionFailureType(actions.CREATE_QUESTION):
    case getActionFailureType(actions.LOAD_QUESTION):
    case getActionFailureType(actions.DELETE_QUESTION): 
    case getActionFailureType(actions.LOAD_QUESTION_FULL): 
    case getActionFailureType(actions.SAVE_QUESTION_FULL): 
      return {
        ...state,
        error: action ? (action.error ? action.error : (action.payload ? action.payload : '')) : '',
        savingResult: false,
        loading: false
      };
      
    case getType(actions.fetchQuestion.success):
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: '',
      };
    case getType(actions.saveQuestion.success):
      return {
        ...state,
        data: action.payload,
        error: '',
        loading: false
      };    
    case getActionEndType(actions.UPDATE_QUESTION):
    case getActionEndType(actions.CREATE_QUESTION): 
      return {
        ...state,
        error: "",
        loading: false
      };    
    case getActionEndType(actions.LOAD_QUESTION): {
      return {
        ...state,
        loading: false,
        data: action.payload,
        error: ""
      };
    }
    case getActionEndType(actions.DELETE_QUESTION): {
      return {
        ...state,
        error: "",
        loading: false
      };
    }
    case getActionEndType(actions.LOAD_QUESTION_FULL):
      return {
        ...state,
        fullInformation: action.payload,
        loading: false
      };
    case getActionEndType(actions.SAVE_QUESTION_FULL):
      return {
        ...state,        
        savingResult: action.payload,
      };
    default:
      return state;
  }
};

