import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { CategoryDTO } from '../../store/selectCategory/type';
import { IQuestion } from '../../models/questionModels';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { Typography, createStyles, Theme } from "@material-ui/core";
import { onHistoryLinkClicked } from '../../services/historyLinksService';
import {
  JnJButton,
  DumbIcon,
} from '@jaj/ui-components';

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: '#fff',
    border: '1px solid #D8D8D8',
    padding: '60px',
    margin: '10px 0',
    [theme.breakpoints.down('md')]: {
      padding: '25px',
    }
  },

  categoryNameHeader: {
    marginBottom: '17px',
    [theme.breakpoints.down('md')]: {
      fontSize: '20px',
      lineHeight: '26px',
      marginBottom: '11px',
    }
  },

  mainSection: {
    display: 'flex',
    flexDirection: 'column',
  },

  questionItem: {
    borderTop: '1px solid #D8D8D8',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    padding: '15px 0',
  },

  questionDescription: {
    flex: 8,
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: '300',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px',
      lineHeight: '26px',
    }
  },

  deleteButton: {
    flex: 2,
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent',
    border: 'none',
    minWidth: '0',
    padding: '0',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'transparent',
    }
  },
  moduleHeader: {
    margin: '18px 0'
  }
});

interface YourGuideCategoryQuestionsProps extends WithStyles{
  category: CategoryDTO,
  categoryId: number,
  categoryQuestions: IQuestion[] | {[key: string]: IQuestion[]},
  hasSubHeader: boolean,
  removeQuestion: (arg0: number) => void,
  currentCompetency: string
}

class YourGuideCategoryQuestions extends PureComponent<YourGuideCategoryQuestionsProps, {}> {
  renderQuestions = (questions) => {
    const { classes, hasSubHeader } = this.props;
    if(hasSubHeader){
      const questionItems = [];
      for(const key in questions){
        questionItems.push((
          <div key={key}>
            <Typography  variant="h4" color="secondary" className={classes.moduleHeader}>
              {`${key[0].toUpperCase()}${key.slice(1).toLowerCase()}`}
            </Typography>
            {
              questions[key].map(question => (
                <div key={question.id}>
                  <div className={classes.questionItem}>
                    <p className={classes.questionDescription}>{question.text}</p>
                    <JnJButton
                      onClick={() => onHistoryLinkClicked({ category: 'question', action: 'remove', label: question.text,
                          onClick: () => this.props.removeQuestion(question.id)
                      })}
                      className={classes.deleteButton}
                    >
                      <DumbIcon/>
                    </JnJButton>
                  </div>
                </div>
              ))
            }
          </div>
        ));
      }
      return questionItems;
    } else {
     return questions && questions.map((question) => {
       return (
         question.text.length > 0 &&
         <div key={question.id}>
           <div className={classes.questionItem}>
             <p className={classes.questionDescription}>{question.text}</p>
             <JnJButton
               onClick={() => this.props.removeQuestion(question.id)}
               className={classes.deleteButton}
             >
               <DumbIcon />
             </JnJButton>
           </div>
         </div>
       )
     })
    }
  };

  render() {
    const {category, categoryQuestions, classes, currentCompetency} = this.props;
    return (
      <div className={classes.root}>
        <Typography className={classes.categoryNameHeader} variant="h3" color="secondary">
          {
            category.text.includes('Functional') && currentCompetency
              ? `${category.text}: ${currentCompetency}`
              : category.text
          }
        </Typography>
        <div className={classes.mainSection}>
          {this.renderQuestions(categoryQuestions)}
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({category}, {categoryId}) => ({
  currentCompetency: (category.categoryStore[categoryId] || {}).text,
});

export default connect(mapStateToProps)(withStyles(styles)(YourGuideCategoryQuestions));

