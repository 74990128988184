import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormSection } from 'redux-form';
import SwipeableViews from 'react-swipeable-views';
import LanguageSelectorTabs from './LanguageSelectorTabs';

class RenderLanguagesComponents extends Component {
  static propTypes = {
    TranslateComponent: PropTypes.func,
    showDescription: PropTypes.bool,
    showProbingQuestions: PropTypes.bool,
    showRatings: PropTypes.bool,
    entityText: PropTypes.string,
    change: PropTypes.func
  };

  state = {
    lang: 'EN',
    isCustomRatingsShown: undefined
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.isCustomRatingsShown === undefined && nextProps.initialValues !== undefined) {
      const isCustomRatingsShown = Object.keys(nextProps.initialValues)
            .map(language => {
              return nextProps.initialValues[language]['custom_rating4'] || 
                    nextProps.initialValues[language]['custom_rating3'] || 
                    nextProps.initialValues[language]['custom_rating2'] || 
                    nextProps.initialValues[language]['custom_rating1'] ? true : false})
            .some(_ => _ === true);

      this.props.change('isCustomRatingsShown', isCustomRatingsShown);
      this.setState({
        isCustomRatingsShown 
      });
    }
  }

  onChangeRatingType = (value: string) => {   
    const newValue = value === 'true';
    this.setState({
      isCustomRatingsShown: newValue
    });
    this.props.change('isCustomRatingsShown', newValue);
  };

  getIsCustomRatingsShown = () => {    
    return this.state.isCustomRatingsShown === true;
  }

  changeTab = (lang) => {
    this.setState({
      lang,
    });
  }

  render() {
    const {
      codes,
      TranslateComponent
    } = this.props;

    const {
      lang
    } = this.state;

    return (
      <React.Fragment>
        <LanguageSelectorTabs value={lang} onChange={this.changeTab}/>
        <SwipeableViews index={codes.indexOf(lang)}>
          {codes.map((code) => (
            <div key={code}>
              <FormSection name={code}>
                <TranslateComponent
                  showProbingQuestions={this.props.showProbingQuestions}
                  showDescription={this.props.showDescription}
                  showRatings={this.props.showRatings}
                  entityText={this.props.entityText}
                  isCustomRatings={this.getIsCustomRatingsShown()}
                  onChangeRatingType={this.onChangeRatingType}
                />
              </FormSection>
            </div>
          ))}
        </SwipeableViews>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({language: {languages = [], loading}}) => ({
  codes: !loading ? languages.map(({code}) => code) : [],
  loading,
})

export default connect(mapStateToProps)(RenderLanguagesComponents);
