import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import RatingScaleItem from './RatingScaleItem';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {
  JnJButton,
  ModalCloseIcon
} from '@jaj/ui-components';

const styles = theme => ({
  dialogWrapper: {
    padding: '21px 45px 45px 21px',
    [theme.breakpoints.down('sm')]: {
      padding: '0 24px 24px 0'
    }
  },
  dialogHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 13,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 0
    }
  },
  styledHeader: {
    '& h2': {
      color: theme.palette.secondary.main,
      fontSize: 24,
      fontWeight: 900,
    }
  },
  closeIcon: {
    marginLeft: 'auto',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0',
    '&:hover': {
      backgroundColor: 'transparent'
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  },
  dialogContent: {
    backgroundColor: '#fff',
    marginLeft: 24,
    padding: '15px 49px',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      marginLeft: 24
    }
  },
});

class RatingScaleDialog extends React.Component {
  onCloseDialog = () => {
      this.props.onClose();
  };

  render() {
    const {open, onClose, classes, fullScreen} = this.props;
    return (
      <Dialog
        onClose={this.onCloseDialog}
        open={open}
        aria-labelledby="rating-scale-dialog"
        PaperProps={{
          style: {
            backgroundColor: '#f4f4f4',
          },
        }}
        maxWidth="md"
        fullScreen={fullScreen}
      >
        <div className={classes.dialogWrapper}>
          <div className={classes.dialogHeader}>
            <DialogTitle
              id="rating-scale-dialog"
              className={classes.styledHeader}
            >
              Example Rating Scale
            </DialogTitle>
            <JnJButton
              onClick={this.onCloseDialog}
              className={classes.closeIcon}
            >
              <ModalCloseIcon />
            </JnJButton>
          </div>
          <DialogContent
            className={classes.dialogContent}
          >
            <RatingScaleItem title="Exceeds requirements." ratingScaleItemNumber="4" hasBorder="true">
              Provided a close to perfect answer demonstrating outstanding knowledge/experience in this area. Candidate provided multiple examples that were relevant and quite effective.
            </RatingScaleItem>
            <RatingScaleItem title="Meets requirements." ratingScaleItemNumber="3" hasBorder="true">
              Provided a strong answer demonstrating knowledge/experience in this area. Candidate provided examples that were relevant and effective.</RatingScaleItem>
            <RatingScaleItem title="Partially meets requirements." ratingScaleItemNumber="2" hasBorder="true">
              Provided a partially effective answer demonstrating only limited knowledge/experience in this area. Candidate provided examples that were somewhat relevant and/or somewhat effective.
            </RatingScaleItem>
            <RatingScaleItem title="Did not meet requirements." ratingScaleItemNumber="1" hasBorder="false">
              Answer did not demonstrate knowledge/experience in this area. Candidate could not provide examples, or irrelevant or ineffective examples were given.
            </RatingScaleItem>
          </DialogContent>
        </div>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withMobileDialog()(RatingScaleDialog));
