import "@babel/polyfill";
import React from 'react';
import ReactDOM from 'react-dom';
import { OidcProvider } from 'redux-oidc';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { Provider } from 'react-redux';
import store from './store';
import { signIn, userManager } from './services/userManager';
import { subscribeGlobalErrorHandle } from './services/errorHandlingService';

if (!localStorage.getItem('auth')) {
  signIn();  //first time open
}
else {
  ReactDOM.render((
    <Provider store={store}>
     <OidcProvider store={store} userManager={userManager}>
        <BrowserRouter>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </BrowserRouter>
      </OidcProvider>
    </Provider>
  ), document.getElementById('root'))
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
subscribeGlobalErrorHandle();
