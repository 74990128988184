import { call, put, all, takeEvery } from 'redux-saga/effects';
import * as userActions from './action';
import { getType } from 'typesafe-actions';
import { fetchCurrentUser, setUserGuideAttendance } from '../../services/user';
import {actions} from "../selectCategory";

function* fetchUserSaga() {
  try {
    const res = yield call(fetchCurrentUser);
    yield put(userActions.fetchUser.success(res));
    yield put(actions.fetchLanguages());
    if (!res.isUserGuideAttendance) {
      yield put(userActions.userAttende.request());
    }
  }
  catch(error) {
    yield put(userActions.fetchUser.failure(error.message ? error.message : 'Error Load'));
  }
}

function* userAttendee() {
  try {
    yield call(setUserGuideAttendance, {});
  }
  catch(error) {
    yield put(userActions.userAttende.failure(error.message ? error.message : 'Error Load'));
  }
}

export function* saga() {
  yield all([
    takeEvery(getType(userActions.fetchUser.request),  fetchUserSaga),
    takeEvery(getType(userActions.userAttende.request),  userAttendee),
  ]);
}
