import { put, all, fork, takeEvery } from 'redux-saga/effects';
import {actions as selectCategoryActions} from '../selectCategory';
import { actions  } from './action';
import { getType } from 'typesafe-actions';


function* watchResetAllActions() {
  yield takeEvery([
    getType(selectCategoryActions.resetAll),
    getType(selectCategoryActions.resetAllQuestion)
  ], function* () {
    yield put(actions.resetAll());
  });
}

export function* saga() {
  yield all([
    fork(watchResetAllActions),
  ]);
}
