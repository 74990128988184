import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import ButtonBase from '@material-ui/core/ButtonBase';
import {
  JnJGridRow,
  JnJGridColumn,
  ClipboardIcon
} from '@jaj/ui-components';
import {
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import QuestionCategory from './QuestionCategory';
import QuestionsListToolbar from './QuestionsListToolbar';
import { actions } from '../../store/selectCategory/action';
import CustomQuestion from '../../components/CustomQuestion';
import YourGuide from './YourGuide';
// import LanguageSelector from '../../components/LanguageSelector';
import { CategoryTab } from '../../store/selectCategory/type';
import { getCategoryIndexById } from '../../services/categoryService';
import { colorPrimary } from '../../styles/colors';
import Loading from '../../components/Loading/Loading';
import Container from '../../components/Container/Container';
import GuideForm from "../../components/GuideForm/GuideForm";
import Hidden from '@material-ui/core/Hidden';
import { EDraftState, guideViewStatus } from "../../models/guideModel";
import { DeleteModalDialog } from "../../components/ModalDialog";
import { actions as customQuestionAction } from "../../store/customQuestions";
import { actions as questionAction } from "../../store/questions";
import GuideInfo from '../../components/GuideFormInfo/GuideInfo';
import NotificationMessage from "../../components/NotificationMessage/NotificationMessage";
import LanguageSelector from "../../components/LanguageSelector/LanguageSelector";

const styles = theme => ({
  root: {
    backgroundColor: '#fff',
    paddingTop: '20px',
    paddingBottom: '20px',
    [theme.breakpoints.up('md')]: {
      paddingTop: '50px',
      paddingBottom: '100px'
    }
  },
  eyebrow: {
    color: '#CC0099',
    fontWeight: 'bold',
    marginBottom: 10
  },
  questionsList: {
    padding: 0
  },
  questionsWrapper: {
    backgroundColor: '#f4f4f4',
    padding: 60,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      padding: '25px',
      //margin: '0 25px',
      margin: '0',
    }
  },
  tabsWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
  leadershipWrapper: {
    backgroundColor: '#f4f4f4',
    padding: 60,
    boxSizing: 'border-box',
    [theme.breakpoints.down('md')]: {
      padding: '25px',
      margin: '0 25px',
    },
    [theme.breakpoints.down('sm')]: {
      paddingBottom: 100,
    },
  },
  form: {
    marginTop: '15px'
  },
  warappertext: {
    padding: '30px 0 0 150px',
    width: 'calc(100% + 100px)',
    margin: '35px 0 15px -50px',
    fontSize: '30px',
    fontWeight: '900',
    color: '#000099',
    borderTop: '1px solid #D8D8D8',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '15px 0 15px 0px',
      padding: '30px 0 0 0px',
    }
  },
  tab: {
    minWidth: 0,
    padding: 0,
    fontSize: '15px',
    borderBottom: '1px solid #D8D8D8',
    margin: '0 23px',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      maxWidth: 'calc(24.5% - 1px)',
      margin: '0',
      padding: '15px 2px',
      border: '1px solid #D8D8D8',
      fontSize: '8px',
    }
  },
  tabIndicator: {
    backgroundColor: colorPrimary,
    [theme.breakpoints.down('sm')]: {
      top: '0!important',
    }
  },
  tabLabel: {
    minWidth: 0,
    margin: 0,
    padding: '0 2px',
    color: '#989ca1',
  },
  tabsWrapperContent: {
    flex: 1,
    width: '100%',
  },
  tabContent: {
    paddingTop: '25px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 0',
  },
  languageSelector: {
    /*display: 'none',
    [theme.breakpoints.down('md')]: {*/
    display: 'flex',
    justifyContent: 'flex-end',
    /*},*/
  },
  removeTabHover: {
    top: 'unset',
    left: 'unset',
    width: 'unset',
    display: 'unset',
  },
  cardText: {
    fontWeight: '900',
    lineHeight: '26px',
    marginTop: '18px',
    display: 'flex',
    fontSize: '14px',
    '& div:first-of-type': {
      textTransform: 'uppercase',
    }
  },
  cardContentText: {
    color: '#63666A',
    fontSize: '14px',
    fontWeight: '300',
    lineHeight: '26px',
    marginLeft: '15px',
    '& p': {
      width: 'max-content',
    },
  },
  notification: {
    margin: 'auto',
    width: '1280px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
  },
  headerTitle: {
    width: '85%',
    fontSize: '36px'
  },
  /*positionText: {
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
    },
  },*/
  longTextView: {
    fontSize: '12px',
    [theme.breakpoints.down('md')]: {
      fontSize: '11px',
    },
  },
});

export class QuestionsListPage extends PureComponent {
  state = {
    value: 0,
    openDeleteDialog: false,
    showMessage: this.props.viewStatus === guideViewStatus.copy,
    count: 0
  };

  componentDidMount() {
    window.onbeforeunload = () => true;
    if (!this.props.guide.questionnaireLength) {
      this.props.history.push('/interview/select-guide-type')
    }
  }

  componentWillUnmount() {
    window.onbeforeunload = null;
    if (this.props.viewStatus === guideViewStatus.edit || this.props.viewStatus === guideViewStatus.copy) {
      this.props.resetAll();
    }
  };

  componentDidUpdate() {
    // This is here for removing 'getSnapshotBeforeUpdate' warning message.
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (this.props.draftStatus === EDraftState.draft && this.props.viewStatus !== guideViewStatus.copy) {
      this.props.history.push({ pathname: '/interview', state: { value: 1 } });
      return this.props.changeDraftStatus(EDraftState.none);
    }
    if (this.props.draftStatus === EDraftState.copy && this.props.viewStatus === guideViewStatus.copy) {
      this.props.history.push({ pathname: '/interview', state: { value: 1 } });
      return this.props.changeDraftStatus(EDraftState.none);
    }
    if (this.props.isGuideDownloaded) {
      return this.props.history.push({ pathname: '/interview', state: { value: 0 } });
    }
    if (this.props.deletedGuide === prevProps.guide.id) {
      return this.props.history.push({ pathname: '/interview', state: { value: 1 } });
    }
    return null;
  }

  onChangeTab = (event, value) => {
    const { selectTab, competency, questionCategories } = this.props;

    const catrgoryIdMap = {
      [CategoryTab.credo]: questionCategories[0].id,
      [CategoryTab.leadrship]: questionCategories[1].id,
      [CategoryTab.functional]: competency,
    };

    selectTab(value, catrgoryIdMap[value]);
  };

  handleCloseDeleteDialog = (isPositiveAnswer) => {
    if (isPositiveAnswer) {
      this.props.removeGuide(this.props.guide);
    }
    this.setState({
      openDeleteDialog: false,
    });
  };

  onDelete = () => {
    this.setState({
      openDeleteDialog: true,
    });
  };

  renderTabContent() {
    const {
      initializing,
      competency,
      questionCategories,
      classes,
      tab,
    } = this.props;


    if (initializing || !questionCategories.length) {
      return 'Loading...';
    }

    const tabMap = {
      [CategoryTab.credo]: () => <QuestionCategory className={classes.questionsWrapper} categoryId={questionCategories[0].id} />,
      [CategoryTab.leadrship]: () => <QuestionCategory className={classes.leadershipWrapper} categoryId={questionCategories[1].id} />,
      [CategoryTab.functional]: () => <QuestionCategory className={classes.questionsWrapper} categoryId={competency} />,
      [CategoryTab.custom]: () => <CustomQuestion className={classes.questionsWrapper} />,
      [CategoryTab.guide]: () => <YourGuide onChangeTab={this.onChangeTab} />,
    };

    const tabFunction = tabMap[tab] || tabMap[CategoryTab.guide];
    return tabFunction();
  }

  handleMessage() {
    this.setState({ showMessage: false });
  }

  rendTitle() {
    if (this.props.viewStatus === guideViewStatus.edit || this.props.viewStatus === guideViewStatus.copy) {
      return 'Edit your interview guide draft';
    }
    return 'Build Your Interview Guide';
  }

  renderContent() {
    const {
      classes,
      questionCategories,
      chosenQuestions,
      tab,
      customQuestions,
      initializing,
    } = this.props;
    let customQuestionsSize = 0;

    customQuestions.forEach(element => {
      if (element.text.length > 0) {
        customQuestionsSize += 1;
      }
    });
    const allChosenQuestionsSize = chosenQuestions.length + customQuestionsSize;


    if (initializing) {
      return (
        <Loading />
      );
    }

    return (
      <>
        <JnJGridRow className={classes.justifyCenter}>
          <JnJGridColumn xs={12} md={12} lg={10}>
            <GuideForm className={classes.form} />
          </JnJGridColumn>
        </JnJGridRow >
        <JnJGridRow className={classes.justifyCenter}>
          <JnJGridColumn xs={12} md={12} lg={8}>
            <GuideInfo
              viewStatus={this.props.viewStatus}
              cardText={classes.cardText}
              cardContentText={classes.cardContentText}
              /*positionText={classes.positionText}*/
              longTextView={classes.longTextView}
              guide={this.props.guide} shouldPositionShow={true} />
          </JnJGridColumn>
          <JnJGridColumn xs={12} md={12} lg={2}>
            <LanguageSelector inputlabel={'Language'} specialClassName={classes.languageSelector} />
          </JnJGridColumn>
        </JnJGridRow>
        <div className={classes.warappertext}>Select your questions</div>
        <JnJGridRow className={classes.justifyCenter}>
          <JnJGridColumn xs={12} lg={10}>
            <div className={classes.tabsWrapper}>
              <Hidden xsDown>
                <ClipboardIcon />
              </Hidden>
              <div className={classes.tabsWrapperContent}>
                <Tabs
                  value={tab || CategoryTab.guide}
                  classes={{ indicator: classes.tabIndicator }}
                  //variant="scrollable"
                  scrollButtons="off"
                  onChange={this.onChangeTab}
                >
                  <Tab
                    classes={{ root: classes.tab, labelContainer: classes.tabLabel }}
                    key={CategoryTab.guide}
                    value={CategoryTab.guide}
                    label={`Your guide: ${allChosenQuestionsSize} questions`}
                    TouchRippleProps={{ classes: { child: classes.removeTabHover } }}
                  />
                  {questionCategories && questionCategories.map((category, index) => {
                    if ((this.props.competency === 1 ||  this.props.competency === null) && category.text === "Functional Competencies") {
                      return null
                    }
                    return (
                      <Tab
                        classes={{ root: classes.tab, labelContainer: classes.tabLabel }}
                        key={category.id}
                        value={getCategoryIndexById(index)}
                        label={category.text}
                        TouchRippleProps={{ classes: { child: classes.removeTabHover } }}
                      />
                    )
                  }
                  )}
                </Tabs>
              </div>
            </div>
            {questionCategories.length && <div className={classes.tabContent}>{this.renderTabContent()}</div>}
            <br />
            <QuestionsListToolbar onDelete={this.onDelete} />
          </JnJGridColumn>
        </JnJGridRow>
        <DeleteModalDialog
          deletedElement={this.props.guide.name}
          selectedThing={'Interview Guide'}
          open={this.state.openDeleteDialog}
          modalClose={this.handleCloseDeleteDialog}
          question='Are you sure you want to delete this guide?'
        />
      </>
    );
  }

  render() {
    const {
      classes,
    } = this.props;

    return (
      <>
        {!this.props.initializing && this.state.showMessage ?
          <div className={classes.notification}>
            <NotificationMessage
              notificationText={`Success! We’ve created a copy of the interview guide you selected. Make your edits below and then save.`}
              onClick={this.handleMessage.bind(this)}
            />
          </div> :
          null}
        <Container static className={classes.root}>
          <JnJGridRow className={classes.justifyCenter}>
            <JnJGridColumn xs={12} md={8} lg={10}>
              <Typography variant='h3' color='secondary' className={classes.headerTitle}>
                {this.rendTitle()}
              </Typography>
            </JnJGridColumn>
          </JnJGridRow>
          {this.renderContent()}
        </Container>
      </>

    );
  }
}

const mapStateToProps = ({ category, tip, user, questions, customQuestion }) => ({
  duration: category.guide.questionnaireLength,
  page: category.page,
  competency: category.guide.functionCategoryId,
  initializing: category.initializing
    || category.initializingFunctionalCompetency
    || category.initializingQuestions
    || user.loading,
  questionCategories: category.questionCategory && category.questionCategory.map(id => category.categoryStore[id]),
  chosenQuestions: questions,
  customQuestions: customQuestion,
  tab: category.tab,
  categoryId: category.categoryId,
  tip: tip,
  position: category.categoryStore[category.guide.guideTypeCategoryId] || {},
  draftStatus: category.draftStatus,
  isGuidePublished: category.isGuidePublished,
  isGuideDownloaded: category.isGuideDownloaded,
  guide: category.guide,
  viewStatus: category.guideViewStatus,
  deletedGuide: category.deletedGuide
});

const mapDispatchToProps = {
  resetAll: actions.resetAll,
  selectTab: actions.selectTab,
  changeDraftStatus: actions.changeDraftStatus,
  removeGuide: actions.deleteGuide,
  addCustomQuestion: customQuestionAction.addQuestions,
  addQuestion: questionAction.pushQuestions,
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(QuestionsListPage));
