import {axiosInstance} from './apiService';
import { ReportDocRequestDTO, ReportDocResponseDTO } from '../store/selectCategory/type';
import { downloadFile } from './downloadService';

export async function loadReportPreview(postData: ReportDocRequestDTO, languageCode = 'EN'): Promise<ReportDocResponseDTO> {
  const res = await axiosInstance.post(
    `/report/${languageCode}/preview`,
    postData,
    {responseType: 'text/plain',
    transformResponse: [],
  });
  return res.data;
}

export async function loadGuide(guideId: ReportDocRequestDTO, languageCode = 'EN'): Promise<ReportDocResponseDTO> {
  const res = await axiosInstance.get(
    `/report/getReportPreviewByUserGuide/${guideId}/${languageCode}`,
    {responseType: 'text/plain',
      transformResponse: [],
    });
  return res.data;
}

export async function getGuideReport(guideId: number, type: string, languageCode = 'EN'): Promise<void> {
  const res = await axiosInstance.get(
    `/report/getReportByUserGuide/${guideId}/${languageCode}`,
    {
      responseType: 'blob',
      transformResponse: [],
    }
  );

  if(res && res.status === 200 && res.config.responseType === 'blob' && res.data) {

    const contentType = res.headers['content-type'];
    const disposition = res.headers['content-disposition'];
    var fileName = "report." + type;

    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    downloadFile(res.data, fileName, contentType);
  }

  return Promise.resolve();
}

export async function downloadReport(postData: ReportDocRequestDTO, type: string, languageCode = 'en'): Promise<void> {
  const res = await axiosInstance.post(
    `/report/${languageCode}`,
    postData,
    {
      responseType: 'blob',
      transformResponse: [],
    }
  );

  if(res && res.status === 200 && res.config.responseType === 'blob' && res.data) {

    const contentType = res.headers['content-type'];
    const disposition = res.headers['content-disposition'];
    var fileName = "report." + type

    if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
        }
    }

    downloadFile(res.data, fileName, contentType);
  }

  return Promise.resolve();
}

export async function downloadSharedGuide(key: string, type: string): Promise<void> {
  const res = await axiosInstance.get(
    `/userGuides/shared/${key}`,
    {
      responseType: 'blob',
      transformResponse: [],
    }
  );

  if(res && res.status === 200 && res.config.responseType === 'blob' && res.data) {

    const contentType = res.headers['content-type'];
    const disposition = res.headers['content-disposition'];
    var fileName = "report." + type

    if (disposition && disposition.indexOf('attachment') !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    downloadFile(res.data, fileName, contentType);
  }

  return Promise.resolve();
}


export async function downloadResource(resource: string): Promise<void> {
  const res = await axiosInstance.get(
    `/common/resource/${resource}`,
    {
      responseType: 'blob',
      transformResponse: [],
    }
  );

  if(res && res.status === 200 && res.config.responseType === 'blob' && res.data) {

    const contentType = res.headers['content-type'];
    const disposition = res.headers['content-disposition'];
    var fileName = "resourceFile.bin";

    if (disposition && disposition.indexOf('attachment') !== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, '');
        }
    }

    downloadFile(res.data, fileName, contentType);
  }

  return Promise.resolve();
}
